import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { MainLayout } from "./components/layout";
import { setUpTheLocale } from "./functions";

const PrivateRoute = () => {
  const auth = localStorage.getItem("token");
  let path = window.location.pathname;
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return auth ? (
    path !== "/pdfmodule" ? (
      <MainLayout>
        <Outlet />
      </MainLayout>
    ) : (
      <Outlet />
    )
  ) : (
    <Navigate to="/signin" />
  );
};
export default PrivateRoute;
