import React, { useEffect, useRef, useState } from "react";
import style from "./style.scss";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Logo, Error, Success } from "../../components/shared/index";
import { Form, Label } from "reactstrap";
import { MyInput } from "../../components/shared/index";
import { axios, AuthAPI, PurchaseOrderAPI, LanguagesApi } from "../../api";
import { message, notification, Select } from "antd";
import "antd/dist/antd.css";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "../../redux/languages";
import { useDispatch } from "react-redux";
import ReactFlagsSelect from "react-flags-select";
const { Option } = Select;
const Login = (props) => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formError, setFormError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [eFirst, setEFirst] = useState(true);
  const [pFirst, setPFirst] = useState(true);
  const [done, setDone] = useState(false);
  const error = useRef(false);
  const em = useRef("");
  const pd = useRef("");
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (state) {
      if (state.forget) {
        notification.info({
          message: `Forget Email Sent!`,
          description: "Forget Reset Email has been sent check your email.",
          placement: "top",
        });
        navigate("/signin", {
          state: {
            forget: false,
          },
        });
      }
    }
  }, []);

  const formSubmitHandler = (e) => {
    e.preventDefault();
    if (
      em.current.length > 0 &&
      em.current.includes("@") &&
      pd.current.length > 0
    ) {
      setOpen(true);
      setFormError(false);
      error.current = false;
      AuthAPI.signIn(email, password)
        .then((res) => {
          

          if(res.token){
            localStorage.setItem("token", res.token);
            // const decodedUser = jwt_decode(res.token);
            window.location.replace("/");
          }else{
            //localStorage.setItem("token", null);
            message.error(
              t("KSuppPortal_Expired_License_Message") /*err.response.data.message*/
            );
          }
          setOpen(false);

        })
        .catch((err) => {
          setOpen(false);
          setFormError(true);

          message.error(
            t("KSuppPortal_WrongPasswordMessage") /*err.response.data.message*/
          );
        });
    } else {
      setFormError(true);
      error.current = true;
    }
  };
  const emailChangeHandler = (e) => {
    if (eFirst) {
      setEFirst(false);
    }
    if (formError) {
      setFormError(false);
    }
    setEmail(e.target.value);
    em.current = e.target.value;
    if (e.target.value.length > 0 && e.target.value.includes("@")) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };
  const passwordChangeHandler = (e) => {
    if (pFirst) {
      setPFirst(false);
    }
    if (formError) {
      setFormError(false);
    }
    setPassword(e.target.value);
    pd.current = e.target.value;
    if (e.target.value.length > 0) {
      setPasswordError(false);
    } else {
      setPasswordError(true);
    }
  };
  document.getElementById("pageTitle").innerHTML = "MIT | Login";

  const [selected, setSelected] = useState("US");

  useEffect(()=>{
    var x = localStorage.getItem("i18nextLng");
    let y;

    if(x === "en"){
      y="US";
    }
    else if(x === undefined){
      y="US";
    }
    else if(x === 'en-US'){
      y="US"
    }
    else{
      y="CN";
    }
    let v = (y === "US" || y === "en-US" || y === undefined) ? "en" :"zr";

    dispatch(changeLanguage(v));
    localStorage.setItem("i18nextLng",v);
    setSelected(y);
  },[]);
  return (
    <>
      <div className="main page-signin">
        <div
        className="page-signin-language" 
        // style={{ marginRight: "30px", marginTop: "10px" }}
        >
        <ReactFlagsSelect
                className="language-changer mr-1"
                showSelectedLabel={true}
                showSecondarySelectedLabel={true}
                showOptionLabel={true}
                showSecondaryOptionLabel={true}
                searchable={false}
                countries={["US", "CN"]}
                customLabels={{
                  US: "English",
                  CN: "中文", //t("KSuppPortal_Chinese") //"Chinese"
                }}
                selected={selected}
                onSelect={(code) => {
                  setSelected(code);
                  let v = code === "US" ? "en" : "zr";
                  dispatch(changeLanguage(v));
                  i18n.changeLanguage(v).then((r) => {
                    setDone(true);
                    
                  });
                }}
              />
        </div>
        <div className="content">
          <Logo />
          <div className="text-center mt-3">
            <h1 className="SignIn py-1">
              {t("KSuppPortal_SignIn") /*Sign In*/}
            </h1>
            <span className="text-white">
              {
                t(
                  "KSuppPortal_SignInInstructions"
                ) /*Enter your details to login to your account:*/
              }
            </span>
          </div>
          
          <div className="pb-5">
            <Form onSubmit={formSubmitHandler}>
            <div style={{ height: "50px" }}>
            {formError ? (
                <Error
                  msg={
                    t(
                      "KSuppPortal_ValidPasswordMessage"
                    ) /*"Please Enter valid email and password"*/
                  }
                />
              ) : (
                <></>
              )}
              {success ? <Success msg={"Login Successfully!"} /> : <></>}
            </div>
              
              <MyInput
                first={eFirst}
                for={"email"}
                name={"email"}
                label={t("KSuppPortal_Email") /*"Email"*/}
                type={"email"}
                onError={emailError}
                onChange={emailChangeHandler}
              />
              <MyInput
                first={pFirst}
                for={"password"}
                name={"password"}
                label={t("KSuppPortal_Password") /*"Password"*/}
                type={"password"}
                onError={passwordError}
                onChange={passwordChangeHandler}
              />
              <div className="checkbox-inline mb-2">
                <label className="checkbox checkbox-outline text-white m-0">
                  <input type="checkbox" name="remember_me" className="me-2" />
                  {t("KSuppPortal_RememberMe") /*Remember me*/}
                </label>
              </div>
              <div className="text-center">
                <button type="submit" className="myBtn">
                  {t("KSuppPortal_SignIn") /*Sign In*/}
                </button>
              </div>
            </Form>
            <div className="text-center mt-3">
              <a
                onClick={() => {
                  navigate("/forgetpassword");
                }}
                className="forget text-white"
              >
                {t("KSuppPortal_ForgetPassword") /*Forget Password*/}
              </a>
            </div>
          </div>
        </div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </>
  );
};
export default Login;
