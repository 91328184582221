import React from "react";
import { Modal, message, Checkbox } from "antd";
import style from "./style.css";
import { axios } from "../../../api";
import qs from "qs";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";

const MyModal = (props) => {
  const { t } = useTranslation();
  const [comment, setComment] = React.useState("");
  const [open, setOpen] = React.useState(false);
  function commentChangeHandler(e) {
    if (e.target.value) {
      setComment(e.target.value);
    }
  }

  function onOkHandler() {
    if (comment.length > 0) {
      props.setLastComment(comment);
      setOpen(true);
      let data;
      if (props.guid) {
        data = JSON.stringify({
          PONum: props.po,
          comment: comment,
          Email: props.apiData.updateby,
          line: props.apiData.line,
          release: props.apiData.rel,
          wdate: props.apiData.wdate,
          updateby: props.apiData.updateby,
          myguid: props.apiData.myguid,
        });
      } else {
        props.setDataLoading(true);
        data = qs.stringify({
          PONum: props.po.toString(),
          comment: comment,
        });
      }
      let config = {};
      if (props.title === "Accept") {
        if (props.guid) {
          props.setStatus("Accepted");
          config = {
            method: "post",
            url: "/useremail/approve",
            headers: {
              "Content-Type": "application/json",
              authkey: "",
            },
            data: data,
          };
        } else {
          config = {
            method: "post",
            url: "/api/mcustdata/approve",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              "Content-Type": "application/x-www-form-urlencoded",
            },
            data: data,
          };
        }
      } else if (props.title === "Reject") {
        if (props.guid) {
          props.setStatus("Rejected");
          config = {
            method: "post",
            url: "/useremail/reject",
            headers: {
              "Content-Type": "application/json",
              authkey: "",
            },
            data: data,
          };
        } else {
          config = {
            method: "post",
            url: "/api/mcustdata/reject",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              "Content-Type": "application/x-www-form-urlencoded",
            },
            data: data,
          };
        }
      }
      axios(config)
        .then(function (response) {
          if (props.guid) {
            setOpen(false);
            props.setVisible(false);
          } else {
            var config = {
              method: "post",
              url: "/api/mcustdata/getcustomerdata",
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                Cookie:
                  "connect.sid=s%3ASy47HMJH7jD3m6xLx_6h3nbAeY5rWnMv.XfhIljQFBW3NoReqhPxZ84ADf5Mk78eVgfMh05vEoTo; connect.sid=s%3Av6HRUE2gpM1dwIMQ9kNqh3RFZ6WjTRqR.hlS1SP8CJiAbOVyCD35cKg6JaSZYMnWW8%2FLRDAc54bU; connect.sid=s%3AK5xv6PK4eEjg-UipuPw-ZzUz-vxlV4iC.HDROOntvrlctvrFiGJzYySrFVgb1jqqHax4IgRjCsbs",
              },
            };
            axios(config)
              .then(function (response) {
                setOpen(false);
                props.setVisible(false);
                setComment("");
                if (props.guid) {
                  localStorage.removeItem("poData");
                  localStorage.setItem(
                    "poData",
                    JSON.stringify(response.data.vendordata.podata)
                  );
                  window.location.href = "/";
                } else {
                  props.afterChange();
                }
              })
              .catch(function (error) {
                setOpen(false);
                // console.log(error);
              });
          }
        })
        .catch(function (error) {
          // console.log(error);
        });
    } else {
      message.error(t("KSuppPortal_CommentMessage") /*"Please enter comment"*/);
    }
  }

  function onCancel() {
    props.setVisible(false);
  }

  return (
    <>
      <Modal
        width={props.width}
        title={
          props.title === "Accept"
            ? t("KSuppPortal_Accept")
            : t("KSuppPortal_Reject")
        }
        visible={props.visible}
        onCancel={onCancel}
        onOk={onOkHandler}
        cancelText={t("KSuppPortal_Close") /*"Close"*/}
        okText={t("KSuppPortal_Update")} /*Update*/
        destroyOnClose={true}
        okButtonProps={{
          className: 'btn btn-success'
        }}
        cancelButtonProps={{
          className: 'btn btn-danger'
        }}
      >
        <div>
          <label htmlFor="comment" className="me-2 comment">
            {t("KSuppPortal_Calculated_AcceptRejReason") /*Comment*/}:
          </label>
          <input
            onChange={commentChangeHandler}
            name="comment"
            type="text"
            className="commentInput"
            placeholder={
              t("KSuppPortal_Calculated_AcceptRejReason") /*"Comment"*/
            }
            required
          />
        </div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Modal>
    </>
  );
};
export default MyModal;
