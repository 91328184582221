import React, { useEffect, useState } from "react";
import { AuthAPI, LanguagesApi, axios } from "../../../api";
import qs from "qs";
import { Form } from "reactstrap";
import { Button, message, Modal } from "antd";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import MyLogo from "../../../assets/logos/logo.png";
import jwt_decode from "jwt-decode";
import { useLocation, useNavigate } from "react-router-dom";
import { getCompanyName, getSupplierCompany } from "../../../redux/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownButton } from "react-bootstrap";
// import "./style.css";
import { useTranslation } from "react-i18next";
import { MenuFoldOutlined, MenuUnfoldOutlined, PoweroffOutlined } from "@ant-design/icons";
import { changeLanguage, getLanguageState,
  getSelectedLanguage } from "../../../redux/languages";
import ReactFlagsSelect from "react-flags-select";

const Header = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let userCompanyDetail = [];
  const path = window.location.pathname;
  let obj = {};
  const token = localStorage.getItem("token");
  const location = useLocation();
  if (token) {
    const decode = jwt_decode(token);
    obj = {
      Email: decode.user.Email,
      FirstName: decode.user.FirstName,
      Company: decode.user.companyname,
    };
    localStorage.setItem("userData", JSON.stringify(obj));
  }
  const userData = JSON.parse(localStorage.getItem("userData"));
  const profileData = JSON.parse(localStorage.getItem("profileData"));
  const userCompanyName = useSelector(getCompanyName);
  userCompanyDetail = useSelector(getSupplierCompany);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(false);
  const [showLinks, setShowLinks] = useState(false);
  const [isOldPasswordCorrect, setIsOldPasswordCorrect] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  // let token = localStorage.getItem("token");
  useEffect(() => {
  }, [location]);
  function handleLogout() {
    var selectedLang = localStorage.getItem('i18nextLng');
    localStorage.clear();
    localStorage.setItem('i18nextLng',selectedLang);
    window.location.href = "/";
  }
  const onProfileClickHandler = () => {
    setShowLinks(false);
    navigate("/profile");
    setMenuToggle(false);
  };
  const changePasswordModalHandler = () => {
    setShowLinks(false);
    setModalVisibility(true);
    setMenuToggle(false);
  };
  function changeNewPasswordHandler(e) {
    if (e.target.value) {
      setNewPassword(e.target.value);
    }
  }
  function changeConfirmPasswordHandler(e) {
    if (e.target.value) {
      setConfirmPassword(e.target.value);
    }
  }
  function onOkHandler() {
    if (
      oldPassword.length > 0 &&
      newPassword.length > 0 &&
      confirmPassword.length > 0
    ) {
      // if(isOldPasswordCorrect){
      if (newPassword === confirmPassword) {
        if (newPassword === oldPassword) {
          message.error(
            t(
              "KSuppPortal_NewPasswordMessage"
            ) /*"New password should not be same as old password"*/
          );
        } else {
          setOpen(true);
          const data = qs.stringify({
            newpass: newPassword,
            oldpassword: oldPassword,
          });
          const config = {
            method: "post",
            url: "/api/mprofile/newpasswordchange",
            headers: {
              Authorization: "bearer " + localStorage.getItem("token"),
              "Content-Type": "application/x-www-form-urlencoded",
            },
            data: data,
          };
          axios(config)
            .then(function (response) {
              message.success(
                t(
                  "KSuppPortal_ChangeMessage"
                ) /*"Password changed successfully"*/
              );
              setOldPassword("");
              setNewPassword("");
              setConfirmPassword("");
              setIsOldPasswordCorrect(false);
              setOpen(false);
              setModalVisibility(false);
            })
            .catch(function (error) {
              message.error(error.response.data.message);
              setOpen(false);
              // console.log(error);
            });
        }
      } else if (newPassword !== confirmPassword) {
        message.error(
          t("KSuppPortal_IncorrectPassword") /*"Password does not match"*/
        );
      }
     
    } else {
      message.info(
        t("KSuppPortal_FillMessage") /*"Please fill all the fields"*/
      );
    }
  }
  function onSubmit(e) {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError(true);
    } else {
      setError(false);
      onOkHandler();
    }
  }
  function changeOldPasswordHandler(e) {
    setOldPassword(e.target.value);
 
  }

  let switchUserCompany = (data) => {
    if (userCompanyName !== data.Customer_Name) {
      AuthAPI.switchUser(data)
        .then((r) => {})
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const [menuToggle, setMenuToggle] = useState(false);
  const [selected, setSelected] = useState("US");
  const [winWidth, setWinWidth] = useState(window.innerWidth);
 

  useEffect(() => {
    const updateWidth = ()=>{
      setWinWidth(window.innerWidth);
    };
    window.addEventListener('resize', updateWidth);
    return () => {
        window.removeEventListener('resize', updateWidth);
    }
}, [])

  useEffect(()=>{
    var x = localStorage.getItem("i18nextLng");
    let y;

    if(x === "en"){
      y="US";
    }
    else if(x === undefined){
      y="US";
    }
    else if(x === 'en-US'){
      y="US"
    }
    else{
      y="CN";
    }
    let v = (y === "US" || y === "en-US" || y === undefined) ? "en" :"zr";

    dispatch(changeLanguage(v));
    localStorage.setItem("i18nextLng",v);
    setSelected(y);

    window.addEventListener('keypress', e => {
      //e.keyCode == 27 escape
      if (e.keyCode == 27 || e.key === "Escape" || e.keyCode == 96) {
        setMenuToggle(!menuToggle);
      }
    });
  },[]);

  return (
    <><header className="main-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2 col-3 main-header-left-side">
              <img
                className="main-logo"
                onClick={() => {
                  navigate("/");
                }}
                src={MyLogo}
                alt="Logo"
                // style={{ width: "80px", height: "50px" }}
              />
            </div>
            
            {(token && path !== "/guid" && path !== "/delconfirm") ? (
              <div className="col-md-10 col-9 main-header-right-side">

                {(winWidth <= 804) && <>
                    {menuToggle ? 
                        <Button onClick={(e)=>setMenuToggle(!menuToggle)} type="default" className="btn-menu-toggle"><MenuUnfoldOutlined/></Button>
                        : 
                        <Button onClick={(e)=>setMenuToggle(!menuToggle)} type="default" className="btn-menu-toggle"><MenuFoldOutlined/></Button>
                    }              
                </>}
                
                <nav className={`menu-toggle ${menuToggle ? 'menu-toggle-open' : 'menu-toggle-close'}`}>

                <ReactFlagsSelect
                  className="language-changer mr-1"
                  showSelectedLabel={true}
                  showSecondarySelectedLabel={true}
                  showOptionLabel={true}
                  showSecondaryOptionLabel={true}
                  searchable={false}
                  countries={["US", "CN"]}
                  customLabels={{
                    US: "English",
                    CN: "中文", //t("KSuppPortal_Chinese")//"Chinese"
                  }}
                  selected={selected}
                  onSelect={(code) => {
                    setSelected(code);
                    let v = code === "US" ? "en" : "zr";
                    dispatch(changeLanguage(v));
                    i18n.changeLanguage(v).then((r) => {
                      LanguagesApi.changeUserLanguage(i18n.language)
                        .then((r) => {
                          console.log(r);
                        })
                        .catch((e) => {
                          console.log(e);
                        });
                      
                    });
                  }}
                />

                <DropdownButton
                    id="dropdown-button"
                    className="mr-1"
                    title={`${userData.FirstName},${
                      profileData ? profileData.Name : userCompanyName
                    }`}
                  >
                    {userCompanyDetail.length > 0 &&
                      userCompanyDetail.map((i, index) => {
                        // console.log(i);
                        return (
                          <>
                            <Dropdown.Item
                              onClick={() => {
                                switchUserCompany(i);
                                setMenuToggle(!menuToggle);
                              }}
                              className="dropBtns"
                              active={i.Customer_Name === userCompanyName}
                            >
                              {i.customername}, {i.Customer_Name}
                            </Dropdown.Item>
                          </>
                        );
                      })}
                  </DropdownButton>
                
                  <DropdownButton
                    id="dropdown-item-button"
                    title={
                      t("KSuppPortal_UserInformation") /*"User Information"*/
                    }
                    className="mr-1"
                  >
                    <Dropdown.Item
                      onClick={changePasswordModalHandler}
                      className="dropBtns"
                    >
                      {t("KSuppPortal_Change_Password ") /*Change Password*/}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={onProfileClickHandler}
                      className="dropBtns"
                    >
                      {t("KSuppPortal_Profile") /*Profile*/}
                    </Dropdown.Item>
                  </DropdownButton>
            
                  
                  <Button onClick={handleLogout} type="danger" className="text-center">
                    <span className="text-logout">{t("KSuppPortal_LogOut")}</span>
                    <PoweroffOutlined />
                  </Button>

                  </nav>
              
              </div>
            ):(<><div className="col-md-10 col-9 main-header-right-side">
            <nav className={`menu-toggle ${menuToggle ? 'menu-toggle-open' : 'menu-toggle-close'}`}>
              <ReactFlagsSelect
                className="language-changer mr-1"
                showSelectedLabel={true}
                showSecondarySelectedLabel={true}
                showOptionLabel={true}
                showSecondaryOptionLabel={true}
                searchable={false}
                countries={["US", "CN"]}
                customLabels={{
                  US: "English",
                  CN: "中文", //t("KSuppPortal_Chinese")//"Chinese"
                }}
                selected={selected}
                onSelect={(code) => {
                  setSelected(code);
                  let v = code === "US" ? "en" : "zr";
                  dispatch(changeLanguage(v));
                  i18n.changeLanguage(v).then((r) => {
                    LanguagesApi.changeUserLanguage(i18n.language)
                      .then((r) => {
                        console.log(r);
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                  });
                }}
              />
              </nav>
          </div></>)}
          </div>

          <Modal
            style={{ width: "500px" }}
            title={t("KSuppPortal_Change_Password ") /*"Change Password"*/}
            onOk={onOkHandler}
            visible={modalVisibility}
            destroyOnClose={true}
            onCancel={() => {
              setOldPassword("");
              setNewPassword("");
              setConfirmPassword("");
              setIsOldPasswordCorrect(false);
              setOpen(false);
              setModalVisibility(false);
              setMenuToggle(false);
            }}
            cancelText={t("KSuppPortal_Close") /*"Close"*/}
            okText={t("KSuppPortal_SaveChanges") /*"Save changes"*/}
            okButtonProps={{
              className:"btn btn-success",
            }}
            cancelButtonProps={{
              className:"btn btn-danger",
            }}
          >
            <Form
              className="form"
              onSubmit={onSubmit}
            >
              <div className="form-group row">
                <label htmlFor="oldPassword" className="col-sm-5 col-form-label">
                  {t("KSuppPortal_Old_Password") /*Old Password*/} :
                </label>
                <div className="col-sm-7">
                <input
                  onChange={changeOldPasswordHandler}
                  name="newPassword"
                  type="password"
                  className="form-control changePasswordInputs"
                  placeholder={t("KSuppPortal_Old_Password") /*Old Password*/}
                  required
                /></div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="recentPassword"
                  className="col-sm-5 col-form-label"
                >
                  {t("KSuppPortal_NewPassword") /*New Password*/} :
                </label>
                <div className="col-sm-7">
                <input
                  autoComplete="off"
                  onChange={changeNewPasswordHandler}
                  name="recentPassword"
                  type="password"
                  className="form-control"
                  placeholder={t("KSuppPortal_NewPassword") /*New Password*/}
                  required
                /></div>
              </div>

              <div className="form-group row">
                <label htmlFor="newPassword" className="col-sm-5 col-form-label">
                  {t("KSuppPortal_ConfirmNewPassword") /*Confirm New Password*/} :
                </label>
                <div className="col-sm-7">
                <input
                  onChange={changeConfirmPasswordHandler}
                  name="newPassword"
                  type="password"
                  className="form-control"
                  placeholder={
                    t("KSuppPortal_ConfirmNewPassword") /*Confirm New Password*/
                  }
                  required
                /></div>
              </div>
            </Form>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Modal>
        </div>
      </header>

      {menuToggle && <Backdrop
        sx={{ color: "#fff", zIndex: 1 }}
        open={menuToggle}
        onClick={(e)=>setMenuToggle(false)} //!menuToggle
      ></Backdrop>}
      
    </>
  );
};
export default Header;
