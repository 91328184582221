// import CreateIcon from "@mui/icons-material/Create";
import RectangleIcon from "@mui/icons-material/Rectangle";
// import CircleIcon from "@mui/icons-material/Circle";
import IconButton from "@mui/material/IconButton";
// import RemoveIcon from "@mui/icons-material/Remove";
// import DoneIcon from "@mui/icons-material/Done";
// import CloseIcon from "@mui/icons-material/Close";
// import TextFieldsIcon from "@mui/icons-material/TextFields";
import PanToolIcon from "@mui/icons-material/PanTool";
// import UndoIcon from "@mui/icons-material/Undo";
// import RedoIcon from "@mui/icons-material/Redo";
import {ArrowLeftOutlined, ArrowRightOutlined} from "@ant-design/icons";
import { useState, useEffect } from "react";

const tools = [
    // {
    //     name: "Pencil",
    //     icon: <CreateIcon />
    // },
    {
        name: "Rectangle",
        icon: <RectangleIcon />,
        txt:'Draw Shape',
    },
    // {
    //     name: "Circle",
    //     icon: <CircleIcon />
    // },
    // {
    //     name: "Line",
    //     icon: <RemoveIcon />
    // },
    // {
    //     name: "Tick",
    //     icon: <DoneIcon />
    // },
    // {
    //     name: "Cross",
    //     icon: <CloseIcon />
    // },
    // {
    //     name: "Text",
    //     icon: <TextFieldsIcon />
    // },
    {
        name: "Select",
        icon: <PanToolIcon/>,
        txt:'Move',
    }
];

const Tools = ({labelInputValue,setLabelInputValue, selectedLabel,setSelectedLabel, drawLabels,setDrawLabels, pdfWidth,setPdfWidth, showSideBar,onLabelClick,showData,setShowData ,onHeaderClick,onRowClick,isRow, setIsRow, isHeader,setIsHeader ,tool, setTool, undo, redo,onShowDataClick }) => {
    const [activeButton, setActiveButton] = useState(0);
    const selectTool = (event) => {
        // console.log(event.currentTarget.id);
        const currentTool = event.currentTarget.id;
        setTool(currentTool);
    };
    return (<>
        {/*{(window.innerWidth-pdfWidth < 500) && <div className={`${showSideBar ? "toolsContainer toolSideBarShow" : " toolsContainer toolSideBarHide "}`}>*/}
        {/*    <div className="d-flex">*/}
        {/*        {tools.map((t, ind) => {*/}
        {/*            return (*/}
        {/*                <IconButton*/}
        {/*                    style={{*/}
        {/*                        margin: "3px 7px",*/}
        {/*                        padding: '5px 10px',*/}
        {/*                        border: "2px solid #a11a10",*/}
        {/*                        borderRadius: "4px",*/}
        {/*                        width: "fit-content",*/}
        {/*                        cursor: "pointer",*/}
        {/*                        backgroundColor: t.name === tool ? "#a11a10" : "transparent",*/}
        {/*                        fontSize: '13px',*/}
        {/*                        color: t.name === tool ? "white" : "black",*/}
        {/*                    }}*/}
        {/*                    onClick={selectTool}*/}
        {/*                    id={t.name}*/}
        {/*                    key={t.name}*/}
        {/*                >*/}
        {/*                    <span style={{marginRight: '2px'}}>{t.icon}</span>*/}
        {/*                    <span style={{color: t.name === tool ? "white" : "black"}}>{t.txt}</span>*/}
        {/*                </IconButton>*/}
        {/*            );*/}
        {/*        })}*/}
        {/*        <IconButton style={{*/}
        {/*            border: "2px solid #a11a10",*/}
        {/*            borderRadius: "4px",*/}
        {/*            margin: "0px 2px",*/}
        {/*            padding: '5px 10px',*/}
        {/*            color: 'black!important'*/}
        {/*        }} size="small" onClick={undo}>*/}
        {/*            <ArrowLeftOutlined/>*/}
        {/*        </IconButton>*/}
        {/*        <IconButton style={{*/}
        {/*            border: "2px solid #a11a10",*/}
        {/*            borderRadius: "4px",*/}
        {/*            margin: "0px 2px",*/}
        {/*            padding: '5px 10px',*/}
        {/*            color: 'black!important'*/}
        {/*        }} size="small" onClick={redo}>*/}
        {/*            <ArrowRightOutlined/>*/}
        {/*        </IconButton>*/}
        {/*    </div>*/}
        {/*    <div style={{*/}
        {/*        display: "flex",*/}
        {/*        alignContent: "space-around",*/}
        {/*        padding: "4px",*/}
        {/*        justifyContent: "start",*/}
        {/*        width: "100%",*/}
        {/*        color: 'black!important',*/}
        {/*        flexWrap: 'wrap'*/}
        {/*    }}>*/}
        {/*        <button*/}
        {/*            // className={isHeader ? "sidebarBtnActive mx-1":"sidebarBtn mx-1"}*/}
        {/*            className={activeButton === 0 ? "sidebarBtnActive mx-1" : "sidebarBtn mx-1"}*/}
        {/*            // style={{ border: "2px solid #a11a10",borderRadius: "4px", margin: "0px 2px",padding:'5px 10px',color:'black!important' }}*/}
        {/*            size="small"*/}
        {/*            onClick={() => {*/}
        {/*                setActiveButton(0);*/}
        {/*                onLabelClick();*/}
        {/*            }}*/}
        {/*        >Select Labels*/}
        {/*        </button>*/}
        {/*        <button*/}
        {/*            // className={isHeader ? "sidebarBtnActive mx-1":"sidebarBtn mx-1"}*/}
        {/*            className={activeButton === 1 ? "sidebarBtnActive mx-1" : "sidebarBtn mx-1"}*/}
        {/*            // style={{ border: "2px solid #a11a10",borderRadius: "4px", margin: "0px 2px",padding:'5px 10px',color:'black!important' }}*/}
        {/*            size="small"*/}
        {/*            onClick={() => {*/}
        {/*                setActiveButton(1);*/}
        {/*                onHeaderClick();*/}
        {/*            }}*/}
        {/*        >Select Headers*/}
        {/*        </button>*/}

        {/*        <button*/}
        {/*            // className={isRow ? "sidebarBtnActive mx-1":"sidebarBtn mx-1"}*/}
        {/*            className={activeButton === 2 ? "sidebarBtnActive mx-1" : "sidebarBtn mx-1"}*/}
        {/*            // style={{ border: "2px solid #a11a10",borderRadius: "4px", margin: "0px 2px",padding:'5px 10px',color:'black!important' }}*/}
        {/*            size="small"*/}
        {/*            onClick={() => {*/}
        {/*                setActiveButton(2);*/}
        {/*                onRowClick();*/}
        {/*            }}*/}
        {/*        >Select Rows*/}
        {/*        </button>*/}
        {/*        <button*/}
        {/*            // className="sidebarBtn mx-1"*/}
        {/*            className={activeButton === 3 ? "sidebarBtnActive mx-1 mt-1" : "sidebarBtn mx-1 mt-1"}*/}
        {/*            onClick={() => {*/}
        {/*                setActiveButton(3);*/}
        {/*                onShowDataClick();*/}
        {/*            }}*/}
        {/*        >*/}
        {/*            Show Data*/}
        {/*        </button>*/}
        {/*    </div>*/}
        {/*</div>}*/}
        {/* For Normal PDF width of file*/}
        {/*(window.innerWidth-pdfWidth > 500) &&*/ <div className="originalDesign originalTools">
            <div className="d-flex">
                {tools.map((t, ind) => {
                    return (
                        <IconButton
                            style={{
                                margin: "3px 7px",
                                padding: '5px 10px',
                                border: "2px solid #a11a10",
                                borderRadius: "4px",
                                width: "fit-content",
                                cursor: "pointer",
                                backgroundColor: t.name === tool ? "#a11a10" : "transparent",
                                fontSize: '13px',
                                color: t.name === tool ? "white" : "black",
                            }}
                            onClick={selectTool}
                            id={t.name}
                            key={t.name}
                        >
                            <span style={{marginRight: '5px'}}>{t.icon}</span>
                            <span style={{color: t.name === tool ? "white" : "black"}}>{t.txt}</span>
                        </IconButton>
                    );
                })}
                <IconButton
                    style={{
                        border: "2px solid #a11a10",
                        borderRadius: "4px",
                        margin: "0px 2px",
                        padding: '5px 10px',
                        color: 'black!important'
                    }}
                    size="small"
                    onClick={undo}
                >
                    <ArrowLeftOutlined/>
                </IconButton>
                <IconButton
                    style={{
                        border: "2px solid #a11a10",
                        borderRadius: "4px",
                        margin: "0px 2px",
                        padding: '5px 10px',
                        color: 'black!important'
                    }}
                    size="small"
                    onClick={redo}
                >
                    <ArrowRightOutlined/>
                </IconButton>
                <button className={drawLabels ? 'sidebarBtnActive ms-2' : "sidebarBtn ms-2"} onClick={()=>{
                    setSelectedLabel('qwerasdfzxcvmkairos');
                    setDrawLabels(prev=>!prev);
                    if(drawLabels){
                        setLabelInputValue('');
                    }
                }}>Draw Label</button>
            </div>
            <div
                style={{
                    display: "flex",
                    alignContent: "space-around",
                    padding: "4px",
                    justifyContent: "start",
                    width: "100%",
                    color: 'black!important',

                }}
            >
                <button
                    // className={isHeader ? "sidebarBtnActive mx-1":"sidebarBtn mx-1"}
                    className={activeButton === 0 ? "sidebarBtnActive mx-1" : "sidebarBtn mx-1"}
                    // style={{ border: "2px solid #a11a10",borderRadius: "4px", margin: "0px 2px",padding:'5px 10px',color:'black!important' }}
                    size="small"
                    onClick={() => {
                        setActiveButton(0);
                        onLabelClick();
                    }}
                >Select Labels
                </button>
                <button
                    // className={isHeader ? "sidebarBtnActive mx-1":"sidebarBtn mx-1"}
                    className={activeButton === 1 ? "sidebarBtnActive mx-1" : "sidebarBtn mx-1"}
                    // style={{ border: "2px solid #a11a10",borderRadius: "4px", margin: "0px 2px",padding:'5px 10px',color:'black!important' }}
                    size="small"
                    onClick={() => {
                        setActiveButton(1);
                        onHeaderClick();
                    }}
                >Select Headers
                </button>

                <button
                    // className={isRow ? "sidebarBtnActive mx-1":"sidebarBtn mx-1"}
                    className={activeButton === 2 ? "sidebarBtnActive mx-1" : "sidebarBtn mx-1"}
                    // style={{ border: "2px solid #a11a10",borderRadius: "4px", margin: "0px 2px",padding:'5px 10px',color:'black!important' }}
                    size="small"
                    onClick={() => {
                        setActiveButton(2);
                        onRowClick();
                    }}
                >Select Rows
                </button>
                <button
                    // className="sidebarBtn mx-1"
                    className={activeButton === 3 ? "sidebarBtnActive mx-1" : "sidebarBtn mx-1"}
                    onClick={() => {
                        setActiveButton(3);
                        onShowDataClick();
                    }}
                >
                    Show Data
                </button>
            </div>
        </div>}
    </>);
};

export default Tools;
