import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationsEN from "../assets/lang/eng.json";
import translationsZR from "../assets/lang/zr.json";

const resources = {
  en: {
    translation: translationsEN,
  },
  zr: {
    translation: translationsZR,
  },
};

// const apiKey = "cOY9eVwPnQkkIIrEP4d-OQ";
// const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;
// i18next
//   .use(HttpBackend)
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     fallbackLng: "en",
//     ns: ["default"],
//     defaultNS: "default",
//
//     supportedLngs: ["en", "zh"],
//
//     backend: {
//       loadPath: loadPath,
//     },
//   });
i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  // .use(Cache)
  .init({
    resources,
    react: {
      useSuspense: true,
    },
    fallbackLng: "en", // default language
    keySeparator: false, // we do not use keys in form messages.welcome
    // interpolation: {
    //   escapeValue: false, // react already safes from xss
    // },
  });
export default i18next;
