export const column = [
  {
    title: "Orders#",
    width: "5%",
    dataIndex: "PODetail_PartNum",
    key: "POHeader_PONum",
    className: "text-center",
  },
  {
    title: "PO Num",
    width: "5%",
    dataIndex: "PODetail_LineDesc",
    key: "PODetail_LineDesc",
    className: "text-center",
  },
  {
    title: "Order Date",
    width: "8%",
    dataIndex: "PODetail_ClassID",
    key: "PODetail_ClassID",
    className: "text-center",
  },
  {
    title: "Status",
    width: "8%",
    dataIndex: "PORel_DueDate",
    key: "PORel_DueDate",
    className: "text-center",
  },
  {
    title: "Total Charges",
    width: "10%",
    dataIndex: "Calculated_DueDate",
    className: "text-center",
    key: "Calculated_DueDate",
  },
  {
    title: "Total Discount",
    width: "7%",
    dataIndex: "PORel_Confirmed",
    className: "text-center",
    key: "PORel_Confirmed",
  },
  {
    title: "GST",
    width: "8%",
    dataIndex: "PORel_KDelConfirm_c",
    className: "text-center",
    key: "PORel_KDelConfirm_c",
  },
  {
    title: "Total Net",
    width: "5%",
    dataIndex: "PORel_OpenRelease",
    className: "text-center",
    key: "PORel_OpenRelease",
  },
];
