import React from "react";

function Box(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill={props.color}
    >
      <path
        fill={props.color}
        fillRule="evenodd"
        d="M7 10V8a5 5 0 1110 0v2h1a2 2 0 012 2v6a2 2 0 01-2 2H6a2 2 0 01-2-2v-6a2 2 0 012-2h1zm5-5a3 3 0 00-3 3v2h6V8a3 3 0 00-3-3z"
      ></path>
    </svg>
  );
}

export { Box };
