import React from 'react';
import style from './style.css';

const SuccessComp = (props)=>{
    return (
        <>
        <div className='success text-center p-2 my-2'>
            {props.msg}
        </div>
        </>
    );
}
export default SuccessComp;