import React from "react";
import { Error, Logo, MyInput, Success } from "../../components/shared/index";
import { Form } from "reactstrap";
import { axios } from "../../api";
import qs from "qs";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import "./style.scss";

const ForgetPassword = (props) => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const navigate = useNavigate();
  const emailChangeHandler = (e) => {
    setEmail(e.target.value);
    // console.log('DONE');
    if (emailError) {
      setEmailError(false);
    }
    if (e.target.value.length > 0 && e.target.value.includes("@")) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };

  function onFormSubmit(e) {
    e.preventDefault();
    if (!emailError) {
      setOpen(true);
      let data = qs.stringify({
        email: email,
      });
      let config = {
        method: "post",
        url: "/cust/sendForgotEmail",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response));
          if (response.status === 200) {
            setOpen(false);
            setError(false);
            setSuccess(true);
            // console.log(JSON.stringify(response.data));
            navigate("/signin", { state: { forget: true } });
          }
        })
        .catch(function (error) {
          setOpen(false);
          setError(true);
          setSuccess(false);
          console.log(error.response.status);
          console.log(JSON.stringify(error));
        });
    }
  }

  return (
    <div
      className="page-forget"
      // style={{ backgroundColor: "rgba(146,69,64,255)", height: "100vh" }}
    >
      <div className="clearfix">
      <div className="w-50 mx-auto my-5">
        <div className="mb-5 text-center">
          <Logo />
        </div>
        <div className="text-white mb-2 text-center">
          <h1 className="text-white">
            {t("KSuppPortal_ResetPassword") /*Reset Password*/}
          </h1>
          <span className="text-white">
            {
              t(
                "KSuppPortal_ForgetPasswordDescription"
              ) /*Enter your details to reset your account password*/
            }
          </span>
        </div>
        <div style={{ height: "50px" }}>
          {error && (
            <Error
              msg={
                t("KSuppPortal_EmailNotExits")
                /*"Email Does Not Exist, Please contact MIT purchase department for account!"*/
              }
            />
          )}
          {success && (
            <Success
              msg={
                t(
                  "KSuppPortal_CheckEmail"
                ) /*"Check your mail to reset Password!"*/
              }
            />
          )}
        </div>
        <div className="mb-5">
          <Form onSubmit={onFormSubmit}>
            <MyInput
              for={"email"}
              label={t("KSuppPortal_Email") /*"Email"*/}
              type={"email"}
              onError={emailError}
              onChange={emailChangeHandler}
            />
            <div className="text-center mt-5">
              <button type="submit" className="myBtn">
                {t("KSuppPortal_SendEmail") /*Send Email*/}
              </button>
            </div>
          </Form>
          <div className="text-center mt-3">
            <a
              onClick={() => {
                navigate("/signin");
              }}
              className="forget text-white"
            >
              {t("KSuppPortal_SignIn") /*Sign In*/}
            </a>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      </div></div>
  );
};
export default ForgetPassword;
