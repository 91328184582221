import React from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const MyFooter = () => {
  const { t } = useTranslation();
  return (
    <div className="container-fluid">
      <div className="row">

        <div className="col-md-5">
          <span className="footer-text">
            2024 &copy; <a className="footerHeadingLink">Kairos</a> Version
            20240821-160830{" "}
          </span>
        </div>
        <div className="col-md-7 text-end">
          <a
            className="footer-link"
            href="https://kairossolutions.co/about-us/"
            target="_blank"
          >
            {t("KSuppPortal_About") /*About*/}
          </a>
          <a
            className="footer-link"
            href="https://kairossolutions.co/"
            target="_blank"
          >
            {t("KSuppPortal_Team") /*Team*/}
          </a>
          <a
            className="footer-link"
            href="https://kairossolutions.co/contact-us/"
            target="_blank"
          >
            {t("KSuppPortal_Contact") /*Contact*/}
          </a>
        </div>

        </div>
    </div>
  );
};
export default MyFooter;
