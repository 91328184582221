import { axios } from "../index";
import jwt_decode from "jwt-decode";

const Languages = {
  getAllLanguages: () => {
    return new Promise((resolve, reject) => {
      const config = {
        method: "post",
        // url: "/api/mcustdata/getLanguage",
        url: "/useremail/getLanguage",
        // headers: {
        //   Authorization: "Bearer " + localStorage.getItem("token"),
        // },
      };
      axios(config)
        .then(function (response) {
          resolve(response.data);
          const decodedUser = jwt_decode(response.data.token);
          localStorage.setItem(
            "i18nextLng",
            decodedUser.user.language === "English"
              ? "en"
              : decodedUser.user.language === "Chinese"
              ? "zr"
              : ""
          );
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  changeUserLanguage: (language) => {
    return new Promise((resolve, reject) => {
      const data = JSON.stringify({
        language:
          language === "en" ? "English" : language === "zr" ? "Chinese" : "",
      });

      const config = {
        method: "post",
        url: "/api/mprofile/addLanguage",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
};
export default Languages;
