import React from 'react';
import {axios} from '../index';
import qs from 'qs';

const OnDelConfirm = {
    onDelConfirm: (checkboxValue, rel,line, poNum, delConfirm=false,guid='') => {
        return new Promise((resolve, reject) => {
            let data,config;
            // console.log(poNum, line, checkboxValue, rel,localStorage.getItem("editBy"));
            if(delConfirm){
                data = JSON.stringify({
                    "PONum": poNum,
                    "line": line,
                    "release": rel,
                    "delconfirm": checkboxValue,
                    "updateby": localStorage.getItem("editBy"),
                    "myguid": guid
                });
                config = {
                    method: 'post',
                    url: '/useremail/polineconfirm',
                    headers: {
                        'Content-Type': 'application/json',},
                    data : data
                };
            }
            else{
                 data = JSON.stringify({
                    "PONum": poNum,
                    "line": line,
                    "release": rel,
                    "delconfirm": checkboxValue,
                    "email": "user@gmail.com"
                });
                 config = {
                    method: 'post',
                    url: '/api/mcustdata/polineconfirm',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                        'Content-Type': 'application/json',
                    },
                    data : data
                };
            }
            axios(config)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },
    onRelConfirm: (dateString, rel,line, poNum, relConfirm,updatedBy,guid) => {
        console.log('Date-->',dateString,'Rel-->' ,rel,'Line-->',line,'PoNum-->', poNum,'RelConfirm-->', relConfirm,'UpdatedBy-->',updatedBy,'Guid-->',guid);
        return new Promise((resolve, reject) => {
            let data,config;
            data = qs.stringify({
                'PONum': poNum,
                'line': line,
                'release': rel,
                'wdate': dateString,
                'updateby': updatedBy,
                'myguid': guid,
                'PORel_Confirmed': relConfirm
            });
             config = {
                method: 'post',
                url: '/useremail/polineduedate',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: data
            };
            axios(config)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },
}
export default OnDelConfirm;