import { M2Axios } from "../../axios";

const Dashboard = {
  getDashboardData: (email, password) => {
    return new Promise((resolve, reject) => {
      const config = {
        method: "post",
        url: "/api/mcustdata/getcustomerdata",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      M2Axios(config)
        .then(function (response) {
          localStorage.setItem(
            "profileData",
            JSON.stringify(response.data.customerdata.profiledata)
          );
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
};
export default Dashboard;
