import moment from "moment";
import { endpoints } from "../constants";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  changeLanguage,
  setAllLanguages,
  startLoading,
  stopLoading,
} from "../redux/languages";
import { LanguagesApi } from "../api";
import store from "../redux/store";

export const canChangeSupplierPromiseDate = (date) => {
  const start = moment(date);
  const end = moment().format("YYYY-MM-DD");
  let ddy = moment.duration(start.diff(end)).asDays();
  return !(ddy <= endpoints.BEFORE_DAYS - 1 && ddy >= 0);
};

export const hasLanguageInLocal = () => {
  const eng = JSON.parse(localStorage.getItem("eng"));
  const chinese = JSON.parse(localStorage.getItem("zr"));
  return !!(eng && chinese);
};
export const setUpTheLocale = async () => {
  let eng = localStorage.getItem("eng");
  let chinese = localStorage.getItem("zr");
  if (eng && chinese) {
    eng = JSON.parse(eng);
    chinese = JSON.parse(chinese);
    store.dispatch(
      setAllLanguages({
        eng: eng,
        chinese: chinese,
      })
    );
    const lang = localStorage.getItem("i18nextLng");
    store.dispatch(changeLanguage(lang === "zr" ? lang : "en"));
    store.dispatch(stopLoading());
  } else {
    store.dispatch(startLoading());
    const r = await LanguagesApi.getAllLanguages();
    let object = {};
    r.groupByLanguage.forEach((i, index) => {
      const keys = Object.keys(i);
      if (keys) {
        object[keys[0]] = i[keys[0]];
      }
    });
    console.log(object);
    // .then((r) => {
    // localStorage.setItem("eng", JSON.stringify(r.groupByLanguage[0].English));
    // localStorage.setItem("zr", JSON.stringify(r.groupByLanguage[1].Chinese));
    localStorage.setItem("eng", JSON.stringify(object?.English));
    localStorage.setItem("zr", JSON.stringify(object?.Chinese));
    store.dispatch(
      setAllLanguages({
        // eng: r.groupByLanguage[0].English,
        // chinese: r.groupByLanguage[1].Chinese,
        eng: object?.English,
        chinese: object?.Chinese,
      })
    );
    store.dispatch(stopLoading());
    // })
    // .catch((e) => {
    //     console.log(e);
    //     store.dispatch(stopLoading());
    // });
  }
};
