import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./style.scss";
import { MyCustomField } from "../../components/shared";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const Profile = () => {
  const { t, i18n } = useTranslation();
  const profileData = JSON.parse(localStorage.getItem("profileData"));
  const userData = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
  const [busy, setBusy] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  // console.log(searchParams.get("pro"));
  // React.useEffect(() => {
  //   i18n.reloadResources();
  // }, [i18n.language]);

  return (
    <>
      <Formik
        initialValues={{
          name: profileData.Name,
          phoneNumber: profileData.PhoneNum,
          country: profileData.Country,
          address: profileData.Address1,
          email: userData.Email,
        }}
        enableReinitialize={false}
        onSubmit={(values) => {
          // console.log(JSON.stringify(values, null, 2));
        }}
      >
        <div className="page-profile page-container">
          <div className="container-fluid">

            <div className="card">
              <header className="card-header">
                <div className="d-flex justify-content-between align-item-center">
                  <h4 className="card-title">{t("KSuppPortal_PersonalInformation") /*Personal Information*/}</h4>
                  <button
                  onClick={() => {
                    navigate("/");
                  }}
                  className="btn btn-primary"
                >
                  <span className="me-1 v-align-super">
                    <svg
                      className="v-align-baseline"
                      id="Layer_1"
                      data-name="Layer 1"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="white"
                      width="15px"
                      viewBox="0 0 122.88 108.06"
                    >
                      <path d="M63.94,24.28a14.28,14.28,0,0,0-20.36-20L4.1,44.42a14.27,14.27,0,0,0,0,20l38.69,39.35a14.27,14.27,0,0,0,20.35-20L48.06,68.41l60.66-.29a14.27,14.27,0,1,0-.23-28.54l-59.85.28,15.3-15.58Z" />
                    </svg>
                  </span>
                  <span>
                    {t("KSuppPortal_BackToHome") /*Back to Home*/}
                  </span>
                </button>
                </div>
              </header>
              <main className="card-body">
                <h5 className="h5 mb-3">
                  {t("KSuppPortal_CustomerInfo") /*Customer Info*/}
                </h5>
                <Form className="form row">
                  <div className="col-md-3">
                    <MyCustomField
                      for={"name"}
                      // fontSize={"13px"}
                      label={t("KSuppPortal_Name") /*"Name:"*/}
                      name={"name"}
                      readonly={true}
                    />
                  </div>
                  <div className="col-md-3">
                  <MyCustomField
                    for={"phoneNumber"}
                    // fontSize={"13px"}
                    label={t("KSuppPortal_PhoneNum") /*"Phone Number:"*/}
                    name={"phoneNumber"}
                    readonly={true}
                  />
                  </div>
                  <div className="col-md-3">
                  <MyCustomField
                    for={"country"}
                    // fontSize={"13px"}
                    label={t("KSuppPortal_Country") /*"Country:"*/}
                    name={"country"}
                    readonly={true}
                  />
                  </div>
                  <div className="col-md-3">
                  <MyCustomField
                    for={"address"}
                    // fontSize={"13px"}
                    label={t("KSuppPortal_Address1") /*"Address:"*/}
                    name={"address"}
                    readonly={true}
                  />
                  </div>
                  <div className="col-md-3">
                  <MyCustomField
                    for={"email"}
                    // fontSize={"13px"}
                    label={t("KSuppPortal_EMailAddress") /*"Email:"*/}
                    name={"email"}
                    readonly={true}
                  /></div>
                </Form>
              </main>
            </div>

            
          </div>
        </div>
      </Formik>
    </>
  );
};
export default Profile;
