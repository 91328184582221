import React from "react";

function MapTip(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill={props.color}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z"></path>
        <path
          fill={props.color}
          d="M9.828 16.657a7 7 0 114.343 0L12 21l-2.172-4.343zM12 12a2 2 0 100-4 2 2 0 000 4z"
        ></path>
      </g>
    </svg>
  );
}

export { MapTip };
