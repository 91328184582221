import { createSlice } from "@reduxjs/toolkit";
import i18next from "i18next";

const initialState = {
  busy: false,
  en: {},
  zr: {},
  selectedLanguage: "en",
};
const LanguagesReducer = createSlice({
  name: "languages",
  initialState: initialState,
  reducers: {
    setAllLanguages: (state = 0, action) => {
      state.en = action.payload.eng;
      state.zr = action.payload.chinese;
      i18next.addResources("en", "translation", state.en);
      i18next.addResources("zr", "translation", state.zr);
    },
    changeLanguage: (state = 0, action) => {
      state.selectedLanguage = action.payload;
    },
    startLoading: (state = 0, action) => {
      state.busy = true;
    },
    stopLoading: (state = 0, action) => {
      state.busy = false;
    },
  },
});

export const { setAllLanguages } = LanguagesReducer.actions;
export const { changeLanguage } = LanguagesReducer.actions;
export const { startLoading } = LanguagesReducer.actions;
export const { stopLoading } = LanguagesReducer.actions;
export const getLanguageState = (state) => state.languages.busy;
export const getEnglishLanguage = (state) => state.languages.eng;
export const getChineseLanguage = (state) => state.languages.zr; //chinese
export const getSelectedLanguage = (state) => state.languages.selectedLanguage;

export default LanguagesReducer.reducer;
