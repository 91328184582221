import React from 'react';
import logo from '../../../assets/logos/logo.png';
import './style.css';
import {useNavigate} from "react-router";

const Logo = ()=>{
    const navigate = useNavigate();
    return (
        <>
        <div className="logo text-center" style={{cursor: 'pointer'}} onClick={()=>{navigate('/signin')}}>
            <img className="forlogo" src={logo} alt="logo" />
        </div>
        </>
    )
}
export default Logo;