import "./style.scss";
import Tools from "./Tools";
import Canvas from "./Canvas";
import { Grid } from "@mui/material";
import {useState, useLayoutEffect, useEffect} from "react";
import {message} from 'antd';
import {useIsMounted} from "@react-pdf-viewer/core";
import {InvoicesAPI, PdfApi} from "../../api";
import html2canvas from "html2canvas";
import Tesseract from 'tesseract.js';

const useHistory = (initialState) => {
    const [index, setIndex] = useState(0);

    const [history, setHistory] = useState([initialState]);

    const setState = (action, overwrite = false) => {
        const newState =
            typeof action === "function" ? action(history[index]) : action;
        if (overwrite) {
            const historyCopy = [...history];
            historyCopy[index] = newState;
            setHistory(historyCopy);
        } else {
            const updatedState = [...history].slice(0, index + 1);
            setHistory([...updatedState, newState]);
            setIndex((prevState) => prevState + 1);
        }
    };

    const undo = () => index > 0 && setIndex((prevState) => prevState - 1);
    const redo = () =>
        index < history.length - 1 && setIndex((prevState) => prevState + 1);

    return [history[index], setState, undo, redo];
};

export default function App() {
    const [elements, setElements, undo, redo] = useHistory([]);
    const [tool, setTool] = useState("Rectangle");
    const [activeBtn, setActiveBtn] = useState([0,0,0,0,0,0,0]);
    const [selectedLabel, setSelectedLabel] = useState("");
    const [changed, setChanged] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [screenShot, setScreenShot] = useState('');
    const [cropedImages, setCropedImages] = useState([]);
    const [response, setResponse] = useState([]);
    let [allArray, setAllArray] = useState([]);
    // const [nextArray, setNextArray] = useState([]);
    const [labelValue, setLabelValue] = useState({
        VendorNum:"",
        InvoiceNum:"",
        InvoiceDate:"",
        DueDate:"",
        FiscalYear:"",
        FiscalPeriod:"",
        CurrencyCode:"",
    });
    useEffect(() => {
        // console.log(labelValue);
    },[setLabelValue])
    useEffect(()=>{
        console.log(elements);
    },[elements]);
    useEffect(()=>{
        // console.log(activeBtn);

    },[activeBtn]);
    useEffect(()=>{
        // console.log(selectedLabel);

    },[selectedLabel]);
    useEffect(()=>{
      // onSubmit();

    },[cropedImages]);
    useEffect(()=>{
        // console.log(elements);
    },[elements])
    const onButtonClicked = (index)=>{
        if(index === 0){
            const tempArray = activeBtn.map((btn,index)=>{
                if(index === 0){
                    if(btn === 0){
                        setSelectedLabel("VendorNum");
                        // btn = 1;
                    }
                    else{
                       setAllArray([]);
                        setLabelValue({
                            ...labelValue,
                            VendorNum:"",
                        })
                        setSelectedLabel("");
                        const tempE = [...elements];
                        let array = [];
                        for (let i = 0; i < tempE.length; i++) {
                            if(tempE[i].selectedLabel !== "VendorNum"){
                                array.push(tempE[i]);
                            }
                        }
                        const tempA = [...allArray];
                        let arrayA = [];
                        for (let i = 0; i < tempA.length; i++) {
                            if(tempA[i].selectedLabel !== "VendorNum"){
                                arrayA.push(tempA[i]);
                            }
                        }
                        if(arrayA.length > 0){
                            setAllArray(arrayA);
                        }else{
                            setAllArray([]);
                        }
                        const tempI = [...cropedImages];
                        let imagesArray = [];
                        for (let i = 0; i < tempI.length; i++) {
                            if(tempI[i].label !== "VendorNum"){
                                imagesArray.push(tempI[i]);
                            }
                        }
                        setCropedImages(imagesArray.length > 0 ? imagesArray : []);
                        // console.log('ONBUTTON CLIGKHUFVYK_______>>>>',array, array.length);
                        if(array.length > 0){
                            setElements(array);
                        }else{
                            setElements([]);
                        }
                        btn = 0;
                    }
                }
                return btn;
            });
            setActiveBtn(tempArray);
        }
        else if(index === 1){
            const tempArray = activeBtn.map((btn,index)=>{
                if(index === 1){
                    if(btn === 0){
                        setSelectedLabel("InvoiceNum");
                        // btn = 1;
                    }else{
                        setSelectedLabel("");
                        setLabelValue({
                            ...labelValue,
                            InvoiceNum:"",
                        })
                        const tempE = [...elements];
                        let array = [];
                        for (let i = 0; i < tempE.length; i++) {
                            if(tempE[i].selectedLabel !== "InvoiceNum"){
                                array.push(tempE[i]);
                            }
                        }
                        const tempA = [...allArray];
                        let arrayA = [];
                        for (let i = 0; i < tempA.length; i++) {
                            if(tempA[i].selectedLabel !== "InvoiceNum"){
                                arrayA.push(tempA[i]);
                            }
                        }
                        if(arrayA.length > 0){
                            setAllArray(arrayA);
                        }else{
                            setAllArray([]);
                        }
                        const tempI = [...cropedImages];
                        let imagesArray = [];
                        for (let i = 0; i < tempI.length; i++) {
                            if(tempI[i].label !== "InvoiceNum"){
                                imagesArray.push(tempI[i]);
                            }
                        }
                        setCropedImages(imagesArray.length > 0 ? imagesArray : []);
                        // console.log('ONBUTTON CLIGKHUFVYK_______>>>>',array, array.length);
                        if(array.length > 0){
                            setElements(array);
                        }else{
                            setElements([]);
                        }

                        btn = 0;
                    }
                }
                return btn;
            });
            setActiveBtn(tempArray);
        }
        else if(index === 2){

            const tempArray = activeBtn.map((btn,index)=>{
                if(index === 2){
                    if(btn === 0){
                        setSelectedLabel("InvoiceDate");
                        // btn = 1;
                    }else{
                        setSelectedLabel("");
                        setLabelValue({
                            ...labelValue,
                            InvoiceDate:"",
                        })
                        const tempE = [...elements];
                        let array = [];
                        for (let i = 0; i < tempE.length; i++) {
                            if(tempE[i].selectedLabel !== "InvoiceDate"){
                                array.push(tempE[i]);
                            }
                        }
                        const tempA = [...allArray];
                        let arrayA = [];
                        for (let i = 0; i < tempA.length; i++) {
                            if(tempA[i].selectedLabel !== "InvoiceDate"){
                                arrayA.push(tempA[i]);
                            }
                        }
                        if(arrayA.length > 0){
                            setAllArray(arrayA);
                        }else{
                            setAllArray([]);
                        }
                        const tempI = [...cropedImages];
                        let imagesArray = [];
                        for (let i = 0; i < tempI.length; i++) {
                            if(tempI[i].label !== "InvoiceDate"){
                                imagesArray.push(tempI[i]);
                            }
                        }
                        setCropedImages(imagesArray.length > 0 ? imagesArray : []);
                        // console.log('ONBUTTON CLIGKHUFVYK_______>>>>',array, array.length);
                        if(array.length > 0){
                            setElements(array);
                        }else{
                            setElements([]);
                        }

                        btn = 0;
                    }
                }
                return btn;
            });
            setActiveBtn(tempArray);
        }
        else if(index === 3){

            const tempArray = activeBtn.map((btn,index)=>{
                if(index === 3){
                    if(btn === 0){
                        setSelectedLabel("DueDate");
                        // btn = 1;
                    }else{
                        setSelectedLabel("");
                        setLabelValue({
                            ...labelValue,
                            DueDate:"",
                        })
                        const tempE = [...elements];
                        let array = [];
                        for (let i = 0; i < tempE.length; i++) {
                            if(tempE[i].selectedLabel !== "DueDate"){
                                array.push(tempE[i]);
                            }
                        }
                        const tempA = [...allArray];
                        let arrayA = [];
                        for (let i = 0; i < tempA.length; i++) {
                            if(tempA[i].selectedLabel !== "DueDate"){
                                arrayA.push(tempA[i]);
                            }
                        }
                        if(arrayA.length > 0){
                            setAllArray(arrayA);
                        }else{
                            setAllArray([]);
                        }
                        const tempI = [...cropedImages];
                        let imagesArray = [];
                        for (let i = 0; i < tempI.length; i++) {
                            if(tempI[i].label !== "DueDate"){
                                imagesArray.push(tempI[i]);
                            }
                        }
                        setCropedImages(imagesArray.length > 0 ? imagesArray : []);
                        // console.log('ONBUTTON CLIGKHUFVYK_______>>>>',array, array.length);
                        if(array.length > 0){
                            setElements(array);
                        }else{
                            setElements([]);
                        }

                        btn = 0;
                    }
                }
                return btn;
            });
            setActiveBtn(tempArray);
        }
        else if(index === 4){

            const tempArray = activeBtn.map((btn,index)=>{
                if(index === 4){
                    if(btn === 0){
                        setSelectedLabel("FiscalYear");
                        // btn = 1;
                    }else{
                        setSelectedLabel("");
                        setLabelValue({
                            ...labelValue,
                            FiscalYear:"",
                        })
                        const tempE = [...elements];
                        let array = [];
                        for (let i = 0; i < tempE.length; i++) {
                            if(tempE[i].selectedLabel !== "FiscalYear"){
                                array.push(tempE[i]);
                            }
                        }
                        const tempA = [...allArray];
                        let arrayA = [];
                        for (let i = 0; i < tempA.length; i++) {
                            if(tempA[i].selectedLabel !== "FiscalYear"){
                                arrayA.push(tempA[i]);
                            }
                        }
                        if(arrayA.length > 0){
                            setAllArray(arrayA);
                        }else{
                            setAllArray([]);
                        }
                        const tempI = [...cropedImages];
                        let imagesArray = [];
                        for (let i = 0; i < tempI.length; i++) {
                            if(tempI[i].label !== "FiscalYear"){
                                imagesArray.push(tempI[i]);
                            }
                        }
                        setCropedImages(imagesArray.length > 0 ? imagesArray : []);
                        // console.log('ONBUTTON CLIGKHUFVYK_______>>>>',array, array.length);
                        if(array.length > 0){
                            setElements(array);
                        }else{
                            setElements([]);
                        }

                        btn = 0;
                    }
                }
                return btn;
            });
            setActiveBtn(tempArray);
        }
        else if(index === 5){

            const tempArray = activeBtn.map((btn,index)=>{
                if(index === 5){
                    if(btn === 0){
                        setSelectedLabel("FiscalPeriod");
                        // btn = 1;
                    }else{
                        setSelectedLabel("");
                        setLabelValue({
                            ...labelValue,
                            FiscalPeriod:"",
                        })
                        const tempE = [...elements];
                        let array = [];
                        for (let i = 0; i < tempE.length; i++) {
                            if(tempE[i].selectedLabel !== "FiscalPeriod"){
                                array.push(tempE[i]);
                            }
                        }
                        const tempA = [...allArray];
                        let arrayA = [];
                        for (let i = 0; i < tempA.length; i++) {
                            if(tempA[i].selectedLabel !== "FiscalPeriod"){
                                arrayA.push(tempA[i]);
                            }
                        }
                        if(arrayA.length > 0){
                            setAllArray(arrayA);
                        }else{
                            setAllArray([]);
                        }
                        const tempI = [...cropedImages];
                        let imagesArray = [];
                        for (let i = 0; i < tempI.length; i++) {
                            if(tempI[i].label !== "FiscalPeriod"){
                                imagesArray.push(tempI[i]);
                            }
                        }
                        setCropedImages(imagesArray.length > 0 ? imagesArray : []);
                        // console.log('ONBUTTON CLIGKHUFVYK_______>>>>',array, array.length);
                        if(array.length > 0){
                            setElements(array);
                        }else{
                            setElements([]);
                        }

                        btn = 0;
                    }
                }
                return btn;
            });
            setActiveBtn(tempArray);
        }
        else if(index === 6){

            const tempArray = activeBtn.map((btn,index)=>{
                if(index === 6){
                    if(btn === 0){
                        setSelectedLabel("CurrencyCode");
                        // btn = 1;
                    }else{
                        setSelectedLabel("");
                        setLabelValue({
                            ...labelValue,
                            CurrencyCode:"",
                        })
                        const tempE = [...elements];
                        let array = [];
                        for (let i = 0; i < tempE.length; i++) {
                            if(tempE[i].selectedLabel !== "CurrencyCode"){
                                array.push(tempE[i]);
                            }
                        }
                        const tempA = [...allArray];
                        let arrayA = [];
                        for (let i = 0; i < tempA.length; i++) {
                            if(tempA[i].selectedLabel !== "CurrencyCode"){
                                arrayA.push(tempA[i]);
                            }
                        }
                        if(arrayA.length > 0){
                            setAllArray(arrayA);
                        }else{
                            setAllArray([]);
                        }
                        const tempI = [...cropedImages];
                        let imagesArray = [];
                        for (let i = 0; i < tempI.length; i++) {
                            if(tempI[i].label !== "CurrencyCode"){
                                imagesArray.push(tempI[i]);
                            }
                        }
                        setCropedImages(imagesArray.length > 0 ? imagesArray : []);
                        // console.log('ONBUTTON CLIGKHUFVYK_______>>>>',array, array.length);
                        if(array.length > 0){
                            setElements(array);
                        }else{
                            setElements([]);
                        }
                        btn = 0;
                    }
                }
                return btn;
            });
            setActiveBtn(tempArray);
        }
    }
    const onSubmit = (templateName)=>{
        if(selectedFile !== null) {
            const tempE = [...elements];
            let array = [];

            for (let i = 0; i < tempE.length; i++) {
                let o = {
                    x1: tempE[i].x1,
                    x2: tempE[i].x2,
                    y1: tempE[i].y2,
                    y2: tempE[i].y1,
                    label: tempE[i].selectedLabel,
                    image:cropedImages[i].image
                }
                array.push(o);
            }
            InvoicesAPI.uploadInvoiceWithCanvasSS(array).then(res=>{
                let object = {};
                for (let i = 0; i < res.length; i++) {
                    if(res[i].label == "VendorNum"){
                        setLabelValue({
                            ...labelValue,
                            VendorNum: res[i].value
                        });
                    }else if(res[i].label == "InvoiceNum"){
                        setLabelValue({
                            ...labelValue,
                            InvoiceNum: res[i].value
                        });
                    }else if(res[i].label == "InvoiceDate"){
                        setLabelValue({
                            ...labelValue,
                            InvoiceDate: res[i].value
                        });
                    }else if(res[i].label == "DueDate"){
                        setLabelValue({
                            ...labelValue,
                            DueDate: res[i].value
                        });
                    }else if(res[i].label === "FiscalYear"){
                        setLabelValue({
                            ...labelValue,
                            FiscalYear: res[i].value
                        });
                    }else if(res[i].label == "FiscalPeriod"){
                        setLabelValue({
                            ...labelValue,
                            FiscalPeriod: res[i].value
                        });
                    }else if(res[i].label == "CurrencyCode"){
                        setLabelValue({
                            ...labelValue,
                            CurrencyCode: res[i].value
                        });
                    }else {
                        setLabelValue({
                            VendorNum: "",
                            InvoiceNum: "",
                            InvoiceDate: "",
                            DueDate: "",
                            FiscalYear: "",
                            FiscalPeriod: "",
                            CurrencyCode: "",
                        })
                    }
                }
                setResponse(res);
            }).catch(err=>{
                setLabelValue({
                    VendorNum: "",
                    InvoiceNum: "",
                    InvoiceDate: "",
                    DueDate: "",
                    FiscalYear: "",
                    FiscalPeriod: "",
                    CurrencyCode: "",
                })
                setResponse([]);
            })
        }else{
            message.info('Please select File');
        }
    }
    function onSaveTheTemplate(templateName) {
        const tempE = [...allArray,...elements];

        let array = [];
        for (let i = 0; i < tempE.length; i++) {
            let o={
                x1: tempE[i].x1,
                x2: tempE[i].x2,
                y1: tempE[i].y2,
                y2: tempE[i].y1,
                label: tempE[i].selectedLabel,
                currentPage: tempE[i].currentPage,
                keys: i === 0 ? [...activeBtn] : '',
            }
            array.push(o);
        }
        PdfApi.saveTemplate(array,templateName).then(res=>{
            // console.log(res,'success in saving template');
            message.info('Template Saved Successfully');
            setElements([]);
            setLabelValue({
                VendorNum: "",
                InvoiceNum: "",
                InvoiceDate: "",
                DueDate: "",
                FiscalYear: "",
                FiscalPeriod: "",
                CurrencyCode: "",
            })
            const temp = [...activeBtn];
            const t = temp.map((btn)=>{
                return btn = 0;
            });
            setActiveBtn(t);
        }).catch(err=>{
            // console.log('error in saving template')
        });
    }
    function dataURLtoFile(dataurl, filename) {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {type:mime});
    }
    const onScreenShotDone = (cropPositionLeft, cropPositionTop,cropWidth, cropHeigth,selectedLabel)=>{
        const body = document.querySelector('body');
        html2canvas(body).then(async (canvas) => {
            let croppedCanvas = document.createElement('canvas');
            let croppedCanvasContext = croppedCanvas.getContext('2d');
            croppedCanvasContext.drawImage(
                canvas,
                cropPositionLeft,
                cropPositionTop,
                cropWidth,
                cropHeigth,
                0,
                0,
                cropWidth,
                cropHeigth
            );
            setScreenShot(croppedCanvas.toDataURL());
            const file = dataURLtoFile(croppedCanvas.toDataURL(), 'capture.png');
            let textData = await Tesseract.recognize(
                file,
                'eng',
                {logger: m => console.log('logger-->', m)}
            );
            const {text} = textData.data
            if(selectedLabel == "VendorNum"){
                setLabelValue(
                    {
                        ...labelValue,
                        VendorNum:text
                    }
                )
            }
            else if(selectedLabel == "InvoiceNum"){
                setLabelValue(
                    {
                        ...labelValue,
                        InvoiceNum:text
                    }
                )
            }
            else if(selectedLabel == "InvoiceDate"){
                setLabelValue(
                    {
                        ...labelValue,
                        InvoiceDate:text
                    }
                )
            }
            else if(selectedLabel == "DueDate"){
                setLabelValue(
                    {
                        ...labelValue,
                        DueDate:text
                    }
                )
            }
            else if(selectedLabel == "FiscalYear"){
                setLabelValue(
                    {
                        ...labelValue,
                        FiscalYear:text
                    }
                )
            }
            else if(selectedLabel == "FiscalPeriod"){
                setLabelValue(
                    {
                        ...labelValue,
                        FiscalPeriod:text
                    }
                )
            }
            else if(selectedLabel == "CurrencyCode"){
                setLabelValue(
                    {
                        ...labelValue,
                        CurrencyCode:text
                    }
                )
            }
            const o = {
                label: selectedLabel,
                image: croppedCanvas.toDataURL(),
            };
            setCropedImages((prev) => [...prev, o]);
        });
    }
    return (
        <>
        <div className="pdfcanvas" id={"pdfID"} style={{height:'891px'}}>
            <Grid
                container
                justifyContent="flex-start"
            >

                <Grid item>
                    <Canvas allArray={allArray} setAllArray={setAllArray} onSaveTheTemplate={onSaveTheTemplate} setLabelValue={setLabelValue} labelValue={labelValue} setResponse={setResponse} response={response} setCroppedImage={setCropedImages} screenCapture={screenShot} onScreenShotDone={onScreenShotDone} setSelectedFile={setSelectedFile} onSubmit={onSubmit} activeBtn={activeBtn} setActiveBtn={setActiveBtn} selectedLabel={selectedLabel} setSelectedLabel={setSelectedLabel} onButtonClicked={onButtonClicked} setChanged={setChanged} tool={tool} elements={elements} setElements={setElements}  />
                </Grid>
                <Grid item >
                    <Tools tool={tool} setTool={setTool} undo={undo} redo={redo} style={{position:'absolute !important',right:'226px !important',top:'41px'}} />
                </Grid>
            </Grid>
        </div>
        </>
    );
}
