import { createSlice } from "@reduxjs/toolkit";

const initialState= {
    docHeaders:[],
    docRowData:[],
    docLabels:[],
    labelValues:[],
    docId:'',
    pdfBase64:'',
    pdfName:'',
    templateName:'',
};
const PdfReducer = createSlice({
    name: "dashboard",
    initialState:initialState,
    reducers: {
        setDocHeaders: (state = 0, action) => {
            state.docHeaders = action.payload;
        },
        setDocRowData: (state = 0, action) => {
            state.docRowData =action.payload;
        },
        setDocLabels: (state = 0, action) => {
            state.docLabels =action.payload;
        },
        setDocLabelValues: (state = 0, action) => {
            state.labelValues =action.payload;
        },
        setDocId: (state = 0, action) => {
            state.docId =action.payload;
        },
        setPdfBase64: (state = 0, action) => {
            state.pdfBase64 =action.payload;
        },
        setPdfName: (state = 0, action) => {
            state.pdfName =action.payload;
        },
        setTemplateName: (state = 0, action) => {
            state.templateName =action.payload;
        },
    },
});

export const { setDocHeaders } = PdfReducer.actions;
export const { setDocRowData } = PdfReducer.actions;
export const { setDocLabels } = PdfReducer.actions;
export const { setDocLabelValues } = PdfReducer.actions;
export const { setDocId } = PdfReducer.actions;
export const { setPdfBase64 } = PdfReducer.actions;
export const { setPdfName } = PdfReducer.actions;
export const { setTemplateName } = PdfReducer.actions;
export const getDocHeaders = (state) => state.pdf.docHeaders;
export const getDocRowData = (state) => state.pdf.docRowData;
export const getDocLabels = (state) => state.pdf.docLabels;
export const getLabelValues = (state) => state.pdf.labelValues;
export const getDocId = (state) => state.pdf.docId;
export const getPdfBase64 = (state) => state.pdf.pdfBase64;
export const getPdfName = (state) => state.pdf.pdfName;
export const getTemplateName = (state) => state.pdf.templateName;

export default PdfReducer.reducer;
