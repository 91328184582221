import React from 'react';
import style from './style.css';

const ErrorCom = (props)=>{
    return (
        <>
       <div className='error text-center p-2 my-2'>
           {props.msg}
       </div>
        </>
            );
}
export default ErrorCom;