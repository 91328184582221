import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { MainLayout } from "./components/layout";
import {
  SignIn,
  Home,
  Profile,
  ForgetPassword,
  Guid,
  DeliveryConfirm,
  PdfView,
  PdfCanvas,
  PdfCanvasSS,
  PdfModule,
  PdfModuleScreen,
  Page404,
} from "./pages";
import PrivateRoute from "./protectedRoute";
import PublicRoute from "./publicRoute";
import "./scss/main.scss";
import { CustomerPortalHome } from "./pages/customerPortal";
import { setAllLanguages, startLoading, stopLoading } from "./redux/languages";
import { LanguagesApi } from "./api";
import { ConfigProvider } from "antd";
import moment from "moment";
import "moment/locale/zh-cn";

import { useDispatch } from "react-redux";
import { setUpTheLocale } from "./functions";
moment.locale("en");
function App() {
  const dispatch = useDispatch();
  let token = localStorage.getItem("token");
  let path = window.location.pathname;
  const [local, setLocal] = React.useState(false);
  useEffect(() => {
    setUpTheLocale().then((r) => {
      setLocal(true);
    });
  }, []);
  function renderRoutes() {
    if (token || path === "/guid" || path === "/delconfirm") {
      return (
        <Routes>
          <Route path="*" element={<Page404 />} />
          {/*{decode.user.role.toLowerCase() !== "custcnt" ? (*/}
          <Route exact path="/" element={<PrivateRoute />}>
            <Route exact path="/" element={<Home />} />
          </Route>
          {/*) : (*/}
          {/*  <Route exact path="/" element={<PrivateRoute />}>*/}
          {/*    <Route exact path="/" element={<CustomerPortalHome />} />*/}
          {/*  </Route>*/}
          {/*)}*/}
          <Route exact path="/invoices" element={<PrivateRoute />}>
            <Route exact path="/invoices" element={<Home />} />
          </Route>
          <Route exact path="/purchase-order" element={<PrivateRoute />}>
            <Route exact path="/purchase-order" element={<Home />} />
          </Route>
          <Route exact path="/profile" element={<PrivateRoute />}>
            <Route exact path="/profile" element={<Profile />} />
          </Route>
          <Route exact path="/forgetpassword" element={<PublicRoute />}>
            <Route exact path="/forgetpassword" element={<ForgetPassword />} />
          </Route>
          <Route exact path="/signin" element={<PublicRoute />}>
            <Route exact path="/signin" element={<SignIn />} />
          </Route>
          <Route exact path="/customerportal" element={<PrivateRoute />}>
            <Route
              exact
              path="/customerportal"
              element={<CustomerPortalHome />}
            />
          </Route>
          {/*<Route exact path='/pdfview' element={<PrivateRoute/>}>*/}
          {/*	<Route exact path='/pdfview' element={<PdfView/>}/>*/}
          {/*</Route>*/}
          {/*<Route exact path='/pdfcanvas' element={<PrivateRoute/>}>*/}
          {/*	<Route exact path='/pdfcanvas' element={<PdfCanvas/>}/>*/}
          {/*</Route>*/}
          {/*<Route exact path='/pdfmodule' element={<PrivateRoute/>}>*/}
          {/*	<Route exact path='/pdfmodule' element={<PdfModule/>}/>*/}
          {/*</Route>*/}
          {/*<Route exact path='/pdfmodulescreen' element={<PrivateRoute/>}>*/}
          {/*	<Route exact path='/pdfmodulescreen' element={<PdfModuleScreen/>}/>*/}
          {/*</Route>*/}
          {/*<Route exact path='/pdfcanvasSS' element={<PrivateRoute/>}>*/}
          {/*	<Route exact path='/pdfcanvasSS' element={<PdfCanvasSS/>}/>*/}
          {/*</Route>*/}
          <Route exact path="/guid" element={<PublicRoute />}>
            <Route exact path="/guid" element={<Guid />} />
          </Route>
          <Route exact path="/delconfirm" element={<PublicRoute />}>
            <Route exact path="/delconfirm" element={<DeliveryConfirm />} />
          </Route>
          {/*<Route exact path='/pdfmodule' element={<PrivateRoute/>}>*/}
          {/*	<Route exact path='/pdfmodule' element={<PdfModule/>}/>*/}
          {/*</Route>*/}
        </Routes>
      );
    } else {
      return (
        <Routes>
          <Route path="*" element={<Page404 />} />
          <Route exact path="/signin" element={<PublicRoute />}>
            <Route exact path="/signin" element={<SignIn />} />
          </Route>
          <Route exact path="/" element={<PrivateRoute />}>
            <Route exact path="/" element={<Home />} />
          </Route>
          <Route exact path="/forgetpassword" element={<PublicRoute />}>
            <Route exact path="/forgetpassword" element={<ForgetPassword />} />
          </Route>
          {/*<Route exact path='/pdfcanvas' element={<PublicRoute/>}>*/}
          {/*	<Route exact path='/pdfcanvas' element={<PdfCanvas/>}/>*/}
          {/*</Route>*/}
          <Route exact path="/" element={<PrivateRoute />}>
            <Route exact path="/profile" element={<Profile />} />
          </Route>
          <Route exact path="/guid" element={<PublicRoute />}>
            <Route exact path="/guid" element={<Guid />} />
          </Route>
          <Route exact path="/delconfirm" element={<PublicRoute />}>
            <Route exact path="/delconfirm" element={<DeliveryConfirm />} />
          </Route>
        </Routes>
      );
    }
  }

  return <Router>{renderRoutes()}</Router>;
}

export default App;
