import { createSlice } from "@reduxjs/toolkit";

const initialState= {
    poData:[],
    invoices:[],
    open:'',
    companyName:'',
    supplierCompany:[],
};
const DashboardReducer = createSlice({
    name: "dashboard",
    initialState:initialState,
    reducers: {
        setPoData: (state = 0, action) => {
            state.poData = action.payload;
        },
        setInvoices: (state = 0, action) => {
            state.invoices =action.payload;
        },
        setOpenRedux: (state = 0, action) => {
            state.open =action.payload;
        },
        setCompanyName: (state = 0, action) => {
            state.companyName =action.payload;
        },
        setSupplierCompany: (state = 0, action) => {
            state.supplierCompany =action.payload;
        },
    },
});

export const { setPoData } = DashboardReducer.actions;
export const { setInvoices } = DashboardReducer.actions;
export const { setOpenRedux } = DashboardReducer.actions;
export const { setCompanyName } = DashboardReducer.actions;
export const { setSupplierCompany } = DashboardReducer.actions;
export const getPoData = (state) => state.dashboard.poData;
export const getInvoices = (state) => state.dashboard.invoices;
export const getOpen = (state) => state.dashboard.open;
export const getCompanyName = (state) => state.dashboard.companyName;
export const getSupplierCompany = (state) => state.dashboard.supplierCompany;

export default DashboardReducer.reducer;
