import React from 'react';
import {axios} from '../index';
import ax from 'axios';
import qs from 'qs';

const Invoices = {
    uploadInvoice: (data) => {
        return new Promise((resolve, reject) => {
            let config = {
                method: 'post',
                // url: '/api/mcustdata/poinvoice',
                url: '/api/mcustdata/poinvoice',
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('token'),
                    "Content-Type": "multipart/form-data",
                },
                data : data
            };
            axios(config)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },
    uploadInvoiceWithCanvas: (file,data1,) => {
        return new Promise((resolve, reject) => {
            let data = new FormData();
            data.append('file', file);
            data.append('templateName', 'templatefromWeb');
            data.append('templateData', JSON.stringify(data1));
            data.append('createdBy', 'Kairos');
            data.append('tempSave', 'false');
            let config = {
                method: 'post',
                // url: 'http://192.168.100.57:3322/api/mcustdata/mapdata',
                url: '/api/mcustdata/mapdata',
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('token'),
                },
                data : data
            };
            axios(config)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },
    uploadInvoiceWithCanvasSS: (data1,) => {
        return new Promise((resolve, reject) => {
            let data = JSON.stringify({
                "templateName": "Testing",
                "templateData": data1,
                "createdBy": "Kairos",
                "tempSave": false
            });

            let config = {
                method: 'post',
                url: '/api/ocr/imagetotext',
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                data : data
            };
            axios(config)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },
}
export default Invoices;