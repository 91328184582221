import React from 'react';
import { Select } from 'antd';
import {PdfApi} from '../../../api';
import {message} from 'antd';
const { Option } = Select;

const App = (props) =>{
    const [options, setOptions] = React.useState([]);
    const onChange = (value) => {
        if(props.file !==null) {
            PdfApi.getPdfTemplateById(value).then((res) => {
                props.onChange(res);
            }).catch((error) => {
                if (error.response.status === 404) {
                    message.info('There is no data against selected template!')
                }
            });
        }else{
            message.info('Please select a file first!')
        }
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };
    React.useEffect(()=>{
        console.log('useEffect in drop down');
        PdfApi.getUsersPdfTemplate().then((res)=>{
            setOptions(res.data);
        }).catch((err)=>{
            console.log(err);
        })
    },[props.loadDropDownMenu]);
    return (<>
            <Select
                className="topFileBtns forInputSettings"
                showSearch
                placeholder="Select Template"
                optionFilterProp="children"
                onSelect={onChange}
                // onChange={onChange}
                onSearch={onSearch}
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                {options.map((item, index) => {
                    return(
                        <Option value={item._id} key={index}>
                        {item.templateName}
                    </Option>
                    )
                })}
            </Select>
        </>)
}

export default App;