import React from 'react';
import { Field, Form, ErrorMessage } from 'formik';
import style from './style.css';

const MyCustomField = (props) => {
    return (
        <>
            <div className="form-group">
                <label htmlFor={props.for} className="label" style={{fontSize:props.fontSize}}>{props.label}</label>
                <Field name={props.name} type="text" className="form-control" readOnly={props.readonly}/>
            </div>
        </>
    );
}
export default MyCustomField;