import { canChangeSupplierPromiseDate } from "../../../functions";
import moment from "moment";
import { DatePicker, Tooltip } from "antd";
import React, { useEffect } from "react";
import { messageSPDDays, messageSPDLimitExceeded } from "../index";
import { useTranslation } from "react-i18next";
import { endpoints } from "../../../constants";

const CommonOf3 = ({ record, onChange }) => {
  const { t } = useTranslation();

  let canChange = true;
  let counter = record?.PORel_PromiseDateLimiter_c;
  if (record?.Calculated_DueDate) {
    canChange = canChangeSupplierPromiseDate(
      moment(record?.Calculated_DueDate).format("YYYY-MM-DD")
    );
  }
  return (
    <>
      <span>
        {!record?.PORel_VoidRelease &&
        record?.PORel_OpenRelease &&
        canChange &&
        counter <= 2 ? (
          record?.Calculated_DueDate ? (
            <DatePicker
              style={{ width: "120px" }}
              className="transitionDatePicker"
              allowClear={false}
              onChange={(date, dateString) =>
                onChange(
                  date,
                  dateString,
                  record.POHeader_PONum,
                  record.PODetail_POLine,
                  record.PORel_PORelNum,
                  0,
                  record.PODetail_PartNum,
                  record.PORel_Confirmed,
                  record.PORel_PromiseDateLimiter_c
                )
              }
              value={moment(
                moment(record?.Calculated_DueDate).format("YYYY-MM-D"),
                "YYYY-MM-DD"
              )}
              format={"YYYY-MM-DD"}
            />
          ) : (
            <DatePicker
              style={{ width: "120px" }}
              className="transitionDatePicker"
              allowClear={false}
              onChange={(date, dateString) =>
                onChange(
                  date,
                  dateString,
                  record.POHeader_PONum,
                  record.PODetail_POLine,
                  record.PORel_PORelNum,
                  0,
                  record.PODetail_PartNum,
                  record.PORel_Confirmed,
                  record.PORel_PromiseDateLimiter_c
                )
              }
              format={"YYYY-MM-DD"}
            />
          )
        ) : (
          <Tooltip
            title={
              counter >= 3
                ? t("KSuppPortal_LimitExceeded") /*messageSPDLimitExceeded*/
                : !canChange
                ? `${t("KSuppPortal_LimitText1")}  ${endpoints.BEFORE_DAYS} ${t(
                    "KSuppPortal_LimitText2"
                  )}` /*messageSPDDays*/
                : ""
            }
            key={"red"}
          >
            {record?.Calculated_DueDate
              ? record?.Calculated_DueDate?.split("T")[0]
              : ""}
          </Tooltip>
        )}
      </span>
    </>
  );
};
export default CommonOf3;
