import React from "react";
import { Modal } from "antd";
import style from "./style.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MyExpireErrorModal = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  function onOkHandler() {
    if (props.guid) {
      props.setVisible(false);
      window.location.href = "/signin";
    } else {
      props.setVisible(false);
    }
  }

  return (
    <>
      <Modal
        zIndex={1001}
        width={props.width}
        title={props.title}
        visible={props.visible}
        onOk={onOkHandler}
        onCancel={() => {
          console.log("CANCEL");
          if (props.guid) {
            props.setVisible(false);
            window.location.href = "/signin";
          } else {
            props.setVisible(false);
          }
        }}
        okText={t("KSuppPortal_OK") /*Ok*/}
        okButtonProps={{
          style: {
            fontSize: "13px",
            backgroundColor: "#B8564C",
            color: "#FFFFFF",
            borderColor: "#B8564C",
            width: "120px",
            height: "45px",
            textAlign: "center",
            veticalAlign: "center",
          },
        }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div>
          <label htmlFor="comment" className="me-2 comment">
            {props.msg}
          </label>
        </div>
      </Modal>
    </>
  );
};
export default MyExpireErrorModal;
