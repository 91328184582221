import React from 'react';
import {axios} from '../index';
import ax from 'axios';
import qs from 'qs';

const Pdf = {
    getUsersPdfTemplate: () => {
        return new Promise((resolve, reject) => {
            const config = {
                method: 'get',
                url: '/api/ocr/getalltemplates',
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('token'),
                }
            };
            axios(config)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    reject(error);
                });

        });
    },
    getPdfTemplateById: (id) => {
        return new Promise((resolve, reject) => {
            const config = {
                method: 'get',
                url: '/api/ocr/gettemplatebyid?id='+id,
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('token'),
                }
            };

            axios(config)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    console.log(error);
                    reject(error);
                });


        });
    },
    saveTemplate: (templateData,templateHeaders,templateName) => {
        return new Promise((resolve, reject) => {
            const data = JSON.stringify({
                "templateName": templateName,
                "templateData": templateData,
                "templateHeaders": templateHeaders,
                "createdBy": "Kairos"
            });
            const config = {
                method: 'post',
                url: '/api/ocr/savetemplate',
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('token'),
                    'Content-Type': 'application/json',
                },
                data : data
            };
            axios(config)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                    reject(error);
                });
        });
    },
    saveTemplateWithData: (labelData,headerData,rowArray, pdf,tempName,pdfName) => {
        return new Promise((resolve, reject) => {
            let data = new FormData();
            data.append('file', pdf);
            data.append('labeldata', JSON.stringify(labelData));
            data.append('headerdata', JSON.stringify(headerData));
            data.append('rowdata', JSON.stringify(rowArray));
            data.append('pdfdata', "");
            data.append('tempname', tempName);
            data.append('pdfname',pdfName);
            let config = {
                method: 'post',
                // url: '/api/ocr/savedata',
                url: 'api/pdf/savedatafinal',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    // 'Content-Type': 'multipart/form-data',
                },
                data : data
            };
            axios(config)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                    reject(error);
                });
        });
    },
    updateTemplateWithData: (docId,labelData,headerData,rowArray,tempName,pdfName) => {
        return new Promise((resolve, reject) => {
            const data = JSON.stringify({
                "docid": docId,
                "labeldata": JSON.stringify(labelData),
                "headerdata": JSON.stringify(headerData),
                "rowdata": JSON.stringify(rowArray),
                "pdfname": pdfName,
                "tempname": tempName
            });
            const config = {
                method: 'post',
                url: '/api/ocr/updatepdfdata',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json',
                },
                data : data
            };
            axios(config)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                    reject(error);
                });
        });
    },
    deleteTemplate: (id) => {
        return new Promise((resolve, reject) => {
            const config = {
                method: 'delete',
                url: '/api/ocr/deletetemplate?id='+id,
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('token'),
                    'Content-Type': 'application/json',
                },
            };
            axios(config)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                    reject(error);
                });
        });
    },
    getTemplateData: () => {
        return new Promise((resolve, reject) => {
            let top = 100, skip = 0;
            let config = {
                method: 'get',
                url: `/api/ocr/getpdfdata?skip=${skip}&top=${top}`,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json',
                }
            };
            axios(config)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                    reject(error);
                });
        });
    },
    getDocDataById: (id) => {
        return new Promise((resolve, reject) => {
            const config = {
                method: 'get',
                url: '/api/ocr/getpdfdatabyid?docid='+id,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json',
                }
            };
            axios(config)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                    reject(error);
                });
        });
    },
    deleteDocById: (id) => {
        return new Promise((resolve, reject) => {
            const config = {
                method: 'delete',
                url: '/api/ocr/deletepdfdata?docid='+id,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json',
                }
            };
            axios(config)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                    reject(error);
                });
        });
    },
    postPdfData: (id) => {
        return new Promise((resolve, reject) => {
            let data = JSON.stringify({
                "pdfid": id
            });
            const config = {
                method: 'post',
                url: '/api/pdf/postpdfdata',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                data : data
            };
            axios(config)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                    reject(error);
                });
        });
    },
}
export default Pdf;