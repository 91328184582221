import React, {useEffect, useState} from 'react';
import {Upload, message, Modal, Form, Input, Button, InputNumber, DatePicker, Spin} from 'antd';
import { InboxOutlined,ArrowLeftOutlined } from '@ant-design/icons';
import style from './style.scss';
import jwt_decode from "jwt-decode";
import {InvoicesAPI} from "../../../api";
const { Dragger } = Upload;

export default (props) => {
    const [uploadClicked, setUploadClicked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedPDF, setSelectedPDF] = useState([]);
    const [selectedCSVXML, setSelectedCSVXML] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [body, setBody] = useState({});
    useEffect(() => {
        if(selectedPDF.length > 0 && selectedCSVXML.length > 0){
            setSelectedFiles([selectedPDF[0],selectedCSVXML[0]]);
        }else{
            setSelectedFiles(selectedPDF);
        }
    }, [selectedPDF]);
    useEffect(() => {
        if(selectedPDF.length > 0 && selectedCSVXML.length > 0){
            setSelectedFiles([selectedPDF[0],selectedCSVXML[0]]);
        }else{
            setSelectedFiles(selectedCSVXML);
        }
    }, [selectedCSVXML]);
    const pdfProps = {
        name: 'file',
        multiple: true,
        beforeUpload: file => {
            const isPdf = file.type === 'application/pdf';
            if (!isPdf) {
                message.error(`${file.name} is not a Pdf file`);
            }
            return false;
        },
        fileList:selectedPDF,
        onRemove: file => {
            const index = selectedPDF.indexOf(file);
            const newFileList = selectedPDF.slice();
            newFileList.splice(index, 1);
            setSelectedPDF(newFileList);
            return {
                fileList: newFileList,
            };
        },
        onChange(info) {
            if(selectedFiles.length === 2) {
                info.fileList = [];
            }
                let length = info.fileList.length;
            if(selectedFiles.length <3) {
                if (length < 3 && (length > 0 && info.fileList[length - 1].type === 'application/pdf')) {
                    if (selectedFiles.length < 2) {
                        setSelectedPDF(info.fileList);
                    }
                } else if (length > 2) {
                    info.fileList = selectedPDF;
                    message.info('You can only upload two files');
                } else if (length === 3) {
                    message.info('You can only upload two files');
                }else if(length === 0){
                    message.info('You can only upload two files');
                }
            }
        },
        onDrop(e) {
        },
    };
    const csvXmlProps = {
        name: 'file',
        multiple: true,
        fileList:selectedCSVXML,
        beforeUpload: file => {
            const isCsvXml = file.type === 'text/csv' || file.type === 'text/xml';
            if (!isCsvXml) {
                message.error(`${file.name} is not a CSV or XML file`);
            }
            return false;
        },
        onRemove: file => {
            const index = selectedCSVXML.indexOf(file);
            const newFileList = selectedCSVXML.slice();
            newFileList.splice(index, 1);
            setSelectedCSVXML(newFileList);
            return {
                fileList: newFileList,
            };
        },
        onChange(info) {
            if(selectedFiles.length === 2) {
                info.fileList = [];
            }
            let length = info.fileList.length;
            if(selectedFiles.length <3) {
                if (length < 3 && (length > 0 && (info.fileList[length - 1].type === 'text/csv' || info.fileList[length - 1].type === 'text/xml'))) {
                    if (selectedFiles.length < 2) {
                        setSelectedCSVXML(info.fileList);
                    }
                } else if (length > 2) {
                    info.fileList = selectedCSVXML;
                    message.info('You can only upload two files');
                } else if (length === 3) {
                    message.info('You can only upload two files');
                }
                else if(length === 0){
                    message.info('You can only upload two files');
                }
            }
        },
        onDrop(e) {
        },
    };
    const handleOk = () => {
        props.setVisible(false);
    };
    const handleCancel = () => {
        setSelectedFiles([]);
        setSelectedPDF([]);
        setSelectedCSVXML([]);
        setUploadClicked(false);
        props.setVisible(false);
    };
    const onFinish = (values) => {
        const token = (localStorage.getItem('token'));
        const decode = jwt_decode(token);
        values.invoiceDate = selectedDate;
        values.supplierId = decode.user.customernum;
        setBody(values);
        setUploadClicked(true);
    };
    const onFinishFailed = (errorInfo) => {
    };
    function onDateChange(date, dateString) {
        setSelectedDate(dateString);
    }
    function onUploadHandler() {
       if(selectedFiles.length > 0){
           body.files = selectedFiles;
           setLoading(true);
           const data = new FormData();
           selectedFiles.map(file=>{
               data.append('file', file.originFileObj);
           });
           data.append('invoiceAmount', body.invoiceAmount);
           data.append('invoiceDate', body.invoiceDate);
           data.append('invoiceNumber', body.invoiceNumber);
           data.append('poNum', body.poNum);
           data.append('supplierId', body.supplierId);
           InvoicesAPI.uploadInvoice(data).then(res => {
               setLoading(false);
               message.success('Invoice Uploaded Successfully');
               setSelectedFiles([]);
               setSelectedPDF([]);
               setSelectedCSVXML([]);
               setUploadClicked(false);
               props.setVisible(false);
           }).catch(err => {
               message.error('Error Uploading Invoice');
               setLoading(false);
           });
       }else{
           message.info('Please select at least one file');
       }

    }
    return (
        <>
    <Modal destroyOnClose={true}
        title="File Upload"
        visible={props.visible}
        okButtonProps={{style:{display:"none"}}}
        cancelButtonProps={{style:{display:"none"}}}
        onOk={handleOk}
        onCancel={handleCancel}
           maskClosable={false}
        width={600}
           height={uploadClicked ? 1000 : 400}
        footer={null}
        // className={'d-flex'}
    >
        {uploadClicked ? (
            <Spin spinning={loading}>
            <div className={'d-flex flex-column align-items-center w-75 mx-auto my-4'}>
                <div className='d-flex justify-content-end align-items-start' style={{alignSelf: 'end',marginBottom: '10px'}}>
                    <ArrowLeftOutlined style={{color: 'red'}} onClick={()=>{setUploadClicked(false)}}/>
                </div>
            <div className="d-flex mb-4">

                <label className="d-flex align-items-center justify-content-center uploadModalBtns text-center">PDF</label>
                <Dragger {...pdfProps} accept={'.pdf'}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined style={{color: 'red'}}/>
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                        Support for a single upload. Only Pdf File Allowed.
                    </p>
                </Dragger>
            </div>
            <div className="d-flex">
                <label className="d-flex align-items-center justify-content-center uploadModalBtns text-center">CSV/XML</label>
                <Dragger {...csvXmlProps} accept={'.csv, .xml'}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined style={{color: 'red'}}/>
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                        Support for a single upload. Only CSV or XML File Allowed.
                    </p>
                </Dragger>
            </div><br/>
           <div className='d-flex justify-content-center'>
               <Button onClick={onUploadHandler} style={{backgroundColor:'#a11a10'}} type="primary" htmlType="submit">
                Upload Files
            </Button>
           </div>
        </div>
            </Spin>
        ) : (<div>
            <h1 className='text-center' style={{fontSize:'13px',fontWeight:'400'}}>AP Invoice</h1>
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                defaultValue={body}
            >
                <Form.Item
                    label="Invoice Number"
                    name="invoiceNumber"
                    rules={[{ required: true, message: 'Please input Invoice Number!' }]}
                >
                    <Input allowClear={true}/>
                </Form.Item>
                <Form.Item
                    label="Invoice Date"
                    name="invoiceDate"
                    rules={[{ required: true, message: 'Please input Invoice date!' }]}
                >
                    <DatePicker onChange={onDateChange} showToday={true} />
                </Form.Item>
                <Form.Item
                    label="PO Number"
                    name="poNum"
                    rules={[{ required: true, message: 'Please input Po Number!' }]}
                >
                    <Input allowClear={true}/>
                </Form.Item>
                <Form.Item
                    label="Invoice Amount"
                    name="invoiceAmount"
                    rules={[{ required: true, message: 'Please input Invoice Amount!' }]}
                >
                    <Input allowClear={true}/>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button style={{backgroundColor:'#a11a10'}} type="primary" htmlType="submit">
                        Upload AP Invoice
                    </Button>
                </Form.Item>
            </Form>
        </div>)}
    </Modal>
        </>
)};