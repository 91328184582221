import React from "react";
import { axios } from "../index";
import qs from "qs";
import jwt_decode from "jwt-decode";
import { M2Axios } from "../axios";

const Auth = {
  forgetPassword: (email) => {
    return new Promise((resolve, reject) => {
      var data = qs.stringify({
        email: email,
      });
      var config = {
        method: "post",
        url: "/cust/sendForgotEmail",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  signIn: (email, password) => {
    return new Promise((resolve, reject) => {
      let data = JSON.stringify({
        username: email,
        password: password,
      });
      let config = {
        method: "post",
        url: "/mlogin",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {

          

          
          // localStorage.setItem(
          //   "i18nextLng",
          //   decodedUser.user.language === "English"
          //     ? "en"
          //     : decodedUser.user.language === "Chinese"
          //     ? "zr"
          //     : ""
          // );
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  switchUser: (body) => {
    return new Promise((resolve, reject) => {
      const data = JSON.stringify({
        customername: body.customername,
        custnum: body.custnum,
        custid: body.custid,
        Customer_Name: body.Customer_Name,
      });
      const config = {
        method: "post",
        url: "/api/mcustdata/switchnew",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          localStorage.setItem("token", response.data.token);
          window.location.replace("/");
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
};
export default Auth;
