import { endpoints } from "../../constants";

export { default as Logo } from "./logo";
export { default as Error } from "./error";
export { default as Success } from "./success";
export { default as MyInput } from "./input";
export { default as MyCustomField } from "./myCustomField";
export { default as MyModal } from "./acceptRejectModal";
export { default as MyExpireErrorModal } from "./expireModal";
export { default as MyUploadFileModal } from "./uploadPicModal";
export { default as MyDropDown } from "./myDropDown";
export { default as ConfirmationModal } from "./confirmationModal";
export { default as ConfirmationDocNameModal } from "./confirmationPdfDataModal";
export { default as SupplierPDCommon } from "./commonOf3";
export { default as Table } from "./table";
export const messageSPDLimitExceeded =
  "Supplier promise date update limit exceeded!";
export const messageSPDDays = `Supplier promise date can\'t be changed within ${endpoints.BEFORE_DAYS} days of set supplier promise date!`;
