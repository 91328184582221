import React, { useEffect, useState } from "react";
import ResponsiveTable from "ant-responsive-table";
import styles from "./style.css";
import moment from "moment";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import {
  getInvoices,
  setCompanyName,
  setInvoices,
  setPoData,
  setSupplierCompany,
} from "../../redux/dashboard";
import { PurchaseOrderAPI } from "../../api";
import { MyUploadFileModal } from "../../components/shared";
import { useTranslation } from "react-i18next";
import TableCmp from "../../components/shared/table";

function Invoices(props) {
  const dispatch = useDispatch();
  const d = localStorage.getItem("invoicesData");
  const [data, setData] = useState(JSON.parse(d));
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [fileModalVisible, setFileModalVisible] = useState(false);
  const { t, i18n } = useTranslation();
  const MobViewColumns = [
    {
      title: t("KSuppPortal_APInvHed_OpenPayable") /*"Open"*/,
      width: "4%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "APInvHed_OpenPayable",
      key: "APInvHed_OpenPayable",
      render: (_, record) => (
        <div>
          {!record?.APInvHed_OpenPayable ? (
            <input type="checkbox" className="editor-active" checked disabled />
          ) : (
            <input type="checkbox" className="editor-active" disabled />
          )}
        </div>
      ),
    },
    {
      title: t("KSuppPortal_APInvHed_InvoiceNum") /*"Invoice#"*/,
      width: "8%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "APInvHed_InvoiceNum",
      key: "APInvHed_InvoiceNum",
      sorter: (a, b) =>
        a.APInvHed_InvoiceNum?.localeCompare(b.APInvHed_InvoiceNum),
      
    },
    {
      title: t("KSuppPortal_APInvHed_InvoiceDate") /*"Invoice date"*/,
      width: "9%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "APInvHed_InvoiceDate",
      key: "APInvHed_InvoiceDate",
      sorter: (a, b) =>
        a.APInvHed_InvoiceDate?.localeCompare(b.APInvHed_InvoiceDate),
      render: (_, record) => (
        <span>
          {record?.APInvHed_InvoiceDate !== null
            ? moment(record?.APInvHed_InvoiceDate).format("YYYY-MM-DD")
            : ""}
        </span>
      ),
    },
    {
      title: t("KSuppPortal_APInvHed_DueDate") /*"Due Date"*/,
      width: "10%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "APInvHed_DueDate",
      key: "APInvHed_DueDate",
      sorter: (a, b) => a.APInvHed_DueDate?.localeCompare(b.APInvHed_DueDate),
      render: (_, record) => (
        <span>
          {record?.APInvHed_DueDate !== null
            ? moment(record?.APInvHed_DueDate).format("YYYY-MM-DD")
            : ""}
        </span>
      ),
    },
    {
      title: t("KSuppPortal_APInvHed_InvoiceHeld") /*"Hold Invoice"*/,
      width: "8%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "APInvHed_InvoiceHeld",
      key: "APInvHed_InvoiceHeld",
      render: (_, record) => (
        <div>
          {!record?.APInvHed_InvoiceHeld == true ? (
            <input type="checkbox" className="editor-active" checked disabled />
          ) : (
            <input type="checkbox" className="editor-active" disabled />
          )}
        </div>
      ),
    },
    {
      title: t("KSuppPortal_APInvHed_Description") /*"Description"*/,
      width: "8%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "APInvHed_Description",
      key: "APInvHed_Description",
      render: (_, record) => (
        <span>
          {record?.APInvHed_Description === ""
            ? ""
            : record?.APInvHed_Description}
        </span>
      ),
    },
    {
      title: t("KSuppPortal_APInvHed_DocInvoiceAmt") /*"Amount"*/,
      width: "8%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "APInvHed_DocInvoiceAmt",
      key: "APInvHed_DocInvoiceAmt",
      className: "text-end",
      render: (record) => (
        <span>
          {
            <NumberFormat
              value={parseFloat(record).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
          }
        </span>
      ),
    },
    {
      title: t("KSuppPortal_APInvHed_CurrencyCode") /*"Curr"*/,
      width: "7%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "APInvHed_CurrencyCode",
      sorter: (a, b) =>
        a.APInvHed_CurrencyCode?.localeCompare(b.APInvHed_CurrencyCode),
      key: "APInvHed_CurrencyCode",
      className: "text-center",
    },
    {
      title: t("KSuppPortal_APInvHed_DocInvoiceBal") /*"Balance"*/,
      width: "8%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "APInvHed_DocInvoiceBal",
      key: "APInvHed_DocInvoiceBal",
      className: "text-end",
      render: (record) => (
        <span>
          {
            <NumberFormat
              value={parseFloat(record).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
          }
        </span>
      ),
    },
    {
      title: t("KSuppPortal_APInvHed_DocTaxAmt") /*"Tax Amount"*/,
      width: "10%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "APInvHed_DocTaxAmt",
      key: "APInvHed_DocTaxAmt",
      className: "text-end",
      render: (record) => (
        <span>
          {
            <NumberFormat
              value={parseFloat(record).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
          }
        </span>
      ),
    },
    {
      title: t("KSuppPortal_CheckHed_CheckDate") /*"Payment Date"*/,
      width: "10%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "action",
      key: "action",
      sorter: (a, b) =>
        a.CheckHed_CheckDate?.localeCompare(b.CheckHed_CheckDate),
      render: (_, record) => (
        <span>
          {record?.CheckHed_CheckDate !== null
            ? moment(record?.CheckHed_CheckDate).format("YYYY-MM-DD")
            : ""}
        </span>
      ),
    },
    {
      title: t("KSuppPortal_CheckHed_CheckNum") /*"Payment Number"*/,
      width: "13%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "CheckHed_CheckNum",
      key: "CheckHed_CheckNum",
      render: (record) => (
        <span>
          {record !== null ? record : ""}
        </span>
      ),
    },
  ];


  const columns = [
    {
      accessorKey: 'APInvHed_OpenPayable',
      header: t("KSuppPortal_APInvHed_OpenPayable" /*"Open"*/),
      size: 30,
      Cell: ({ cell }) => {
        const value = cell.getValue(); 
        const isChecked = value === false;
        return (
          <div>
            <input
              type="checkbox"
              className="editor-active"
              checked={isChecked}
              disabled
            />
          </div>
        );
      },
    },
    {
      accessorKey: 'APInvHed_InvoiceNum',
      header: t("KSuppPortal_APInvHed_InvoiceNum" /*"Invoice#"*/),
      size: 50,
      sorter: (a, b) => {
        const numA = a.APInvHed_InvoiceNum || 0;
        const numB = b.APInvHed_InvoiceNum || 0;
        return numA - numB;
      },
    },
    {
      accessorKey: 'APInvHed_InvoiceDate',
      header: t("KSuppPortal_APInvHed_InvoiceDate" /*"Invoice date"*/),
      size: 70,
      sorter: (a, b) => {
        const dateA = new Date(a.APInvHed_InvoiceDate);
        const dateB = new Date(b.APInvHed_InvoiceDate);
        return dateA - dateB; // Compare dates
      },
      Cell: ({ cell }) => {     
        return <span> {moment(cell.getValue()).format('YYYY-MM-DD')} </span>
      }
    },
    {
      accessorKey: 'APInvHed_DueDate',
      header: t("KSuppPortal_APInvHed_DueDate" /*"Due Date"*/),
      size: 70,
      sorter: (a, b) => {
        const dateA = new Date(a.APInvHed_DueDate);
        const dateB = new Date(b.APInvHed_DueDate);
        return dateA - dateB; // Compare dates
      },
      Cell: ({ cell }) => {
        let value = cell.getValue();
        return <span> { value ?  moment(value).format('YYYY-MM-DD') : ''} </span>
      }
    },
    {
      accessorKey: 'APInvHed_InvoiceHeld',
      header: t("KSuppPortal_APInvHed_InvoiceHeld" /*"Hold Invoice"*/),
      size: 70,
      Cell: ({ cell }) => {
        const value = cell.getValue(); 
        const isChecked = value === false;
        return (
          <div>
            <input
              type="checkbox"
              className="editor-active"
              checked={isChecked}
              disabled
            />
          </div>
        );
      },
    },
    {
      accessorKey: 'APInvHed_Description',
      header: t("KSuppPortal_APInvHed_Description" /*"Description"*/),
      size: 70,
    },
    {
      accessorKey: 'APInvHed_DocInvoiceAmt',
      header: t("KSuppPortal_APInvHed_DocInvoiceAmt" /*"Amount"*/),
      size: 70,
      Cell: ({ cell }) => (
        <span>
          <NumberFormat
            value={parseFloat(cell.getValue()).toFixed(2)}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
        </span>
      ),
    },
    {
      accessorKey: 'APInvHed_CurrencyCode',
      header: t("KSuppPortal_APInvHed_CurrencyCode" /*"Curr"*/),
      size: 50,
    },
    {
      accessorKey: 'APInvHed_DocInvoiceBal',
      header: t("KSuppPortal_APInvHed_DocInvoiceBal" /*"Balance"*/),
      size: 60,
      Cell: ({ cell }) => (
        <span>
          <NumberFormat
            value={parseFloat(cell.getValue()).toFixed(2)}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
        </span>
      ),
    },
    {
      accessorKey: 'APInvHed_DocTaxAmt',
      header: t("KSuppPortal_APInvHed_DocTaxAmt" /*"Tax Amount"*/),
      size: 70,
      Cell: ({ cell }) => (
        <span>
          <NumberFormat
            value={parseFloat(cell.getValue()).toFixed(2)}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
        </span>
      ),
    },
    {
      accessorKey: 'CheckHed_CheckDate',
      header: t("KSuppPortal_CheckHed_CheckDate" /*"Payment Date"*/),
      size: 80,
      sorter: (a, b) => {
        const dateA = new Date(a.CheckHed_CheckDate) || new Date(0); // Handle undefined values
        const dateB = new Date(b.CheckHed_CheckDate) || new Date(0);
        return dateA - dateB; // Compare dates
      },
      Cell: ({ cell }) => {
        const value = cell.getValue();
        let formattedDate = '';
    
        try {
          if (value) {
            const date = new Date(value);
            if (!isNaN(date.getTime())) { // Validate date
              formattedDate = moment(date).format('YYYY-MM-DD');
            }
          }
        } catch (error) {
          console.error('Error formatting date:', error); // Log any errors
        }
    
        return (
          <span>{formattedDate}</span>
        );
      },
    },
    
    {
      accessorKey: 'CheckHed_CheckNum',
      header: t("KSuppPortal_CheckHed_CheckNum" /*"Payment Number"*/),
      size: 100,
    },
  ];

  function searchEventHandler(e) {
    const s = e.target.value;
    if (s) {
      const filterPoData = data.filter((item, i) => {
        return (
          moment(item.APInvHed_DueDate)
            .format("YYYY-MM-DD")
            .toString()
            .includes(s) ||
          moment(item.APInvHed_InvoiceDate)
            .format("YYYY-MM-DD")
            .toString()
            .includes(s) ||
          item.APInvHed_DocTaxAmt.toString().includes(s) ||
          item.APInvHed_DocInvoiceBal.toString().includes(s) ||
          item.APInvHed_CurrencyCode.toString().toLowerCase().includes(s) ||
          item.APInvHed_DocInvoiceAmt.toString().includes(s) ||
          item.APInvHed_InvoiceNum.toString().includes(s)
        );
      });
      setFilterData(filterPoData);
      setFilter(true);
    } else {
      setFilter(false);
    }
  }

  const dataFromRedux = useSelector(getInvoices);
  useEffect(() => {
    if (dataFromRedux.length > 0) {
      setData(dataFromRedux);
    } else {
      setDataLoading(true);
      PurchaseOrderAPI.getCustomerData()
        .then((res) => {
          dispatch(setPoData(res.data.vendordata.podata));
          dispatch(setInvoices(res.data.vendordata.apinvdata));
          dispatch(setCompanyName(res.data.vendordata.profiledata.Name));
          dispatch(setSupplierCompany(res.data.otherdata));
          setData(res.data.vendordata.apinvdata);
          setDataLoading(false);
          // window.location.href = '/';
        })
        .catch((err) => {
          console.log(JSON.stringify(err));
          setDataLoading(false);
        });
    }
  }, []);
  useEffect(() => {}, [dataFromRedux]);

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const updateDimensions = () => {
    setWidth((prevState) => window.innerWidth);
    setHeight((prevState) => window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  return (
    <>

        <div className="btn-tbl-top-group">
          <div className="search-area">
          <label style={{ fontSize: "13px" }} className="text-dark">
            {t("KSuppPortal_Search") /*Search*/}:
          </label>
          <input
            onChange={searchEventHandler}
            name="search"
            className="searchInputField"
          />
          </div>
        </div>

      <div className="ant-table-responsive">
      {width > 768 && height > 344 ? (
          <TableCmp
            columns={columns}
            data={data}
            isLoading={dataLoading}
            isExplore
            setIsExplore
          />
      ) : (
        <ResponsiveTable
          antTableProps={{
            showHeader: true,
            columns: MobViewColumns,
            dataSource: filter ? filterData : data,
            loading: dataLoading,
            rowKey: (record) => record.APInvHed_InvoiceNum,
            pagination: {
              showSizeChanger: false,
            },
            showSorterTooltip: false,
            className: styles,
          }}
          mobileBreakPoint={768}
        />
      )}
      </div>
      <MyUploadFileModal
        visible={fileModalVisible}
        setVisible={setFileModalVisible}
      />
    </>
  );
}

export default Invoices;
