import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authReducer from "./auth";
import dashboardReducer from "./dashboard";
import pdfReducer from "./pdf";
import languageReducer from "./languages";

const store = configureStore({
  reducer: {
    app: authReducer,
    dashboard: dashboardReducer,
    pdf: pdfReducer,
    languages: languageReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
