import "./style.scss";
import {Checkbox, Popconfirm, Table} from 'antd';
import moment from "moment";
import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {PdfApi} from "../../api";
import {message, Spin} from 'antd';
import {useNavigate} from "react-router";
import {useDispatch} from "react-redux";
import {
    setDocHeaders,
    setDocId,
    setDocLabels,
    setDocLabelValues,
    setDocRowData,
    setPdfBase64,
    setPdfName, setTemplateName
} from "../../redux/pdf";
const NewPdfDesign=()=>{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const tableDataSource = useRef([]);
    const [docColumns,setDocColumns] =useState( [
        {
            title: 'Sr No',
            dataIndex: 'srNo',
            key: 'srNo',
            className: 'text-center',
        },
        {
            title: 'Document Name',
            dataIndex: 'docName',
            key: 'docName',
            className: 'text-center',
            render: (_, record) => {
                return (
                    <>{
                        <a className="docIDtxt">{record.docName}</a>
                    }</>
                );
            }
        },
        {
            title: 'Template Name',
            dataIndex: 'tempName',
            key: 'tempName',
            className: 'text-center',
        },
        {
            title: 'Creation Date',
            dataIndex: 'creationDate',
            key: 'creationDate',
            className: 'text-center',
            render: (_, record) => (
                <span>{moment(record.creationDate).format("YYYY-MM-DD")}</span>
            ),
        },
        {
            title: 'Action',
            className: 'text-center',
            dataIndex: 'action',
            width: '225px',
            render: (_, record) => (
                <div className="d-flex justify-content-start">
                    <button className="docActionBtns bg-success" onClick={()=>{getDocDataById(record.docId)}}>View</button>
                    <button className="docActionBtns bg-primary" onClick={()=>{onDocRetryClick(record.docId)}}>Retry</button>
                    <Popconfirm
                        placement="top"
                        title={'Are you sure to delete this document?'}
                        onConfirm={()=>{onDeleteDocConfirm(record.docId)}}
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={{className: 'bg-danger'}}
                        cancelButtonProps={{ color:'black', borderColor:'black'}}
                        className="forPopUp">
                        <button className="docActionBtns bg-danger">Delete</button>
                    </Popconfirm>
                    {record.checkBox === true && <Popconfirm
                        placement="top"
                        title={'Are you sure to post this document?'}
                        onConfirm={()=>{onPostDocConfirm(record.docId)}}
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={{className: 'bg-danger'}}
                        cancelButtonProps={{color:'black',borderColor:'black'}}
                        className="forPopUp">
                        <button className="docActionBtns" style={{backgroundColor:'#11AC9E'}}>Post</button>
                    </Popconfirm>}
                </div>
            ),
        },
    ]);
    const [tblDataSource,setTblDataSource] = useState([]);
    const [busy,setBusy] = useState(false);
    const [tblColumns,setTblColumns] = useState([ {
        title: 'Sr No',
        dataIndex: 'srNo',
        key: 'srNo',
        className: 'text-center',
    },]);
    const [labels, setLabels] = useState([{label: '', value: ''}]);
    const [search, setSearch] = useState(false);
    const [filterTemplates, setFilterTemplates] = useState([]);
    const [selectedDocName, setSelectedDocName] = useState('');
    const [docDataSource,setDocDataSource] = useState([]);
    function getDocDataById(docId) {
        setBusy(true);
        PdfApi.getDocDataById(docId).then(res=>{
            if(res.length>0){
                setSelectedDocName(res[0].ShortChar01);
                setHeaderOfTable(JSON.parse(res[0].Character02));
                setRowData(JSON.parse(res[0].Character04));
                setAllLabels(JSON.parse(res[0].Character01));
                setBusy(false);
            }
            setBusy(false);
        }).catch(err=>{
            message.error('Error while fetching data against this document id.');
            setBusy(false);
        })
    }
    function onDeleteDocConfirm(id) {
        setBusy(true);
        PdfApi.deleteDocById(id).then(deleteRes=>{
            PdfApi.getTemplateData().then(res=>{
                setAllTemplate(res);
                if(res.length>0){
                    setSelectedDocName(res[0].ShortChar01);
                    setHeaderOfTable(JSON.parse(res[0].Character02));
                    setRowData(JSON.parse(res[0].Character04));
                    setAllLabels(JSON.parse(res[0].Character01));
                }else{
                   setAllEmpty();
                }
                setBusy(false);
            }).catch(err=>{
                message.error('Error in fetching data');
                console.log(err);
                setAllEmpty();
                setBusy(false);
            });
        }).catch(err=>{
            message.error('Error while deleting this document.');
            setBusy(false);
        })
    }
    function onDocRetryClick(docId) {
        setBusy(true);
        PdfApi.getDocDataById(docId).then(res=>{
           const docHeaders = JSON.parse(res[0].Character02);
           let docRowData = [];
           const r =JSON.parse(res[0].Character04);
           for(let i=0;i<r.length;i++){
                  docRowData.push(r[i].value);
              }
           const docLabels = JSON.parse(res[0].Character01);
           let labelValues = [];
           let activeButtons = [];
            for (let i = 0; i < docLabels.length; i++) {
                let o = {
                    key:docLabels[i].selectedLabel,
                    value:docLabels[i].value,
                }
                labelValues.push(o);
                activeButtons.push(1);
            }
          
           dispatch(setDocHeaders(docHeaders));
           dispatch(setDocRowData(docRowData));
           dispatch(setDocLabels(docLabels));
           dispatch(setDocLabelValues(labelValues));
           dispatch(setDocId(docId));
           dispatch(setPdfBase64(res[0].Character03));
           dispatch(setPdfName(res[0].ShortChar01));
           dispatch(setTemplateName(res[0].ShortChar04));
           setBusy(false);
            navigate('/pdfmodule');
        }).catch(err=>{
            message.error('Error while retrying.');
            setBusy(false);
        })
    }
    function onPostDocConfirm(docId) {
        setBusy(true);
        PdfApi.postPdfData(docId).then(deleteRes=>{
            PdfApi.getTemplateData().then(res=>{
                setAllTemplate(res);
                if(res.length>0){
                    setSelectedDocName(res[0].ShortChar01);
                    setHeaderOfTable(JSON.parse(res[0].Character02));
                    setRowData(JSON.parse(res[0].Character04));
                    setAllLabels(JSON.parse(res[0].Character01));
                }else{
                    setAllEmpty();
                }
                setBusy(false);
            }).catch(err=>{
                message.error('Error in fetching data');
                console.log(err);
                setAllEmpty();
                setBusy(false);
            });
        }).catch(err=>{
            message.error('Error while deleting this document.');
            setBusy(false);
        })
    }
    useEffect(()=>{
        // setBusy(false);
        setDocDataSource(tableDataSource.current);
    },[tableDataSource.current]);
    function setAllEmpty() {
        setTblDataSource([]);
        setTblColumns([ {
            title: 'Sr No',
            dataIndex: 'srNo',
            key: 'srNo',
            className: 'text-center',
        },]);
        setLabels([{label: '', value: ''}]);
        setSelectedDocName('No Selected Document');
    }
    useLayoutEffect(() => {
        setBusy(true);
        PdfApi.getTemplateData().then(res=>{
           setAllTemplate(res);
          if(res.length>0){
              setSelectedDocName(res[0].ShortChar01);
              setHeaderOfTable(JSON.parse(res[0].Character02));
              setRowData(JSON.parse(res[0].Character04));
              setAllLabels(JSON.parse(res[0].Character01));
          }else{
              setAllEmpty();
          }
            setBusy(false);
        }).catch(err=>{
            message.error('Error in fetching data');
            setAllEmpty();
            setBusy(false);
        });
    },[]);
    const setAllTemplate = (res)=>{
        let allTemplates = [];
        res.map((item,index)=>{
            allTemplates.push({
                key: index,
                srNo: index+1,
                docId: item.Key3,
                docName:item.ShortChar01,
                tempName: item.ShortChar04,
                creationDate: item.Date02,
                checkBox:item.CheckBox01,
            });
        });
        // console.log('ALL TEMPLATES-->',allTemplates);
        setDocDataSource(allTemplates);
        tableDataSource.current = allTemplates;
}
    const setHeaderOfTable = (headers)=>{
            try {
                let headersData = [];
                let headersfromAPI = headers;
                if (headersfromAPI.length > 0) {
                    headersData.push({
                        key: -1,
                        title: 'Sr No',
                        className: 'text-center',
                        dataIndex: 'srNo',
                    });
                    headersfromAPI.map((item, index) => {
                        headersData.push({
                            key: index,
                            title: item.selectedLabel,
                            className: 'text-center',
                            dataIndex: item.selectedLabel,
                        });
                    });
                    setTblColumns(headersData);
                } else {
                    setTblColumns([{
                        title: 'Sr No',
                        dataIndex: 'srNo',
                        key: 'srNo',
                        className: 'text-center',
                    },]);
                }
            } catch (error) {
                console.log('ERROR IN Set Header',error);
            }
}
    const setRowData = (rows)=>{
        try {
            let rowDatafromAPI = rows;
            let rowData = [];
            if (rowDatafromAPI.length > 0) {
                rowDatafromAPI.map((item, index) => {
                    // console.log('inside', item);
                    const v = item.value;
                    v.srNo = index + 1;
                    rowData.push(v);
                });
                setTblDataSource(rowData);
            } else {
                setTblDataSource([{
                    title: 'Sr No',
                    dataIndex: 'srNo',
                    key: 'srNo',
                    className: 'text-center',
                },]);
            }
        } catch (error) {
            // console.log('ERROR IN Set Row',error);
        }
}
    const setAllLabels = (labels)=>{
        try {
            let labelData = [];
            if (labels.length > 0) {
                labels.map((item, index) => {
                    // console.log('inside', JSON.stringify(item));
                    labelData.push({
                        key: index,
                        title: item.selectedLabel,
                        value: item.value,
                    });
                });
                setLabels(labelData);
            } else {
                setLabels([{
                    label: "",
                    value: "",
                }]);
            }
        } catch (error) {
            // console.log('ERROR IN Set Label',error);
        }
    }
    const onSearchChange = (e)=> {
        const searchText = e.target.value;
        if(searchText.length>0){
            setSearch(true);
            const templates = docDataSource.filter(item => {
                return item.tempName.toLowerCase().includes(searchText.toLowerCase()) || item.docName.toLowerCase().includes(searchText.toLowerCase()) || moment(item.creationDate).format("YYYY-MM-DD").includes(searchText.toLowerCase()) || item.srNo.toString().includes(searchText.toLowerCase());
            });
            setFilterTemplates(templates);
        }else{
            setSearch(false);
        }
    }
    return(
        <div className="forSpinner">
            <Spin spinning={busy}>
                <div className="px-2 newPdfDesign">
                    <div className="row w-100">
                        <div className="col-9 contentLeft">
                            <div className="contentLeftSub">
                                <div className="d-flex justify-content-between tableHeadingMain">
                                    <h5 className="tableHeadingTxt d-flex align-items-end">
                                        <span>
                                            Document List
                                               </span>
                                    </h5>
                                    <input onChange={onSearchChange} type="text" placeholder="Search" className="tblSearch" />
                                 </div>
                                <div>
                                    <Table
                                        dataSource={ search ? filterTemplates: busy ? "": docDataSource}
                                        columns={docColumns}
                                        style={{minHeight:'170px'}} />
                                </div>
                             </div>
                    <div className="contentLeftSub mt-2">
                        <div className="d-flex justify-content-between tableHeadingMain">
                            <h5 className="tableHeadingTxt">Table Data of {selectedDocName}</h5>
                        </div>
                        <div>
                            <Table dataSource={tblDataSource} columns={tblColumns}/>
                        </div>
                    </div>
                </div>
                <div className="col-3 sideContentMain">
                    <h1 className="labelHeadingTxt mt-3">Label Data of {selectedDocName}</h1>
                    <div className="labelData">
                        {labels.map((l)=>{
                            return(<div className="row mb-1">
                                <span className="col-5 labelDataHeader">{l.title}</span>
                                <span className="col-7 labelDataTxt">{l.value}</span>
                            </div>)
                        })}
                    </div>
                </div>
            </div>
        </div>
            </Spin>
        </div>
    );
}
export default NewPdfDesign;