import React, { useEffect, useRef, useState } from "react";

import { Checkbox, message, Table } from "antd";
import ResponsiveTable from "ant-responsive-table";
import styles from "./styles.scss";
import moment from "moment";
import { axios, DelConfirm } from "../../api";
import qs from "qs";
import {
  messageSPDDays,
  messageSPDLimitExceeded,
  MyExpireErrorModal,
  MyModal,
  SupplierPDCommon,
} from "../../components/shared";
import { useSearchParams } from "react-router-dom";
import NumberFormat from "react-number-format";
import { canChangeSupplierPromiseDate } from "../../functions";
import { useTranslation } from "react-i18next";

function GuidPage() {
  const { t } = useTranslation();
  const [buyerData, setBuyerData] = useState({});
  const [edit, setEdit] = useState({});
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [poLineData, setPoLineData] = useState([]);
  const myData = useRef([]);
  const currentPoDetailOpenStatus = useRef(false);
  const init = useRef(true);
  const myguid = useRef(false);
  const [modalTitle, setModalTitle] = useState("");
  const [status, setStatus] = useState("");
  const [lastComment, setLastComment] = useState("");
  const [apiData, setApiData] = useState({});
  const poNum = useRef("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [col, setCol] = useState([]);

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const updateDimensions = () => {
    setWidth((prevState) => window.innerWidth);
    setHeight((prevState) => window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  function onDelConfirmChange(e, rel, line, partNum) {
    setLoading(true);
    let search = window.location.search;
    let params = new URLSearchParams(search);
    DelConfirm.onDelConfirm(
      e.target.checked,
      rel,
      line,
      poNum.current,
      true,
      params.get("guid")
    )
      .then((res) => {
        const tempData = myData.current.map((item) => {
          if (
            item.PORel_PORelNum === rel &&
            item.PODetail_POLine === line &&
            item.PODetail_PartNum === partNum
          ) {
            item.PORel_KDelConfirm_c = e.target.checked;
          }
          return item;
        });
        setPoLineData(tempData, () => {
          const tempColumns = poColumns.map((col) => {
            if (col.dataIndex === "PORel_KDelConfirm_c") {
              col.render = (text, record) => {
                let diffDays = "";
                if (
                  record?.Calculated_DueDate == null ||
                  record?.Calculated_DueDate == ""
                ) {
                  const promiseDate = new Date(
                    moment(record?.PORel_DueDate).format("YYYY-MM-D")
                  );
                  const todayDate = new Date(
                    moment(new Date()).format("YYYY-MM-D")
                  );
                  const diffTime = todayDate - promiseDate;
                  diffDays = diffTime / (1000 * 60 * 60 * 24);
                  diffDays = Math.round(diffDays);
                } else {
                  const promiseDate = new Date(
                    moment(record?.Calculated_DueDate).format("YYYY-MM-D")
                  );
                  const todayDate = new Date(
                    moment(new Date()).format("YYYY-MM-D")
                  );
                  const diffTime = todayDate - promiseDate;
                  diffDays = diffTime / (1000 * 60 * 60 * 24);
                  diffDays = Math.round(diffDays);
                }
                if (
                  record.PORel_PORelNum === rel &&
                  record.PODetail_POLine === line &&
                  record.PODetail_PartNum === partNum
                ) {
                  return (
                    <>
                      {!record?.PORel_VoidRelease &&
                      record?.PORel_OpenRelease ? (
                        <Checkbox
                          disabled={!(diffDays <= 0 && diffDays >= -6)}
                          className={
                            diffDays <= 0 && diffDays >= -6
                              ? "antdActiveCheckbox"
                              : "antdDisableCheckBox"
                          }
                          checked={record.PORel_KDelConfirm_c}
                          onChange={(e) =>
                            onDelConfirmChange(
                              e,
                              record.PORel_PORelNum,
                              record.PODetail_POLine,
                              record.PODetail_PartNum
                            )
                          }
                        />
                      ) : (
                        <Checkbox
                          disabled={true}
                          className={"antdDisableCheckBox"}
                          checked={record.PORel_KDelConfirm_c}
                          onChange={(e) =>
                            onDelConfirmChange(
                              e,
                              record.PORel_PORelNum,
                              record.PODetail_POLine,
                              record.PODetail_PartNum
                            )
                          }
                        />
                      )}
                    </>
                  );
                } else {
                  return (
                    <>
                      {!record?.PORel_VoidRelease &&
                      record?.PORel_OpenRelease ? (
                        <Checkbox
                          disabled={!(diffDays <= 0 && diffDays >= -6)}
                          className={
                            diffDays <= 0 && diffDays >= -6
                              ? "antdActiveCheckbox"
                              : "antdDisableCheckBox"
                          }
                          checked={record.PORel_KDelConfirm_c}
                          onChange={(e) =>
                            onDelConfirmChange(
                              e,
                              record.PORel_PORelNum,
                              record.PODetail_POLine,
                              record.PODetail_PartNum
                            )
                          }
                        />
                      ) : (
                        <Checkbox
                          disabled={true}
                          className={"antdDisableCheckBox"}
                          checked={record.PORel_KDelConfirm_c}
                          onChange={(e) =>
                            onDelConfirmChange(
                              e,
                              record.PORel_PORelNum,
                              record.PODetail_POLine,
                              record.PODetail_PartNum
                            )
                          }
                        />
                      )}
                    </>
                  );
                }
              };
            }
            return col;
          });
          setCol(tempColumns);
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.response.data);
      });
  }

  const poColumns = [
    {
      title: t("KSuppPortal_PODetail_PartNum") /*Part Num*/,
      width: "8%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PODetail_PartNum",
      key: "PODetail_PartNum",
    },
    {
      title: t("KSuppPortal_PODetail_LineDesc") /*Description*/,
      width: "8%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PODetail_LineDesc",
      key: "PODetail_LineDesc",
    },
    {
      title: t("KSuppPortal_PODetail_ClassID") /*Class ID*/,
      width: "5%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PODetail_ClassID",
      key: "PODetail_ClassID",
    },
    {
      title: t("KSuppPortal_PORel_DueDate") /*Due Date*/,
      width: "5%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PORel_DueDate",
      key: "PORel_DueDate",
      render: (_, record) => (
        <span>
          {record?.PORel_DueDate
            ? moment(record?.PORel_DueDate).format("YYYY-MM-DD")
            : ""}
        </span>
      ),
    },
    {
      title: t("KSuppPortal_Calculated_DueDate") /*Supplier Promise Date*/,
      width: "10%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "Calculated_DueDate",
      className: "text-center",
      key: "Calculated_DueDate",
      render: (_, record) => (
        <SupplierPDCommon record={record} onChange={onChange} />
      ),
    },
    {
      title: t("KSuppPortal_PORel_Confirmed") /*Rel Confirmed*/,
      width: "7%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PORel_Confirmed",
      className: "text-center",
      key: "PORel_Confirmed",
      render: (_, record, index) => {
        return (
          <>
            {!record?.PORel_VoidRelease && record?.PORel_OpenRelease ? (
              <Checkbox
                className="antdActiveCheckbox"
                checked={record.PORel_Confirmed}
                onChange={(e) => {
                  onRelConfirm(
                    e,
                    index,
                    record.Calculated_DueDate !== null
                      ? record.Calculated_DueDate.split("T")[0]
                      : record.Calculated_DueDate,
                    poNum.current,
                    record.PODetail_POLine,
                    record.PORel_PORelNum
                  );
                }}
                disabled={false}
              />
            ) : (
              <Checkbox
                className="antdDisableCheckBox"
                checked={record?.PORel_Confirmed}
                disabled={true}
              />
            )}
          </>
        );
      },
    },
    {
      title: t("KSuppPortal_PORel_KDelConfirm_c") /*Confirm Delivery*/,
      width: "8%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PORel_KDelConfirm_c",
      className: "text-center",
      key: "PORel_KDelConfirm_c",
      render: (_, record) => {
        // console.log(typeof record?.Calculated_DueDate, record?.Calculated_DueDate);
        let diffDays = "";
        // let suppPromiseDate = true;
        if (
          record?.Calculated_DueDate == null ||
          record?.Calculated_DueDate == ""
        ) {
          // console.log('Due Date');
          // suppPromiseDate = false;
          const promiseDate = new Date(
            moment(record?.PORel_DueDate).format("YYYY-MM-D")
          );
          const todayDate = new Date(moment(new Date()).format("YYYY-MM-D"));
          console.log(todayDate);
          const diffTime = todayDate - promiseDate;
          diffDays = diffTime / (1000 * 60 * 60 * 24);
          diffDays = Math.round(diffDays);
          // console.log(diffDays);
        } else {
          // console.log('Supplier Date');
          // suppPromiseDate = true;
          const promiseDate = new Date(
            moment(record?.Calculated_DueDate).format("YYYY-MM-D")
          );
          const todayDate = new Date(moment(new Date()).format("YYYY-MM-D"));
          const diffTime = todayDate - promiseDate;
          diffDays = diffTime / (1000 * 60 * 60 * 24);
          diffDays = Math.round(diffDays);
          // diffDays = Math.abs(diffDays);
        }
        // console.log(diffDays);
        return (
          <>
            {!record?.PORel_VoidRelease && record?.PORel_OpenRelease ? (
              <Checkbox
                disabled={!(diffDays <= 0 && diffDays >= -6)}
                className={
                  diffDays <= 0 && diffDays >= -6
                    ? "antdActiveCheckbox"
                    : "antdDisableCheckBox"
                }
                checked={record.PORel_KDelConfirm_c}
                onChange={(e) =>
                  onDelConfirmChange(
                    e,
                    record.PORel_PORelNum,
                    record.PODetail_POLine,
                    record.PODetail_PartNum
                  )
                }
              />
            ) : (
              <Checkbox
                disabled={true}
                className={"antdDisableCheckBox"}
                checked={record?.PORel_KDelConfirm_c}
                onChange={(e) =>
                  onDelConfirmChange(
                    e,
                    record.PORel_PORelNum,
                    record.PODetail_POLine,
                    record.PODetail_PartNum
                  )
                }
              />
            )}
          </>
        );
      },
    },
    {
      title: t("KSuppPortal_PORel_OpenRelease") /*Open Release*/,
      width: "5%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PORel_OpenRelease",
      className: "text-center",
      key: "PORel_OpenRelease",
      render: (_, record) => {
        return (
          <>
            {
              <Checkbox
                disabled={true}
                className={"antdDisableCheckBox"}
                checked={record?.PORel_OpenRelease}
              />
            }
          </>
        );
      },
    },
    {
      title: t("KSuppPortal_PODetail_UnitCost") /*Unit Price*/,
      width: "5%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PODetail_DocUnitCost",
      key: "PODetail_DocUnitCost",
      className: "text-end",
      render: (_, record) => (
        <span>
          {
            <NumberFormat
              value={parseFloat(record?.PODetail_DocUnitCost).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
          }
        </span>
      ),
    },
    {
      title: t("KSuppPortal_PODetail_OrderQty") /*Quantity*/,
      width: "4%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PORel_RelQty",
      key: "PORel_RelQty",
      className: "text-center",
      render: (_, record) => (
        <span>{parseFloat(record?.PORel_RelQty).toFixed(2)}</span>
      ),
    },
    {
      title: t("KSuppPortal_PODetail_POLine") /*Line*/,
      width: "5%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PODetail_POLine",
      key: "PODetail_POLine",
    },
    {
      title: t("KSuppPortal_PORel_PORelNum") /*Release*/,
      width: "5%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PORel_PORelNum",
      key: "PORel_PORelNum",
    },
    {
      title: t("KSuppPortal_PORel_JobNum") /*Job Num*/,
      width: "6.5%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PORel_JobNum",
      key: "PORel_JobNum",
    },
    {
      title: t("KSuppPortal_PORel_ProjectID") /*Project ID*/,
      width: "6.5%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PORel_ProjectID",
      key: "PORel_ProjectID",
    },
    {
      title: t("KSuppPortal_PORel_PhaseID") /*Phase ID*/,
      width: "5%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PORel_PhaseID",
      key: "PORel_PhaseID",
    },
  ];
  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let guid = params.get("guid");
    setLoading(true);
    let config = {
      method: "get",
      url: "/useremail/memail?guid%20=" + guid,
    };
    axios(config)
      .then(function (response) {
        if (response.data.message) {
          myguid.current = true;
          setVisible(true);
          setLoading(false);
        } else {
          poNum.current = response.data.UD20_Key2;
          localStorage.setItem("editBy", response.data.UD20_Character04);
          setEdit(response.data.UD20_Character04);
          let config = {
            method: "get",
            url: "/useremail/orders?PONum=" + response.data.UD20_Key2,
          };
          axios(config)
            .then(function (response) {
              //Table data
              setPoLineData(response.data.data.rels);
              init.current = false;
              setCol(poColumns);
              myData.current = response.data.data.rels;
              currentPoDetailOpenStatus.current =
                response.data.data.orderdata.OpenOrder;
              myguid.current = response.data.data.rels[0].PORel_OpenRelease;

              let d = {};
              let api = {};
              //For Api
              api.poNum = poNum.current;
              api.rel = response.data.data.rels[0].PORel_PORelNum;
              api.line = response.data.data.rels[0].PODetail_POLine;
              api.wdate =
                response.data.data.rels[0].PORel_DueDate.split("T")[0];
              api.updateby = localStorage.getItem("editBy");
              api.myguid = searchParams.get("guid");
              setApiData(api);
              //Buyer Details
              d.buyer = response.data.data.orderdata.BuyerIDName;
              d.poNum = response.data.PONum;
              d.plant = response.data.data.orderdata.pbsPlant_c;
              d.orderdate =
                response.data.data.orderdata.OrderDate.split("T")[0];
              d.totalCharge = response.data.data.orderdata.TotalCharges;
              d.totalDiscount = response.data.data.orderdata.TotalDiscount;
              d.totalNet = response.data.data.orderdata.DocTotalOrder;
              d.totalTax = response.data.data.orderdata.TotalTax;
              d.vendorName = response.data.data.orderdata.VendorName;
              d.currencyCode = response.data.data.rels[0].POHeader_CurrencyCode;
              d.address =
                response.data.data.orderdata.VendorAddress1 +
                "\n" +
                response.data.data.orderdata.VendorAddress2 +
                " " +
                response.data.data.orderdata.VendorZIP +
                " " +
                response.data.data.orderdata.VendorCountry;
              if (response.data.data.podtl.length > 0) {
                d.status =
                  response.data.data.podtl[0].Calculated_AcceptReject === 1
                    ? "Accepted"
                    : response.data.data.podtl[0].Calculated_AcceptReject === -1
                    ? "Rejected"
                    : "None";
                setStatus(
                  response.data.data.podtl[0].Calculated_AcceptReject === 1
                    ? "Accepted"
                    : response.data.data.podtl[0].Calculated_AcceptReject === -1
                    ? "Rejected"
                    : "None"
                );
                setLastComment(
                  response.data.data.podtl[0].Calculated_AcceptRejReason
                );
              } else {
                d.status = "None";
                setStatus("None");
              }

              setBuyerData(d);
              setLoading(false);
            })
            .catch(function (error) {
              setLoading(false);
              console.log(JSON.stringify(error));
            });
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.log(JSON.stringify(error));
      });
  }, []);
  useEffect(() => {
    if (init.current === false) {
      setLoading(false);
    }
  }, [poLineData]);
  const onChange = async (
    date,
    dateString,
    poNum,
    line,
    rel,
    copy = 0,
    partNum,
    relConfirm,
    counter
  ) => {
    if (counter !== 3) {
      if (copy === 0) {
        setLoading(true);
      }
      let data1 = qs.stringify({
        PONum: poNum,
        line: line,
        release: rel,
        wdate: dateString,
        updateby: localStorage.getItem("editBy"),
        myguid: searchParams.get("guid"),
        PORel_Confirmed: relConfirm,
      });
      let config = {
        method: "post",
        url: "/useremail/polineduedate",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data1,
      };
      try {
        const response = await axios(config);
        if (copy === 0) {
          const tempData = myData.current.map((item) => {
            if (
              item.PODetail_POLine === line &&
              item.PORel_PORelNum === rel &&
              item.PODetail_PartNum === partNum
            ) {
              item.Calculated_DueDate = dateString + "T00:00:00";
              item.PORel_Confirmed = true;
            }
            return item;
          });
          let config = {
            method: "get",
            url: "/useremail/orders?PONum=" + poNum,
          };
          axios(config)
            .then(function (response) {
              // setPoLineData(tempData);
              setPoLineData(response.data.data.rels);
              setLoading(false);
              message.success(
                t(
                  "KSuppPortal_UpdateMessage"
                ) /*"Supplier promise date has been Updated"*/
              );
            })
            .catch(function (error) {
              setLoading(false);
            });
        }
      } catch (error) {
        setLoading(false);
        const { response } = error;
        if (response.status === 429) {
          message.info(messageSPDLimitExceeded);
        } else if (response.status === 424) {
          message.info(messageSPDDays);
        }
      }
    } else {
      message.info(
        t(
          "KSuppPortal_LimitExceeded"
        ) /*"You can't update it. Supplier promise date update limit has been exceeded."*/
      );
    }
  };

  async function copyClickHandler() {
    if (currentPoDetailOpenStatus.current) {
      let counter = 0,
        changeCounter = 0;
      setLoading(true);
      for (let i = 0; i < poLineData.length; i++) {
        const dueDate = poLineData[i].PORel_DueDate
          ? poLineData[i].PORel_DueDate.split("T")[0]
          : "";
        const supplierDueDate = poLineData[i].Calculated_DueDate
          ? poLineData[i].Calculated_DueDate.split("T")[0]
          : "";
        if (poLineData[i].PORel_PromiseDateLimiter_c !== 3) {
          if (
            dueDate !== supplierDueDate &&
            canChangeSupplierPromiseDate(poLineData[i].Calculated_DueDate)
          ) {
            poLineData[i].Calculated_DueDate = poLineData[i].PORel_DueDate;
            await onChange(
              null,
              dueDate,
              poLineData[i].POHeader_PONum,
              poLineData[i].PODetail_POLine,
              poLineData[i].PORel_PORelNum,
              1,
              poLineData[i].PODetail_PartNum,
              poLineData[i].PORel_Confirmed,
              poLineData[i].PORel_PromiseDateLimiter_c
            );
            changeCounter++;
          }
        } else if (poLineData[i].PORel_PromiseDateLimiter_c === 3) {
          counter++;
        }
      }
      if (changeCounter > 0 && counter > 0) {
        message.info(
          t(
            "KSuppPortal_copyLimit"
          ) /*"Some Supplier Promise date could not copied because of limit exceeded rule."*/
        );
      }
      let newPoLineData = poLineData.map((line) => {
        if (
          line.Calculated_DueDate !== line.PORel_DueDate &&
          line.PORel_PromiseDateLimiter_c !== 3 &&
          canChangeSupplierPromiseDate(line.Calculated_DueDate)
        ) {
          line.Calculated_DueDate = line.PORel_DueDate;
          line.PORel_PromiseDateLimiter_c = line.PORel_PromiseDateLimiter_c + 1;
        }
        return line;
      });
      setPoLineData(newPoLineData);
      const tempColumns = poColumns.map((col) => {
        if (col.dataIndex === "Calculated_DueDate") {
          col.render = (text, record) => (
            <SupplierPDCommon record={record} onChange={onChange} />
          );
        }
        return col;
      });
      setCol(tempColumns);
      setLoading(false);
    } else {
      setVisible(true);
    }
  }

  const acceptHandler = () => {
    if (currentPoDetailOpenStatus.current) {
      setModalTitle("Accept");
      setModalVisible(true);
    } else {
      setVisible(true);
    }
  };
  const rejectHandler = () => {
    if (currentPoDetailOpenStatus.current) {
      setModalTitle("Reject");
      setModalVisible(true);
    } else {
      setVisible(true);
    }
  };

  function onRelConfirm(e, index, dateString, poNum, line, rel) {
    setLoading(true);
    DelConfirm.onRelConfirm(
      dateString,
      rel,
      line,
      poNum,
      e.target.checked,
      localStorage.getItem("editBy"),
      searchParams.get("guid")
    )
      .then((res) => {
        const tempData = myData.current.map((item, itemIndex) => {
          if (index === itemIndex) {
            item.PORel_Confirmed = e.target.checked;
          }
          return item;
        });
        setPoLineData(tempData);
        // setLoading(false);
      })
      .catch((err) => {
        console.log(JSON.stringify(err));
        setLoading(false);
      });
  }

  return (
    <div>
      <div className="mb-3" style={{ boxShadow: "#b7abaf 1px 3px 8px" }}>
        <div className="pt-3 px-1 ps-2 mt-0 row" style={{ margin: "0 1px" }}>
          <div className="col-lg-2 col-md-4 col-sm-5">
            <div className="poDetailsSubDiv row mb-2">
              <h1 className="PoDetailFields col-7 m-0 p-0">
                {t("KSuppPortal_POHeader_BuyerID") /*Buyer*/}:
              </h1>
              <h5 className="col-5 m-0 p-0 text-start">{buyerData.buyer}</h5>
            </div>
            <div className="poDetailsSubDiv row mb-2">
              <h1 className="col-7 m-0 p-0">
                {t("KSuppPortal_PONumber") /*PO Number*/}:
              </h1>
              <h5 className="col-5 m-0 p-0 text-start">{buyerData.poNum}</h5>
            </div>
            <div className="poDetailsSubDiv row mb-2">
              <h1 className="col-7 m-0 p-0">
                {t("KSuppPortal_POHeader_OrderDate") /*Order Date*/}:
              </h1>
              <h5 className="col-5 m-0 p-0 text-start">
                {buyerData.orderdate}
              </h5>
            </div>
          </div>
          <div className="poDetailsMain col-lg-2 col-md-4 col-sm-5 mx-md-5 me-sm-0">
            <div className="poDetailsSubDiv row mb-2">
              <h1 className="col-6 m-0 p-0 text-start">
                {t("KSuppPortal_POHeader_DocTotalCharges") /*Charges*/}:
              </h1>
              <h5 className="col-6 m-0 p-0 text-end">
                {
                  <NumberFormat
                    value={parseFloat(buyerData.totalCharge).toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                }
              </h5>
            </div>
            <div className="poDetailsSubDiv row mb-2">
              <h1 className="col-6 m-0 p-0 text-start">
                {t("KSuppPortal_POHeader_DocTotalTax") /*Tax*/}:
              </h1>
              <h5 className="col-6 m-0 p-0 text-end">
                {
                  <NumberFormat
                    value={parseFloat(buyerData.totalTax).toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                }
              </h5>
            </div>
            <div className="poDetailsSubDiv row mb-2">
              <h1 className="col-6 m-0 p-0 text-start">
                {t("KSuppPortal_POHeader_TotalOrder") /*Order Total*/}:
              </h1>
              <h5 className="col-6 m-0 p-0 text-end">
                {
                  <NumberFormat
                    value={parseFloat(buyerData.totalNet).toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                }
              </h5>
            </div>
            <div className="poDetailsSubDiv row mb-2">
              <h1 className="col-6 m-0 p-0 text-start">
                {t("KSuppPortal_POHeader_CurrencyCode") /*Currency Code*/}:
              </h1>
              <h5 className="col-6 m-0 p-0 text-end">
                {buyerData.currencyCode}
              </h5>
            </div>
          </div>
          <div className="poDetailsMain col-lg-5 col-md-8 col-sm-10 m-0 m-lg-0 my-sm-3">
            <div className="poDetailsSubDiv row mb-2">
              <h1 className="col-2 m-0 p-0">
                {t("KSuppPortal_Name") /*Name*/}:
              </h1>
              <h5 className="col-10 m-0 p-0">{buyerData.vendorName}</h5>
            </div>
            <div className="poDetailsSubDiv row mb-2">
              <h1 className="col-2 m-0 p-0">
                {t("KSuppPortal_Address1") /*Address*/}:
              </h1>
              <h5 className="col-10 m-0 p-0">{buyerData.address}</h5>
            </div>
            <div className="poDetailsSubDiv row mb-2">
              <h1 className="col-2 m-0 p-0">
                {t("KSuppPortal_Status") /*Status*/} :
              </h1>
              <h5 className="col-10 m-0 p-0">
                {status === "Accepted"
                  ? t("KSuppPortal_Accepted")
                  : status === "Rejected"
                  ? t("KSuppPortal_Rejected")
                  : status === "None"
                  ? t("KSuppPortal_None")
                  : status}
              </h5>
            </div>
            <div className="poDetailsSubDiv row mb-2">
              <h1 className="col-2 m-0 p-0">
                {t("KSuppPortal_Calculated_AcceptRejReason") /*Comment*/} :
              </h1>
              <h5 className="col-10 m-0 p-0">{lastComment}</h5>
            </div>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-9 m-0 m-lg-0 my-sm-3 my-3">
            <div className="row d-flex justify-content-between">
              <button
                style={{ fontSize: "13px" }}
                className="col-5 border-0 bg-success text-white px-3 py-2 border-rounded"
                onClick={acceptHandler}
              >
                {t("KSuppPortal_Accept") /*Accept*/}
              </button>
              <button
                title="Reject"
                style={{ fontSize: "13px", backgroundColor: "#9A0E06" }}
                className="col-5 border-0 text-white px-3 py-2"
                onClick={rejectHandler}
              >
                {t("KSuppPortal_Reject") /*Reject*/}
              </button>
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-end"
          style={{ marginTop: "-29px" }}
        >
          <button onClick={copyClickHandler} className="btnPoModal">
            {t("KSuppPortal_Copy") /*Copy Promise Date*/}
          </button>
        </div>
      </div>
      <div className="box-shadow: #b7abaf 1px 3px 8px;">
        {width > 1000 && height > 344 ? (
          <Table
            rowKey={(record) =>
              record.POHeader_PONum + Math.random(poLineData.length)
            }
            className={styles}
            loading={loading}
            dataSource={loading ? "" : poLineData}
            // columns={col}
            pagination={{
              showSizeChanger: true,
            }}
          >
            {poColumns.map((column) => (
              <Table.Column
                key={column.key}
                title={column.title}
                width={column?.width}
                dataIndex={column.dataIndex}
                render={column?.render}
                sorter={column?.sorter}
                className={column?.className}
              />
            ))}
          </Table>
        ) : (
          <ResponsiveTable
            antTableProps={{
              showHeader: true,
              columns: poColumns,
              rowKey: (record) =>
                record.POHeader_PONum + Math.random(poLineData.length),
              className: styles,
              loading: loading,
              dataSource: loading ? [] : poLineData,
              pagination: {
                showSizeChanger: true,
              },
            }}
            mobileBreakPoint={768}
          />
        )}
      </div>
      {myguid.current ? (
        <MyExpireErrorModal
          guid={true}
          msg={
            t(
              "KSuppPortal_LinkMessage"
            ) /*"Your link has been expired please logon first!"*/
          }
          title={t("KSuppPortal_LinkExpired") /*"Link Expired"*/}
          visible={visible}
          setVisible={setVisible}
        />
      ) : (
        <MyExpireErrorModal
          guid={false}
          msg={
            t(
              "KSuppPortal_POClosedMessage"
            ) /*"Selected Po Num is closed so you can't perform this Action!"*/
          }
          title={t("KSuppPortal_POClosed") /*"Po Num closed"*/}
          visible={visible}
          setVisible={setVisible}
        />
      )}
      <MyModal
        po={poNum.current}
        title={
          modalTitle === "Accept"
            ? t("KSuppPortal_Accept")
            : modalTitle === "Reject"
            ? t("KSuppPortal_Reject")
            : modalTitle
        }
        setVisible={setModalVisible}
        visible={modalVisible}
        width={500}
        guid={true}
        setApiData={setApiData}
        apiData={apiData}
        setStatus={setStatus}
        setLastComment={setLastComment}
      />
    </div>
  );
}

export default GuidPage;
