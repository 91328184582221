import React, { useEffect } from "react";
import "./style.css";
import { Logo } from "../../components/shared/index";
import "antd/dist/antd.css";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
const Page404 = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    document.getElementById("pageTitle").innerHTML = "MIT | Not Found";
  }, []);

  return (
    <>
      <div className="main">
        <div className="content">
          <Logo />
          <div className="text-center" style={{ marginTop: "25vh" }}>
            <label className="SignIn py-1">404</label>
            <br />
            <span
              onClick={() => {
                navigate("/");
              }}
              className="text-white loginavLink"
            >
              {
                t(
                  "KSuppPortal_PageNotFound"
                ) /*Page not found! Click here to go Back!*/
              }
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default Page404;
