//import Konva from "konva";
//import { Stage, Layer, Line } from "react-konva";
import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import rough from "roughjs/bundled/rough.esm";
import getStroke from "perfect-freehand";
import PDF from "react-pdf-js";
import {Document, page} from "react-pdf-js";
import {Dropdown, message, Space, Menu, Spin} from 'antd';
import {MyDropDown, ConfirmationModal} from '../../components/shared';
//import axios from "axios";
import { useStateWithCallbackLazy } from 'use-state-with-callback';

import { Button } from "@mui/material";
import {DownOutlined, PlusOutlined} from "@ant-design/icons";
import html2canvas from "html2canvas";
import Tesseract from "tesseract.js";

const generator = rough.generator();


function lineElement(x1, y1, x2, y2) {
    const roughElement = generator.line(x1, y1, x2, y2);
    return { x1, y1, x2, y2, roughElement };
}

function rectangleElement(x1, y1, x2, y2) {
    const roughElement = generator.rectangle(x1, y1, x2 - x1, y2 - y1);
    return { x1, y1, x2, y2, roughElement };
}

function ellipseElement(x1, y1, x2, y2) {
    const roughElement = generator.ellipse(
        x1 + (x2 - x1) / 2,
        y1 + (y2 - y1) / 2,
        x2 - x1,
        y2 - y1
    );
    return { x1, y1, x2, y2, roughElement };
}

function tickElement(x1, y1, x2, y2) {
    const roughElement = [
        generator.line(x1, y1, x1 + (x2 - x1) * 0.2, y2, {
            stroke: "green",
            strokeWidth: 2
        }),
        generator.line(x1 + (x2 - x1) * 0.2, y2, x2, y1 - y1 * 0.3, {
            stroke: "green",
            strokeWidth: 2
        })
    ];
    return { x1, y1, x2, y2, roughElement };
}

function crossElement(x1, y1, x2, y2) {
    const roughElement = [
        generator.line(x1, y1, x2, y2, { stroke: "red", strokeWidth: 2 }),
        generator.line(x1, y2, x2, y1, { stroke: "red", strokeWidth: 2 })
    ];
    return { x1, y1, x2, y2, roughElement };
}

function createElement(id, x1, y1, x2, y2, type, selectedLabel,currentPage) {
    let roughElement;

    switch (type) {
        case "Line":
            roughElement = { ...lineElement(x1, y1, x2, y2), type, id };
            break;

        case "Rectangle":
            // console.log('BEFORE-->',x1, y1, x2, y2);
            roughElement = {
                ...rectangleElement(x1, y1, x2, y2),
                type,
                id,
                selectedLabel,
                currentPage,
            };
            // console.log('AFTER-->',x1, y1, x2, y2);
            break;

        case "Circle":
            roughElement = { ...ellipseElement(x1, y1, x2, y2), type, id };
            break;

        case "Tick":
            roughElement = { ...tickElement(x1, y1, x2, y2), type, id };
            break;

        case "Cross":
            roughElement = { ...crossElement(x1, y1, x2, y2), type, id };
            break;

        case "Pencil":
            return { id, type, points: [{ x: x1, y: y1 }] };

        case "Text":
            return { id, type, x1, y1, x2, y2, text: "" };

        default:
            throw new Error(`Type not recognized ${type}`);
    }

    // roughElement = { ...roughElement, type, id };
    //console.log(lineElement);
    return roughElement;
}

const getSvgPathFromStroke = (stroke) => {
    if (!stroke.length) return "";

    const d = stroke.reduce(
        (acc, [x0, y0], i, arr) => {
            const [x1, y1] = arr[(i + 1) % arr.length];
            acc.push(x0, y0, (x0 + x1) / 2, (y0 + y1) / 2);
            return acc;
        },
        ["M", ...stroke[0], "Q"]
    );

    d.push("Z");
    return d.join(" ");
};

function drawElement(roughCanvas, context, element) {
    switch (element.type) {
        case "Line":
            roughCanvas.draw(element.roughElement);
            break;

        case "Rectangle":
            roughCanvas.draw(element.roughElement);
            break;

        case "Circle":
            roughCanvas.draw(element.roughElement);
            break;

        case "Tick":
            element.roughElement.forEach((elem) => roughCanvas.draw(elem));
            break;

        case "Cross":
            element.roughElement.forEach((elem) => roughCanvas.draw(elem));
            break;

        case "Pencil":
            const stroke = getSvgPathFromStroke(
                getStroke(element.points, { size: 4 })
            );
            context.fill(new Path2D(stroke));
            break;
        case "Text":
            context.textBaseline = "top";
            context.font = "24px sans-serif";
            context.fillText(element.text, element.x1, element.y1);
            break;
        default:
            throw new Error(`Type not recognized ${element.type}`);
    }
}

const adjustElementCoordinates = (element) => {
    const { type, x1, y1, x2, y2 } = element;
    if (type === "rectangle") {
        const minX = Math.min(x1, x2);
        const maxX = Math.max(x1, x2);
        const minY = Math.min(y1, y2);
        const maxY = Math.max(y1, y2);
        return { x1: minX, y1: minY, x2: maxX, y2: maxY };
    } else {
        if (x1 < x2 || (x1 === x2 && y1 < y2)) {
            return { x1, y1, x2, y2 };
        } else {
            return { x1: x2, y1: y2, x2: x1, y2: y1 };
        }
    }
};

const adjustmentRequired = (type) =>
    ["Line", "Rectangle", "Circle", "Tick", "Cross"].includes(type);

function isWithinElement(x, y, element) {
    const { type, x1, y1, x2, y2 } = element;
    if (type === "Rectangle") {
        const minX = Math.min(x1, x2);
        const maxX = Math.max(x1, x2);
        const minY = Math.min(y1, y2);
        const maxY = Math.max(y1, y2);
        return x >= minX && x <= maxX && y >= minY && y <= maxY;
    } else {
        const a = { x: x1, y: y1 };
        const b = { x: x2, y: y2 };
        const c = { x, y };
        const offset = distance(a, b) - (distance(a, c) + distance(b, c));
        return Math.abs(offset) < 1;
    }
}

const distance = (a, b) =>
    Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));

const getElementAtPosition = (x, y, elements) => {
    return elements.find((element) => isWithinElement(x, y, element));
};

// const getBase64 = (file) => {
//   return new Promise((resolve) => {
//     let fileInfo;
//     let baseURL = "";
//     // Make new FileReader
//     let reader = new FileReader();

//     // Convert the file to base64 text
//     reader.readAsDataURL(file);

//     // on reader load somthing...
//     reader.onload = () => {
//       // Make a fileInfo Object
//       console.log("Called", reader);
//       baseURL = reader.result;
//       console.log(baseURL);
//       resolve(baseURL);
//     };
//     console.log(fileInfo);
//   });
// };

const Canvas = ({ allArray, setAllArray, onSaveTheTemplate,setLabelValue,labelValue,response, setCroppedImage,screenCapture, onScreenShotDone, setSelectedFile,onSubmit,activeBtn,setActiveBtn,selectedLabel,setSelectedLabel,onButtonClicked,setChanged,tool, elements, setElements }) => {
    document.getElementById('bodyScroll').style.overflow= 'hidden';
    const [action, setAction] = useState("none");
    const [selectedElement, setSelectedElement] = useState(null);
    const [pdf, setPdf] = useState({
        file: null
        // base64URL: ""
    });
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(null);
    const textAreaRef = useRef();
    const canvasEl = useRef(null);
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
    const [cropPositionTop, setCropPositionTop] = useState(0);
    const [cropPositionLeft, setCropPositionLeft] = useState(0);
    const [cropHeigt, setCropHeight] = useState(0);
    const [cropWidth, setCropWidth] = useState(0);
    const [saveConfirmVisible, setSaveConfirmVisible] = useState(false);
    const [busy, setBusy] = useState(false);
    const [isTemplate, setIsTemplate] = useState(false);
    const [totalPages, setTotalPages] = useState();
    const [currentPage, setCurrentPage] = useStateWithCallbackLazy(1);
    const [scrolledArr,setScrolledArr]=useState([]);
    const scroller=document.getElementById('renderPDF');
    const [scrolled,setScrolled]=useState(0);
    var check=1;
    const timer = ms => new Promise(res => setTimeout(res, ms));

    useLayoutEffect(() => {
        const canvas = document.getElementById("canvas");
        const context = canvas.getContext("2d");

        context.clearRect(0, 0, canvas.width, canvas.height);
        // context.translate(1,1);
        // context.rotate((Math.PI/100)/25);
        // context.transform(1, 0, 0, -1, 0, canvas.height)

        const roughCanvas = rough.canvas(canvas);

        if (isTemplate) {
            const tempCurrent = elements.filter(item => item.currentPage === 1);
            //setElements(tempCurrent);
            tempCurrent.forEach((element) => {
                if (action === "writing" && selectedElement.id === element.id) return;
                drawElement(roughCanvas, context, element);
                setIsTemplate(false);
            });
        } else {
        elements.forEach((element) => {
            if (action === "writing" && selectedElement.id === element.id) return;
            drawElement(roughCanvas, context, element);
        });
    }
    }, [elements, action, selectedElement,scrolledArr, pdf]);
    useLayoutEffect(() => {
        // const pdfCanvas = document.getElementById("canvasForPdf");
        // const pdfCtx = pdfCanvas.getContext("2d");
        // let imageSrc = new Image(pdfCanvas.width, pdfCanvas.height);
        if (pdf.file) {
            // imageSrc.src = image.base64URL;
            // imageSrc.addEventListener("load", (e) => {
            //   imgCtx.drawImage(imageSrc, 0, 0, imgCanvas.width, imgCanvas.height);
            // });
            // const page = 1;
            // const [loading, numPages] = usePdf({
            //   file: pdf,
            //   page,
            //   canvasEl
            // });
        }
        //context.drawImage(imageSrc, 0, 0, canvas.width, canvas.height);
    }, [pdf]);
    useEffect(() => {
        const textArea = textAreaRef.current;
        if (action === "writing") {
            textArea.focus();
            textArea.value = selectedElement.text;
        }
    }, [action, selectedElement]);
    useEffect(()=>{
        window.addEventListener('scroll',(e)=>{
            const canvas = document.getElementById("canvas");
            const context = canvas.getContext("2d");
            context.translate(0,0);
            // setTopPixel(window.pageYOffset);
        })
    },[]);

    useEffect(()=>{
    },[allArray]);


    const updateElement = (id, x1, y1, x2, y2, type, options) => {
        const elementsCopy = [...elements];

        switch (type) {
            case "Line":
            case "Rectangle":
            case "Circle":
            case "Tick":
            case "Cross":
                // console.log('CROSS');
                elementsCopy[id] = createElement(id, x1, y1, x2, y2, type, selectedLabel,currentPage);
                break;
            case "Pencil":
                elementsCopy[id].points = [
                    ...elementsCopy[id].points,
                    { x: x2, y: y2 }
                ];
                break;
            case "Text":
                const textWidth = document
                    .getElementById("canvas")
                    .getContext("2d")
                    .measureText(options.text).width;
                const textHeight = 24;
                elementsCopy[id] = {
                    ...createElement(id, x1, y1, x1 + textWidth, y1 + textHeight, type),
                    text: options.text
                };
                break;
            default:
                throw new Error(`Type not recognised: ${type}`);
        }
        // elementsCopy.label = selectedLabel;
        setElements(elementsCopy, true);

    };
    const handleMouseDown = (nativeEvent) => {
        // console.log([...elements]);
        const tempE = [...elements];
        // console.log('TEMPE------->',tempE);
        const find = tempE.find((el)=> {
            // console.log(el);
            return el.selectedLabel == selectedLabel
        });
        // console.log('FIND-->',find);
       if(selectedLabel !== "" && (!find) && file ) {
           if (action === "writing") return;
           const {clientX, clientY} = nativeEvent;
           setStartX(clientX);
              setStartY(clientY);
           if (tool === "Select") {
               // move element
               const element = getElementAtPosition(clientX, clientY, elements);
               if (element) {
                   const offsetX = clientX - element.x1;
                   const offsetY = clientY - element.y1;
                   // elementsCopy.label = selectedLabel;
                   setElements((prevState) => prevState);
                   setSelectedElement({...element, offsetX, offsetY});
                   setAction("moving");
               }
           }
           else {
               const id = elements.length;
               // console.log('Before Create Element',clientX,clientY);
               const element = createElement(
                   id,
                   clientX,
                   clientY+(scroller.scrollTop/*window.devicePixelRatio*/),
                   clientX,
                   clientY+(scroller.scrollTop/*window.devicePixelRatio*/),
                   tool,
                   selectedLabel,
                   currentPage,
               );
               // element.label = selectedLabel;
               setElements((prev) => [...prev, element]);
               setSelectedElement(element);
               if (tool === "Text") {
                   setAction("writing");
               } else {
                   setAction("drawing");
               }
           }
       }else if(selectedLabel === ""){
           message.info('Please select atleast one label first!');
       }else if(find){
           message.info('You already Drawn '+selectedLabel);
       }else if(!(file)){
           message.info('Please upload PDF first!');
       }
    };
    const handleMouseMove = (nativeEvent) => {
        // console.log('MOVE');
           const {clientX, clientY} = nativeEvent;
           if (tool === "Select") {
               nativeEvent.target.style.cursor = getElementAtPosition(
                   clientX,
                   clientY,
                   elements
               )
                   ? "grab"
                   : "default";
           }

           if (action === "drawing") {
               // if (tool === "Pencil") {
               //   contextRef.current.lineTo(clientX, clientY);
               //   contextRef.current.stroke();
               //   return;
               // }
               // console.log("DRAWING");
               const index = elements.length - 1;
               const {x1, y1} = elements[index];
               updateElement(index, x1, y1, clientX, clientY+(scroller.scrollTop/*window.devicePixelRatio*/), tool);

               // console.log('X AND Y---->',startX,startY);
               let cropPositionTop = startY ;
               let cropPositionLeft = startX ;
               const endX = clientX ;
               const endY = clientY;
               const isStartTop = endY >= startY;
               const isStartBottom = endY <= startY;
               const isStartLeft = endX >= startX;
               const isStartRight = endX <= startX;
               const isStartTopLeft = isStartTop && isStartLeft;
               const isStartTopRight = isStartTop && isStartRight;
               const isStartBottomLeft = isStartBottom && isStartLeft;
               const isStartBottomRight = isStartBottom && isStartRight;
               // let newBorderWidth = borderWidth;
               let cropWidth = 0;
               let cropHeigth = 0;
                   if (isStartTopLeft) {
                       // console.log('TOP LEFT');
                       // newBorderWidth = `${startY}px ${windowWidth - endX}px ${
                       //     windowHeight - endY
                       // }px ${startX}px`;
                       cropWidth = endX - startX;
                       cropHeigth = endY - startY;
                   }
                   if (isStartTopRight) {
                       // console.log('TOP RIGHT');
                       // newBorderWidth = `${startY}px ${windowWidth - startX}px ${
                       //     windowHeight - endY
                       // }px ${endX}px`;
                       cropWidth = startX - endX;
                       cropHeigth = endY - startY;
                       cropPositionLeft = endX+10;
                   }
                   if (isStartBottomLeft) {
                       // console.log('Bottom LEFT');
                       // newBorderWidth = `${endY}px ${windowWidth - endX}px ${
                       //     windowHeight - startY
                       // }px ${startX}px`;
                       cropWidth = endX - startX;
                       cropHeigth = startY - endY;
                       cropPositionTop = endY;
                   }
                   if (isStartBottomRight) {
                       // console.log('Bottom RIGHT');
                       // newBorderWidth = `${endY}px ${windowWidth - startX}px ${
                       //     windowHeight - startY
                       // }px ${endX}px`;
                       cropWidth = startX - endX;
                       cropHeigth = startY - endY;
                       cropPositionLeft = endX;
                       cropPositionTop = endY;
                   }
               setCropWidth(cropWidth);
               setCropHeight(cropHeigth);
               setCropPositionLeft(cropPositionLeft);
               setCropPositionTop(cropPositionTop);



           } else if (action === "moving") {
               // console.log('MOVING');
               const {id, x1, x2, y1, y2, type, offsetX, offsetY} = selectedElement;
               const width = x2 - x1;
               const height = y2 - y1;
               const newX1 = clientX - offsetX;
               const newY1 = clientY - offsetY;
               const options = type === "Text" ? {text: selectedElement.text} : {};
               updateElement(
                   id,
                   newX1,
                   newY1,
                   newX1 + width,
                   newY1 + height,
                   type,
                   options
               );
           }

    };
    const handleMouseUp = async (nativeEvent) => {
        const {clientX, clientY} = nativeEvent;
        if (selectedElement) {
            if (
                selectedElement.type === "Text" &&
                clientX - selectedElement.offsetX === selectedElement.x1 &&
                clientY - selectedElement.offsetY === selectedElement.y1
            ) {
                setAction("writing");
                return;
            }

            const index = selectedElement.id;
            const {id, type} = elements[index];
            if (action === "drawing" && adjustmentRequired(type)) {
                const {x1, y1, x2, y2} = adjustElementCoordinates(elements[index]);
                // console.log(id, x1, y1, x2, y2, type);
                // "x1": 92.904,
                //     "y1": 732.32821,
                //     "x2": 144.604108,
                //     "y2": 748.3272099999999,
                // updateElement(id, 92.904, 732.32821, 144.604108, 748.3272099999999, type);
                const canvas = document.getElementById("canvas");
                const context = canvas.getContext("2d");
                const tempB = [...activeBtn];
                const temp = tempB.map((btn, index) => {
                    if (index === 0 && selectedLabel === "VendorNum") {
                        btn = 1;
                    } else if (index === 1 && selectedLabel === "InvoiceNum") {
                        btn = 1;
                    } else if (index === 2 && selectedLabel === "InvoiceDate") {
                        btn = 1;
                    } else if (index === 3 && selectedLabel === "DueDate") {
                        btn = 1;
                    } else if (index === 4 && selectedLabel === "FiscalYear") {
                        btn = 1;
                    } else if (index === 5 && selectedLabel === "FiscalPeriod") {
                        btn = 1;
                    } else if (index === 6 && selectedLabel === "CurrencyCode") {
                        btn = 1;
                    }
                    return btn;
                });
                setActiveBtn(temp);
                // console.log('y1-->',(833-y2));
                // console.log('y2-->',(833-y1));
                const zoomState = window.devicePixelRatio;
                updateElement(id, 0, 0, 0, 0, type);
                setTimeout(async () => {
                    await onScreenShotDone(cropPositionLeft * zoomState, cropPositionTop * zoomState, cropWidth * zoomState, cropHeigt * zoomState,selectedLabel);
                }, 1);
                setTimeout(()=>{
                    updateElement(id, x1, y1, x2, y2, type);
                }, 2);
                // await onScreenShotDone(cropPositionLeft, cropPositionTop, cropWidth, cropHeigt, selectedLabel);
                // updateElement(id, x1, y1, x2, y2, type);
                // setChanged(true);
            }
        }
        if (action === "writing") return;

        setSelectedElement(null);
        setAction("none");
    };
    const handleBlur = (event) => {
        const { id, x1, y1, type } = selectedElement;
        setAction("none");
        setSelectedElement(null);
        updateElement(id, x1, y1, null, null, type, { text: event.target.value });
    };
    const handleClear = () => {
        setElements([]);
        setCroppedImage([]);
        setLabelValue({
            VendorNum: "",
            InvoiceNum: "",
            InvoiceDate: "",
            DueDate: "",
            FiscalYear: "",
            FiscalPeriod: "",
            CurrencyCode: "",
        })
        const temp = [...activeBtn];
        const t = temp.map((btn)=>{
            return btn = 0;
        });
        setActiveBtn(t);
    };
    // const handleInput = (event) => {
    //   console.log(event.target.files[0]);
    //   // const file = event.target.files[0];
    //   // getBase64(file)
    //   //   .then((result) => {
    //   //     file["base64"] = result;
    //   //     console.log("File Is", file.base64);
    //   //     setImage({
    //   //       base64URL: result,
    //   //       file
    //   //     });
    //   //   })
    //   //   .catch((err) => {
    //   //     console.log(err);
    //   //   });
    //   setPdf({
    //     file: event.target.files[0]
    //   });
    // };
    const fileToBase64 = (file, cb) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(null, reader.result);
        };
        reader.onerror = function (error) {
            cb(error, null);
        };
    };
    const handleInput = ({ target }) => {
        // console.log(target.files[0]);
        setSelectedFile(target.files[0]);
        if (target.files < 1 || !target.validity.valid) {
            return;
        }
        fileToBase64(target.files[0], (err, result) => {
            if (result) {
                setFile(result);
                setFileName(target.files[0]);
            }
        });
    };
    function onDocumentComplete(e) {
        // console.log("onDocumentComplete", e);
        // console.log(JSON.stringify(e));
        setTotalPages(e);
        setCurrentPage(1);
    }

    function saveTempelateHandler() {
        setSaveConfirmVisible(true);

    }
    function dataURLtoFile(dataurl, filename) {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {type:mime});
    }
    function getPicture(cropPositionLeft, cropPositionTop, cropWidth, cropHeigt) {
        return new Promise((resolve, reject) => {
            const body = document.querySelector('body');
            html2canvas(body).then(async (canvas) => {
                let croppedCanvas = document.createElement('canvas');
                let croppedCanvasContext = croppedCanvas.getContext('2d');
                // croppedCanvas.width = cropWidth;
                // croppedCanvas.height = cropHeigth;
                const zoomState = window.devicePixelRatio;
                // croppedCanvasContext.drawImage(
                //     canvas,
                //     cropPositionLeft,
                //     cropPositionTop,
                //     cropWidth / zoomState,
                //     cropHeigt / zoomState,
                //     0,
                //     0,
                //     cropWidth / zoomState,
                //     cropHeigt / zoomState
                // );
                croppedCanvasContext.drawImage(
                    canvas,
                    cropPositionLeft * zoomState,
                    cropPositionTop * zoomState,
                    cropWidth * zoomState,
                    cropHeigt * zoomState,
                    0,
                    0,
                    cropWidth * zoomState,
                    cropHeigt * zoomState
                );
                // croppedCanvas.toDataURL();
                // const url = croppedCanvas.toDataURL()
                const file = dataURLtoFile(croppedCanvas.toDataURL(), 'capture.png');
                // console.log(file);
                let textData = await Tesseract.recognize(
                    file,
                    'eng',
                    {logger: m => console.log('logger-->', m)}
                );
                const {text} = textData.data
                // console.log(text);
                resolve(text);
                return text;
        }).catch(err => {
            console.log(err);
            reject(err);
        });
    });
    }
    async function onTemplateChange(data) {
        scroller.scrollTop = 0;
        setBusy(true);
        setLabelValue({
            VendorNum: "",
            InvoiceNum: "",
            InvoiceDate: "",
            DueDate: "",
            FiscalYear: "",
            FiscalPeriod: "",
            CurrencyCode: "",
        });
        setActiveBtn(data.data.templateData[0].keys);
        // console.log('DATA FROM API-->',data);

        let roughElement;
        let array = [];
        let cropPositionTop;
        let cropPositionLeft
        let p1 = [];
        setAllArray(data.data.templateData);
        for (let i = 0; i < data.data.templateData.length; i++) {
            let apiData = data.data.templateData[i];
            cropPositionTop = apiData.y1;
            cropPositionLeft = apiData.x1;
            let endX = apiData.x2;
            let endY = apiData.y2;
            let isStartTop = endY >= apiData.y1;
            let isStartBottom = endY <= apiData.y1;
            let isStartLeft = endX >= apiData.x1;
            let isStartRight = endX <= apiData.x1;
            let isStartTopLeft = isStartTop && isStartLeft;
            let isStartTopRight = isStartTop && isStartRight;
            let isStartBottomLeft = isStartBottom && isStartLeft;
            let isStartBottomRight = isStartBottom && isStartRight;
            let cropWidth = 0;
            let cropHeigth = 0;
            if (isStartTopLeft) {
                // console.log('TOP LEFT');
                cropWidth = endX - apiData.x1;
                cropHeigth = endY - apiData.y1;
            }
            if (isStartTopRight) {
                // console.log('TOP RIGHT');
                cropWidth = apiData.x1 - endX;
                cropHeigth = endY - apiData.y1;
                cropPositionLeft = endX + 10;
            }
            if (isStartBottomLeft) {
                // console.log('Bottom LEFT');
                cropWidth = endX - apiData.x1;
                cropHeigth = apiData.y1 - endY;
                cropPositionTop = endY;
            }
            if (isStartBottomRight) {
                // console.log('Bottom RIGHT');
                cropWidth = apiData.x1 - endX;
                cropHeigth = apiData.y1 - endY;
                cropPositionLeft = endX;
                cropPositionTop = endY;
            }
            const zoomState = window.devicePixelRatio;
            //For testing to view the screenshot on page
            // if(i===0){
            //     onScreenShotDone(apiData.y1*zoomState,apiData.x1*zoomState,(apiData.x2-apiData.x1)*zoomState,(apiData.y2-apiData.y1)*zoomState);
            //     // onScreenShotDone(apiData.x1*zoomState,apiData.y1,apiData.x2/(zoomState+0.5),(apiData.y2/(zoomState+0.5)));
            // }

            // if(currentPage !== apiData.currentPage){
            //     setCurrentPage(apiData.currentPage,(currentPage)=>{
            //         console.log('currentPage',currentPage);
            //         p1.push(getPicture(cropPositionLeft,cropPositionTop,cropWidth,cropHeigth));
            //         roughElement = {
            //             ...rectangleElement(apiData.x1, apiData.y1, apiData.x2, apiData.y2),
            //             type: 'Rectangle',
            //             id: i,
            //             selectedLabel: apiData.label,
            //             currentPage:apiData.currentPage,
            //         };
            //         array.push(roughElement)
            //     });
            // }
            // if(currentPage === apiData.currentPage){
            //     p1.push(getPicture(cropPositionLeft,cropPositionTop,cropWidth,cropHeigth));
            //     roughElement = {
            //         ...rectangleElement(apiData.x1, apiData.y1, apiData.x2, apiData.y2),
            //         type: 'Rectangle',
            //         id: i,
            //         selectedLabel: apiData.label,
            //         currentPage:apiData.currentPage,
            //     };
            //     array.push(roughElement)
            // }
            if (totalPages === 1) {
                p1.push(getPicture(cropPositionLeft, cropPositionTop, cropWidth, cropHeigth));
                roughElement = {
                    ...rectangleElement(apiData.x1, apiData.y1, apiData.x2, apiData.y2),
                    type: 'Rectangle',
                    id: i,
                    selectedLabel: apiData.label,
                    currentPage: apiData.currentPage,
                };
                array.push(roughElement)
            } else {
                if (currentPage !== apiData.currentPage) {
                    check=apiData.currentPage;
                    setCurrentPage(apiData.currentPage, (currentPage) => {
                    });
                }

                if (check === apiData.currentPage) {
                    await timer(250);
                    let data = await getPicture(cropPositionLeft, cropPositionTop, cropWidth, cropHeigth);
                    // await timer(1000);
                    p1.push({text: data, currentPage: apiData.currentPage});
                    roughElement = {
                        ...rectangleElement(apiData.x1, apiData.y1, apiData.x2, apiData.y2),
                        type: 'Rectangle',
                        id: i,
                        selectedLabel: apiData.label,
                        currentPage: apiData.currentPage,
                    };
                    array.push(roughElement)
                }
            }

        }
        setIsTemplate(true);
        setElements(array);
        setAllArray(array);
        let arrayOfText = [];
        await Promise.all(p1).then((r1) => {
            for (let i = 0; i < r1.length; i++) {
                // console.log('value is here---->', r1[i]);
                arrayOfText.push(r1[i]);
            }
        });

        if(totalPages===1){
            for (let i = 0; i < data.data.templateData.length; i++){
                let labels = {...labelValue};
                let apiData = data.data.templateData[i];
                if(apiData.label === "VendorNum"){
                    setLabelValue(prev=>(
                        {
                            ...prev,
                            VendorNum: arrayOfText[i]
                        }));
                }
                else if(apiData.label === "InvoiceNum"){
                    setLabelValue(prev=>({
                        ...prev,
                        InvoiceNum: arrayOfText[i]
                    }));
                }
                else if( apiData.label === "InvoiceDate"){
                    let l = { ...labelValue };
                    setLabelValue(prev=>({
                        ...prev,
                        InvoiceDate: arrayOfText[i]
                    }));
                }
                else if( apiData.label === "DueDate"){
                    setLabelValue(prev=>({
                        ...prev,
                        DueDate: arrayOfText[i]
                    }));
                }
                else if(apiData.label === "FiscalYear"){
                    setLabelValue(prev=>({
                        ...prev,
                        FiscalYear: arrayOfText[i]
                    }));
                }
                else if(apiData.label === "FiscalPeriod"){
                    setLabelValue(prev=>({
                        ...prev,
                        FiscalPeriod: arrayOfText[i]
                    }));
                }
                else if( apiData.label === "CurrencyCode"){
                    setLabelValue(prev=>({
                        ...prev,
                        CurrencyCode: arrayOfText[i]
                    }));
                }
            }
        }
        else{
            for (let i = 0; i < arrayOfText.length; i++){
                let labels = {...labelValue};
                let apiData = data.data.templateData[i];
                if(apiData.label === "VendorNum"){
                    setLabelValue(prev=>(
                        {
                            ...prev,
                            VendorNum: arrayOfText[i].text
                        }));
                }
                else if(apiData.label === "InvoiceNum"){
                    setLabelValue(prev=>({
                        ...prev,
                        InvoiceNum: arrayOfText[i].text
                    }));
                }
                else if( apiData.label === "InvoiceDate"){
                    let l = { ...labelValue };
                    setLabelValue(prev=>({
                        ...prev,
                        InvoiceDate: arrayOfText[i].text
                    }));
                }
                else if( apiData.label === "DueDate"){
                    setLabelValue(prev=>({
                        ...prev,
                        DueDate: arrayOfText[i].text
                    }));
                }
                else if(apiData.label === "FiscalYear"){
                    setLabelValue(prev=>({
                        ...prev,
                        FiscalYear: arrayOfText[i].text
                    }));
                }
                else if(apiData.label === "FiscalPeriod"){
                    setLabelValue(prev=>({
                        ...prev,
                        FiscalPeriod: arrayOfText[i].text
                    }));
                }
                else if( apiData.label === "CurrencyCode"){
                    setLabelValue(prev=>({
                        ...prev,
                        CurrencyCode: arrayOfText[i].text
                    }));
                }
            }
        }


        setCurrentPage(1);
        setBusy(false);
    }

    function onNextPage() {
        if (isTemplate) {
            const tempAll = [...allArray];
            // setNewPage(newPage+1);
            setCurrentPage(prev => prev + 1, () => {
                const tempCurrent = tempAll.filter(item => item.currentPage === currentPage + 1);
                setElements(tempCurrent);
            });
            if (elements.length > 0) {
                setAllArray([...elements]);
            }
        } else {
            const tempAll = [...allArray];
            // setNewPage(newPage+1);
            setCurrentPage(prev => prev + 1, () => {
                const tempCurrent = tempAll.filter(item => item.currentPage === currentPage + 1);
                setElements(tempCurrent);
            });
            if (elements.length > 0) {
                setAllArray([...elements]);
            }
        }
    }
        // const arrayNew = [...nextArray];
        // currentArray=[...elements]
        // setElements(arrayNew);
        // setCurrentPage(prev=>prev+1,()=>{
        //     // setElements([]);
        //     setCroppedImage([]);
        //     setLabelValue({
        //         VendorNum: "",
        //         InvoiceNum: "",
        //         InvoiceDate: "",
        //         DueDate: "",
        //         FiscalYear: "",
        //         FiscalPeriod: "",
        //         CurrencyCode: "",
        //     })
        //     const temp = [...activeBtn];
        //     const t = temp.map((btn)=>{
        //         return btn = 0;
        //     });
        //     setActiveBtn(t);
        // });


    function onPrevPage() {
        // console.log('prev page');
        // setNewPage(newPage-1);
        if(isTemplate){
            const tempAll = [...allArray];

            setCurrentPage(prev=>prev-1,()=>{
                const tempCurrent = tempAll.filter(item => item.currentPage === currentPage-1);
                setElements(tempCurrent);
            });
            if(elements.length > 0){
                setAllArray([...elements]);
            }
        }else {
            const tempAll = [...allArray];

            setCurrentPage(prev => prev - 1, () => {
                const tempCurrent = tempAll.filter(item => item.currentPage === currentPage - 1);
                setElements(tempCurrent);
            });
            if (elements.length > 0) {
                setAllArray([...elements]);
            }
        }
    }


    function onScrollHandler() {

            // setScrolledPixels(scroller.scrollTop);
            var c = document.getElementById("canvas");
            var ctx = c.getContext("2d");
            // console.log('translating 1');
            const size=scrolledArr.length;
            const secondLast=scrolledArr[size-1];
            const last=scroller.scrollTop;
            if(size==0){
                ctx.translate(0,(-scroller.scrollTop/*pageZoom*/));
            }
            if(size>0){
                const value=secondLast-last;
                ctx.translate(0, value/*pageZoom*/);
            }
            setScrolledArr(()=>[...scrolledArr,scroller.scrollTop]);
            setScrolled(last);
            
        }

    return (
        <>

        <div
           
        >
            <Spin spinning={busy} style={{zIndex:100}} >
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    left: 612,
                    margin: "4px",
                    zIndex: 100
                }}>
                <div style={{height:'40px'}} className="d-flex">
                   <MyDropDown onChange={onTemplateChange}/>



                    <input
                            type="file"
                            accept="application/pdf"
                            style={{ display: "none" }}
                            id="contained-button-file"
                            onChange={handleInput}
                        />
                        <label htmlFor="contained-button-file">
                            <Button
                                className="topFileBtns"
                                style={{ margin: "4px", padding: "6px 4px 6px 4px",border:'2px solid #a11a10',backgroundColor:'white',marginTop:'0px',fontSize:'13px'}}
                                component="span"
                                // color="primary"
                            >
                                <span>Select File</span>
                                <span><PlusOutlined className="ms-2 align-text-bottom" /></span>
                            </Button>
                        </label>
                    {/*<button className="topFileBtns">*/}
                    {/*    Process*/}
                    {/*</button>*/}
                    <button className="topFileBtns" onClick={saveTempelateHandler}>
                        Save Template
                    </button>
                    <button
                        onClick={handleClear}
                        style={{margin:'0px 5px',padding:'5px 10px',backgroundColor:'#a11a10',color:'white',border:'2px solid #a11a10',borderRadius: "4px",fontSize:'13px'}}>
                        Clear
                    </button>
                    <button disabled={currentPage === 1} className="topFileBtns" onClick={onPrevPage}>Prev Page</button>
                    <h5 className="topFileBtns">{currentPage}</h5>
                    <button disabled={currentPage === totalPages} className="topFileBtns" onClick={onNextPage}>Next Page</button>

                </div>
                <div className="d-flex justify-content-between flex-row align-items-top p-2 forBtns" style={{marginTop:'60px',width:'368px'}}>
                    <div>
                        <div className="d-flex justify-content-between align-items-center">
                            <button  onClick={()=>onButtonClicked(0)} className={activeBtn[0] === 1 ? "sidebarBtnActive my-1":"sidebarBtn my-1"} style={{width:'110px'}}>
                                <span className="mx-auto">VendorNum</span>
                            </button>
                            <div className="mt-1 "><input type='text' disabled={activeBtn[0] !== 1} defaultValue={labelValue.VendorNum} className="valueTxt valueTxtInput"/></div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <button  onClick={()=>onButtonClicked(1)} className={activeBtn[1] === 1 ? "sidebarBtnActive my-1":"sidebarBtn my-1"} style={{width:'110px'}}>
                                <span className="mx-auto">InvoiceNum</span>
                            </button>
                            <div className="mt-1 "><input type='text' disabled={activeBtn[1] !== 1}  defaultValue={labelValue.InvoiceNum} className="valueTxt valueTxtInput"/></div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <button onClick={()=>onButtonClicked(2)} className={activeBtn[2] === 1 ? "sidebarBtnActive my-1":"sidebarBtn my-1"} style={{width:'110px'}}>
                                <span className="mx-auto">InvoiceDate</span>
                            </button>
                            <div className="mt-1 "><input type='text' disabled={activeBtn[2] !== 1}  defaultValue={labelValue.InvoiceDate} className="valueTxt valueTxtInput"/></div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <button onClick={()=>onButtonClicked(3)} className={activeBtn[3] === 1 ? "sidebarBtnActive my-1":"sidebarBtn my-1"} style={{width:'110px'}}>
                                <span className="mx-auto">DueDate</span>
                            </button>
                            <div className="mt-1 "><input type='text' disabled={activeBtn[3] !== 1}  defaultValue={labelValue.DueDate} className="valueTxt valueTxtInput"/></div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <button onClick={()=>onButtonClicked(4)} className={activeBtn[4] === 1 ? "sidebarBtnActive my-1":"sidebarBtn my-1"} style={{width:'110px'}}>
                                <span className="mx-auto">FiscalYear</span>
                            </button>
                            <div className="mt-1 "><input type='text' disabled={activeBtn[4] !== 1}  defaultValue={labelValue.FiscalYear} className="valueTxt valueTxtInput"/></div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <button onClick={()=>onButtonClicked(5)} className={activeBtn[5] === 1 ? "sidebarBtnActive my-1":"sidebarBtn my-1"} style={{width:'110px'}}>
                                <span className="mx-auto">FiscalPeriod</span>
                            </button>
                            <div className="mt-1 "><input type='text' disabled={activeBtn[5] !== 1}  defaultValue={labelValue.FiscalPeriod} className="valueTxt valueTxtInput"/></div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <button onClick={()=>onButtonClicked(6)} className={activeBtn[6] === 1 ? "sidebarBtnActive my-1":"sidebarBtn my-1"} style={{width:'110px'}}>
                                <span className="mx-auto">CurrencyCode</span>
                            </button>
                            <div className="mt-1 "><input type='text' disabled={activeBtn[6] !== 1}  defaultValue={labelValue.CurrencyCode} className="valueTxt valueTxtInput"/></div>
                        </div>
                    </div>
                    <div>
                        {/*<div className="mt-1 valueTxt"><span>{labelValue.InvoiceNum}</span></div>*/}
                        {/*<div className="mt-1 valueTxt"><span>{labelValue.InvoiceDate}</span></div>*/}
                        {/*<div className="mt-1 valueTxt"><span>{labelValue.DueDate}</span></div>*/}
                        {/*<div className="mt-1 valueTxt"><span>{labelValue.FiscalYear}</span></div>*/}
                        {/*<div className="mt-1 valueTxt"><span>{labelValue.FiscalPeriod}</span></div>*/}
                        {/*<div className="mt-1 valueTxt"><span>{labelValue.CurrencyCode}</span></div>*/}
                    </div>
                </div>
            </div>
            </Spin>
            {/*<div className="mt-2 p-2" style={{position:'absolute', top:'400px', right:'4px', border: '1px solid black',width:'216px'}}>*/}
            {/*    <h4>Selected Values</h4>*/}
            {/*    {(response.length>0)&& response.map((res, index) => {*/}
            {/*            return (*/}
            {/*                <div key={index}>*/}
            {/*                    <div className=" selectedValues d-flex justify-content-between flex-wrap border border-dark border-1 my-1 px-2 py-2">*/}
            {/*                        <span className="me-1">{res.label}</span>*/}
            {/*                        <span className="valueString">{res.value}</span>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            );*/}
            {/*        }*/}
            {/*    )}*/}
            {/*</div>*/}
            {/*<div style={{position:'absolute', top:'500px', right:'4px', border: '1px solid black',width:'216px'}}>*/}
            {/*    <img src={screenCapture} alt={'something'}/>*/}
            {/*</div>*/}
            {action === "writing" ? (
                <textarea
                    ref={textAreaRef}
                    onBlur={handleBlur}
                    style={{
                        position: "fixed",
                        top: selectedElement.y1 - 2,
                        left: selectedElement.x1,
                        font: "24px sans-serif",
                        margin: 0,
                        padding: 0,
                        border: 0,
                        outline: 1,
                        resize: "auto",
                        overflow: "hidden",
                        whiteSpace: "pre",
                        background: "transparent",
                        zIndex: 99
                    }}
                />
            ) : null}

            <canvas
                id="canvas"
                // width={window.innerWidth-950}
                width={600}
                height={800}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                style={{
                    // height:'100%',
                    // width:'100%',
                    // border: "2px solid black",
                    padding: 0,
                    zIndex: 99,
                    position: "absolute"
                }}
            >
                Canvas
            </canvas>
            {/* <canvas
        id="canvasForPdf"
        ref={canvasEl}
        width={window.innerWidth - 100}
        height={600}
        style={{
          border: "2px solid black",
          padding: 0,
          zIndex: 1,
          position: "absolute",
          overflow: "scroll"
        }}
      >
        Canvas for image
      </canvas> */}
            {file && (
                <div id={'renderPDF'}
                    style={{
                        height: '700px',
                        padding: 0,
                        zIndex: 1,
                        position: "absolute",
                        overflowY:'scroll',
                    }}
                     onScroll={onScrollHandler}
                >
                    <PDF
                        file={file}
                        page={currentPage}
                        // scale={0.85}
                        scale={1}
                        onDocumentComplete={onDocumentComplete}

                        // zIndex={9999999999999999}
                    />
                </div>
            )}
        </div>
            <ConfirmationModal file={file} visible={saveConfirmVisible} setVisible={setSaveConfirmVisible} onSaveTheTemplate={onSaveTheTemplate} />
        </>
    );
};

export default Canvas;
