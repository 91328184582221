import React from 'react';
import {useState}  from "react";
import {Input, Modal,message} from "antd";
import {useNavigate} from "react-router-dom";
const ConfirmationModal=(props)=>{
    const [showInputField, setShowInputField] = useState(false);
    const [templateName, setTemplateName] = useState('');
    function onOkHandler() {
        console.log('OK')
        if(!showInputField){
            setShowInputField(true);
        }else if(showInputField){
            if(templateName===''){
                message.info('Please type template name!')
            }else{
                props.onSaveTheTemplate(templateName);
                setTemplateName('');
                props.setVisible(false);
            }
        }
    }
    return (
        <>
            <Modal
                zIndex={1001}
                width={400}
                title={'Confirmation'}
                visible={props.visible}
                onOk={onOkHandler}
                onCancel={()=>{
                   props.setVisible(false);
                    setShowInputField(false);
                }}
                destroyOnClose={true}
                okText='Yes'
                cancelText='No'
                okButtonProps={{style:{fontSize: '13px',backgroundColor:'#a11a10',color:'#FFFFFF' ,borderColor:'#a11a10',textAlign:'center' ,veticalAlign:'center'}}}
                cancelButtonProps={{style:{fontSize: '13px' ,borderColor:'#a11a10',color:'black',textAlign:'center' ,veticalAlign:'center'}}}
            >
                {(!showInputField) ? <div>
                    <label htmlFor='comment' className="m-2 comment" style={{fontSize: '13px'}}>Do you want to save the template?</label>
                </div> : <div>
                    <Input placeholder="Template Name" onSelect={(e)=>{
                    console.log(e.target.value);
                        setTemplateName(e.target.value);
                    }
                    }/>
                </div>}
            </Modal>
        </>
    )
}
export default ConfirmationModal;