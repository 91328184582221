import React, { useEffect, useRef } from "react";
import { Invoices, PurchaseOrder } from "../index";
// import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { getInvoices, getOpen, getPoData } from "../../redux/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { LanguagesApi } from "../../api";
import {
  setAllLanguages,
  startLoading,
  stopLoading,
} from "../../redux/languages";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const page = localStorage.getItem("PO");
  const navigate = useNavigate();
  const reduxPoData = useSelector(getPoData);
  const reduxInvoicesData = useSelector(getInvoices);
  const reduxOpen = useSelector(getOpen);
  const po = reduxPoData.length;
  const open = reduxOpen;
  const invoices = reduxInvoicesData.length;
  const totalPo = parseInt(po); /* + parseInt(open)*/
  const activeButton = useRef("1");
  let path = window.location.pathname;
  const [busy, setBusy] = React.useState(false);
  useEffect(() => {
    if (path === "/invoices") {
      activeButton.current = "2";
      navigate("/invoices");
    }
    if (path === "/purchase-order") {
      activeButton.current = "1";
      navigate("/purchase-order");
    }
  }, [path]);

  const invoiceClickHandler = () => {
    localStorage.setItem("PO", "2");
    activeButton.current = "2";
    navigate("/invoices");
  };
  const pOClickHandler = () => {
    localStorage.setItem("PO", "1");
    activeButton.current = "1";
    navigate("/purchase-order");
  };
  return (
    <div className="container-fluid">
      <div className="row">

        <div className="col-lg-2 col-md-3">
          <div className="d-flex flex-column mb-3">
            <button
              onClick={pOClickHandler}
              className={
                activeButton.current === "1" ? "sidebarBtnActive" : "sidebarBtn"
              }
            >
              <span>{t("KSuppPortal_PurchaseOrder") /*Purchase Order*/}</span>
              <span>
                {totalPo} ({open})
              </span>
            </button>
            <button
              onClick={invoiceClickHandler}
              className={
                activeButton.current === "2" ? "sidebarBtnActive" : "sidebarBtn"
              }
            >
              <span>{t("KSuppPortal_Invoices") /*Invoices*/}</span>
              <span>{invoices}</span>
            </button>
          </div>
        </div>

        <div className="col-lg-10 col-md-9">
          <section className="card">
            <header className="card-header">
              <h5 className="card-title">
                {
                  activeButton.current === "1"
                    ? t("KSuppPortal_PurchaseOrder") /*"Purchase Order"*/
                    : t("KSuppPortal_Invoices") /*"Invoices"*/
                }
              </h5>
            </header>
            <main className="card-body">
              {activeButton.current === "1" ? <PurchaseOrder /> : <Invoices />}
            </main>
          </section>
        </div>
      </div>
    </div>
  );
};
export default Home;
