import React, { useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { Spin } from 'antd';
import { useTranslation } from "react-i18next";
import { Button, IconButton, Tooltip, Box } from '@mui/material';
import { GroupWork as GroupWorkIcon } from '@mui/icons-material';


const customStyles = {
  tableHeader: {
    fontSize: '13px',
    color: 'white',
    fontWeight: "400",
    backgroundColor: "#924540",
    '& .MuiTableSortLabel-icon': {
      color: 'white',
    },
    '& .MuiTableSortLabel-root': {
      color: 'white',
    },
    '& .MuiSvgIcon-root': {
      color: 'white',

    }
    
  }
};

const Loader = () => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
    <Spin />;
  </div>
)


const TableCmp = ({ columns, data, isLoading, renderDetailPanel }) => {
  const[isEnable, setEnable]=useState(false)
  const { t} = useTranslation();
  if (isLoading) {
    return (
      <div style={{ padding: "2px" }}>
        <Loader />;
      </div>
    );
  }


  return <MaterialReactTable
    columns={columns}
    displayColumnDefOptions={{
      'mrt-row-expand': {
        muiTableHeadCellProps: {
          sx: {
            minWidth: '10px',
            maxWidth: '55px',
            width: '20px',
            padding: '0',
            backgroundColor: '#924540',
            '&& span': {
              display: 'none',
            },
          },
        },
        muiTableBodyCellProps: {
          sx: {
            align: 'left',
            minWidth: '10px',
            maxWidth: '55px',
            width: '20px',
            // paddingLeft:"1rem"

          },
        },
        size: 130,
      },
    }}
    data={data || []}
    muiTableHeadCellProps={{ sx: customStyles.tableHeader }}
    enableColumnActions={false}
    enableFullScreenToggle={false}
    debugTable={true}
    enableGrouping={isEnable}
    enableColumnOrdering={isEnable}
    enableExpanding={true}

    initialState={{
      density: 'compact',
      expanded: false,
    }}
    renderTopToolbarCustomActions={() => (
      <Tooltip title="Enable Grouping and Rearrangement">

      <Box sx={{width:"40px",height:'40px',alignItems:'center', justifyContent:'center', display:'flex',
      '&:hover': {
       backgroundColor: '#f5f5f5',
       },}}         onClick={()=>{setEnable(!isEnable)}}
>
        <GroupWorkIcon sx={{ color: 'rgba(0, 0, 0, 0.54)',              
        padding: '2px',
         } }    
        />
      </Box>
      </Tooltip>

    )}
    renderRow={(props) => {
      const { row, ...rest } = props;
      return (
        <tr {...rest}>
          {row.getVisibleCells().map(cell => (
            <td key={cell.id}>{cell.getValue() ?? ''}</td>
          ))}
        </tr>
      );
    }}
    renderRowPlaceholder={() => <Loader />}
    renderDetailPanel={renderDetailPanel} 
  />;
};

export default TableCmp;