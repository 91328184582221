// import Konva from "konva";
// import { Stage, Layer, Line } from "react-konva";
import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import style from './style.scss';
import rough from "roughjs/bundled/rough.esm";
import getStroke from "perfect-freehand";
// import PDF from "react-pdf-js";
import PDF from "@mikecousins/react-pdf";
import { Document, page } from "react-pdf-js";
import { Dropdown, message, Space, Menu, Spin, Modal } from 'antd';
import { MyDropDown, ConfirmationModal, ConfirmationDocNameModal } from '../../components/shared';
import { SizeMe } from "react-sizeme";
import detectZoom from "detect-zoom";
//import axios from "axios";
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Button } from "@mui/material";
import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    PlusOutlined
} from "@ant-design/icons";
import html2canvas from "html2canvas";
import Tesseract from "tesseract.js";
import CanvasLabel from "../../components/shared/CanvasLabel";
import { forEach } from "react-bootstrap/ElementChildren";
import styles from "../invoices/style.css";
import moment from "moment";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const generator = rough.generator();
function lineElement(x1, y1, x2, y2) {
    const roughElement = generator.line(x1, y1, x2, y2);
    return { x1, y1, x2, y2, roughElement };
}
function rectangleElement(x1, y1, x2, y2) {
    const roughElement = generator.rectangle(x1, y1, x2 - x1, y2 - y1);
    return { x1, y1, x2, y2, roughElement };
}
function ellipseElement(x1, y1, x2, y2) {
    const roughElement = generator.ellipse(
        x1 + (x2 - x1) / 2,
        y1 + (y2 - y1) / 2,
        x2 - x1,
        y2 - y1
    );
    return { x1, y1, x2, y2, roughElement };
}
function tickElement(x1, y1, x2, y2) {
    const roughElement = [
        generator.line(x1, y1, x1 + (x2 - x1) * 0.2, y2, {
            stroke: "green",
            strokeWidth: 2
        }),
        generator.line(x1 + (x2 - x1) * 0.2, y2, x2, y1 - y1 * 0.3, {
            stroke: "green",
            strokeWidth: 2
        })
    ];
    return { x1, y1, x2, y2, roughElement };
}
function crossElement(x1, y1, x2, y2) {
    const roughElement = [
        generator.line(x1, y1, x2, y2, { stroke: "red", strokeWidth: 2 }),
        generator.line(x1, y2, x2, y1, { stroke: "red", strokeWidth: 2 })
    ];
    return { x1, y1, x2, y2, roughElement };
}
function createElement(id, x1, y1, x2, y2, type, selectedLabel, currentPage) {
    let roughElement;

    switch (type) {
        case "Line":
            roughElement = { ...lineElement(x1, y1, x2, y2), type, id };
            break;

        case "Rectangle":
            // console.log('BEFORE-->',x1, y1, x2, y2);
            roughElement = {
                ...rectangleElement(x1, y1, x2, y2),
                type,
                id,
                selectedLabel,
                currentPage,
            };
            // console.log('AFTER-->',x1, y1, x2, y2);
            break;

        case "Circle":
            roughElement = { ...ellipseElement(x1, y1, x2, y2), type, id };
            break;

        case "Tick":
            roughElement = { ...tickElement(x1, y1, x2, y2), type, id };
            break;

        case "Cross":
            roughElement = { ...crossElement(x1, y1, x2, y2), type, id };
            break;

        case "Pencil":
            return { id, type, points: [{ x: x1, y: y1 }] };

        case "Text":
            return { id, type, x1, y1, x2, y2, text: "" };

        default:
            throw new Error(`Type not recognized ${type}`);
    }

    // roughElement = { ...roughElement, type, id };
    //console.log(lineElement);
    return roughElement;
}
const getSvgPathFromStroke = (stroke) => {
    if (!stroke.length) return "";

    const d = stroke.reduce(
        (acc, [x0, y0], i, arr) => {
            const [x1, y1] = arr[(i + 1) % arr.length];
            acc.push(x0, y0, (x0 + x1) / 2, (y0 + y1) / 2);
            return acc;
        },
        ["M", ...stroke[0], "Q"]
    );

    d.push("Z");
    return d.join(" ");
};
function drawElement(roughCanvas, context, element) {
    switch (element.type) {
        case "Line":
            roughCanvas.draw(element.roughElement);
            break;

        case "Rectangle":
            roughCanvas.draw(element.roughElement);
            break;

        case "Circle":
            roughCanvas.draw(element.roughElement);
            break;

        case "Tick":
            element.roughElement.forEach((elem) => roughCanvas.draw(elem));
            break;

        case "Cross":
            element.roughElement.forEach((elem) => roughCanvas.draw(elem));
            break;

        case "Pencil":
            const stroke = getSvgPathFromStroke(
                getStroke(element.points, { size: 4 })
            );
            context.fill(new Path2D(stroke));
            break;
        case "Text":
            context.textBaseline = "top";
            context.font = "24px sans-serif";
            context.fillText(element.text, element.x1, element.y1);
            break;
        default:
            throw new Error(`Type not recognized ${element.type}`);
    }
}
const adjustElementCoordinates = (element) => {
    const { type, x1, y1, x2, y2 } = element;
    if (type === "rectangle") {
        const minX = Math.min(x1, x2);
        const maxX = Math.max(x1, x2);
        const minY = Math.min(y1, y2);
        const maxY = Math.max(y1, y2);
        return { x1: minX, y1: minY, x2: maxX, y2: maxY };
    } else {
        if (x1 < x2 || (x1 === x2 && y1 < y2)) {
            return { x1, y1, x2, y2 };
        } else {
            return { x1: x2, y1: y2, x2: x1, y2: y1 };
        }
    }
};
const adjustmentRequired = (type) => ["Line", "Rectangle", "Circle", "Tick", "Cross"].includes(type);
function isWithinElement(x, y, element) {
    const { type, x1, y1, x2, y2 } = element;
    if (type === "Rectangle") {
        const minX = Math.min(x1, x2);
        const maxX = Math.max(x1, x2);
        const minY = Math.min(y1, y2);
        const maxY = Math.max(y1, y2);
        return x >= minX && x <= maxX && y >= minY && y <= maxY;
    } else {
        const a = { x: x1, y: y1 };
        const b = { x: x2, y: y2 };
        const c = { x, y };
        const offset = distance(a, b) - (distance(a, c) + distance(b, c));
        return Math.abs(offset) < 1;
    }
}
const distance = (a, b) => Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
const getElementAtPosition = (x, y, elements) => {
    return elements.find((element) => isWithinElement(x, y, element));
};
const Canvas = ({ busy, setBusy, labelInputValue, setLabelInputValue, pdfWidth, setPdfWidth, showSideBar, showInputOnCanvas, onUpdateTempWithDataClick, selectedFile, docId, onSaveTempWithDataClick, getRowData, setMainLabelValue, isLabel, setDataSource, scrolled, setScrolled, mainKey, setMainKey, dataSource, showData, setShowData, totalInput, setTotalInput, isRow, setIsRow, isHeader, setIsHeader, lineText, setLineText, tblHeaders, setTblHeaders, tblRows, setTblRows, allArray, setAllArray, onSaveTheTemplate, onDeleteTheTemplate, setLabelValue, labelValue, response, setCroppedImage, screenCapture, onScreenShotDone, setSelectedFile, onSubmit, activeBtn, setActiveBtn, selectedLabel, setSelectedLabel, onButtonClicked, setChanged, tool, elements, setElements, setMainElements, mainElements }) => {
    document.getElementById('bodyScroll').style.overflow = 'hidden';
    const [isEditable, setIsEditable] = useState(false);
    const [editingRow, setEditingRow] = useState(null);
    const [action, setAction] = useState("none");
    const [loadDropDownMenu, setLoadDropDownMenu] = useState(false);
    // const [showData,setShowData]=useState(false);
    const [selectedElement, setSelectedElement] = useState(null);
    const [pdf, setPdf] = useState({
        file: null
        // base64URL: ""
    });
    // useEffect(() => {
    //     if(docId !== ''){
    //         console.log('IN USE EFFECT FILE-->',selectedFile);
    //         fileToBase64(selectedFile, (err, result) => {
    //             if (result) {
    //                 console.log('FILE BASE64-->',result);
    //                 setFile(result);
    //                 // setFileName(target.files[0]);
    //             }
    //         });
    //     }
    // }, [docId]);
    useEffect(() => {
        setLabelInput(labelInputValue);
    }, [labelInputValue]);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(null);
    const textAreaRef = useRef();
    const canvasEl = useRef(null);
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
    const [cropPositionTop, setCropPositionTop] = useState(0);
    const [cropPositionLeft, setCropPositionLeft] = useState(0);
    const [cropHeigt, setCropHeight] = useState(0);
    const [cropWidth, setCropWidth] = useState(0);
    const [saveConfirmVisible, setSaveConfirmVisible] = useState(false);
    const [saveDocWithDataVisible, setSaveDocWithDataVisible] = useState(false);
    // const [busy, setBusy] = useState(false);
    const [isTemplate, setIsTemplate] = useState(false);
    const [totalPages, setTotalPages] = useState();
    const [currentPage, setCurrentPage] = useStateWithCallbackLazy(1);
    const [scrolledArr, setScrolledArr] = useState([]);
    const [xScrolledArr, setXScrolledArr] = useState([]);
    const scroller = document.getElementById('renderPDF');
    const [labelCount, setLabelCount] = useState(0);
    const [labelInput, setLabelInput] = useState('');
    const [renderWidth, setRenderWidth] = useState(0);
    const [renderHeight, setRenderHeight] = useState(0);
    const [templateID, setTemplateID] = useState(null);
    const [templateName, setTemplateName] = useState('');
    const [pageZoom, setPageZoom] = useState(detectZoom.device());
    const [mouseMoved, setMouseMoved] = useState(0);
    const [pdfScale, setPdfScale] = useState(1.5);
    const pdfCount = useRef(0);
    const pDFWIDTH = useRef(0);
    const [pdfZoom, setPdfZoom] = useState(1);
    const pdfZoomIn = () => {
        // console.log('pdfZoomIn',pdfZoom);
        // document.querySelector('#renderPDF canvas').style.zoom = pdfZoom+0.1;
        // setPdfZoom(pdfZoom+0.1);
        setPdfScale((prev) => prev + 0.05);
    }
    const pdfZoomOut = () => {
        // console.log('pdfZoomOut',pdfZoom);
        // document.querySelector('#renderPDF canvas').style.zoom = pdfZoom-0.1;
        // setPdfZoom(pdfZoom-0.1);
        setPdfScale((prev) => prev - 0.05);
    }
    window.addEventListener('resize', () => {
        setTimeout(() => {
            if (window.devicePixelRatio !== pageZoom) {
                setPageZoom(window.devicePixelRatio);
            }
        }, 1500);
    });
    useEffect(() => {
        var c = document.getElementById('canvas');
        var ctx = c.getContext('2d');
        if (scrolled) {
            console.log('ReScalling');
            console.log(scrolled);
            ctx.translate(0, -scrolled);
            ctx.width = renderWidth;
            scroller.scrollTop++;
        }
    }, [pageZoom]);
    // const columns = dataSource.map((header, index) => {
    //         return{
    //             title:`${header.label}`,
    //             dataIndex:'value',
    //         }
    //     })
    // function createData(
    //     name,
    //     calories,
    //     fat,
    //     carbs,
    //     protein,
    // ) {
    //     return { name, calories, fat, carbs, protein };
    // }
    // const rows = [
    //     // createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    //     // createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    //     // createData('Eclair', 262, 16.0, 24, 6.0),
    //     // createData('Cupcake', 305, 3.7, 67, 4.3),
    //     // createData('Gingerbread', 356, 16.0, 49, 3.9),
    // ];
    //const rows = [...dataSource];
    //console.log('columns = ',columns);
    // {
    //     title:`${labelCount}`,
    //     width:"4%",
    //     dataIndex: 'selectedLabel',
    //     // key: 'APInvHed_OpenPayable',
    //     // render: (_, record) => (
    //     //     <div>{!record?.APInvHed_OpenPayable ?
    //     //         <input type="checkbox" className="editor-active" checked disabled/> :
    //     //         <input type="checkbox" className="editor-active" disabled/>}</div>
    //     // ),
    // },
    var check = 1;
    const timer = ms => new Promise(res => setTimeout(res, ms));
    useLayoutEffect(() => {
        const canvas = document.getElementById("canvas");
        const context = canvas.getContext("2d");
        context.clearRect(0, 0, canvas.width, canvas.height);
        // context.translate(1,1);
        // context.rotate((Math.PI/100)/25);
        // context.transform(1, 0, 0, -1, 0, canvas.height)

        const roughCanvas = rough.canvas(canvas);

        if (isTemplate) {
            const tempCurrent = elements.filter(item => item.currentPage === 1);
            console.log('tempCurrent ON PREV', tempCurrent);
            //setElements(tempCurrent);
            tempCurrent.forEach((element) => {
                if (action === "writing" && selectedElement.id === element.id) return;
                drawElement(roughCanvas, context, element);
                setIsTemplate(false);
            });

        }
        if (isHeader) {
            //const tempCurrent = elements.filter(item => item.currentPage === 1);
            //console.log('tempCurrent ON PREV',tempCurrent);
            //setElements(tempCurrent);
            tblHeaders.forEach((element) => {
                if (action === "writing" && selectedElement.id === element.id) return;
                drawElement(roughCanvas, context, element);
                setIsTemplate(false);
            });
            // setTotalInput([]);
            // tblHeaders.map((header, index) => {
            //     setTotalInput(prev=>[...prev, header.selectedLabel]);
            // })
        }
        else if (isRow) {
            //const tempCurrent = elements.filter(item => item.currentPage === 1);
            //console.log('tempCurrent ON PREV',tempCurrent);
            //setElements(tempCurrent);
            tblRows.forEach((element) => {
                if (action === "writing" && selectedElement.id === element.id) return;
                drawElement(roughCanvas, context, element);
                setIsTemplate(false);
            });
        }
        else if (isLabel) {
            console.log('IN---LABEL');
            console.log(mainElements);
            if (docId !== '') {
                console.log('IN USE EFFECT FILE-->', selectedFile);
                fileToBase64(selectedFile, (err, result) => {
                    if (result) {
                        console.log('FILE BASE64-->', result);
                        setFile(result);
                    }
                });
            }
            [...mainElements].forEach((element) => {
                console.log('INSIDE ELEMENT-->', element);
                if (action === "writing" && selectedElement.id === element.id) return;
                drawElement(roughCanvas, context, element);
                setIsTemplate(false);
            });

        }
        else {
            elements.forEach((element) => {
                if (action === "writing" && selectedElement.id === element.id) return;
                drawElement(roughCanvas, context, element);
            });
        }
    }, [elements, tblHeaders, tblRows, action, selectedElement, scrolledArr, pdf, isLabel, mainElements]);
    useLayoutEffect(() => {
        // const pdfCanvas = document.getElementById("canvasForPdf");
        // const pdfCtx = pdfCanvas.getContext("2d");
        // let imageSrc = new Image(pdfCanvas.width, pdfCanvas.height);
        if (pdf.file) {
            // imageSrc.src = image.base64URL;
            // imageSrc.addEventListener("load", (e) => {
            //   imgCtx.drawImage(imageSrc, 0, 0, imgCanvas.width, imgCanvas.height);
            // });
            // const page = 1;
            // const [loading, numPages] = usePdf({
            //   file: pdf,
            //   page,
            //   canvasEl
            // });
        }
        //context.drawImage(imageSrc, 0, 0, canvas.width, canvas.height);
    }, [pdf]);
    useEffect(() => {
        const textArea = textAreaRef.current;
        if (action === "writing") {
            textArea.focus();
            textArea.value = selectedElement.text;
        }
    }, [action, selectedElement]);
    useEffect(() => {
        window.addEventListener('scroll', (e) => {
            const canvas = document.getElementById("canvas");
            const context = canvas.getContext("2d");
            context.translate(0, 0);
            // setTopPixel(window.pageYOffset);
        })
    }, []);
    useEffect(() => {
        console.log('tblHeaders', tblHeaders)
    }, [tblHeaders]);
    const updateElement = (id, x1, y1, x2, y2, type, options) => {
        const elementsCopy = [...elements];

        switch (type) {
            case "Line":
            case "Rectangle":
            case "Circle":
            case "Tick":
            case "Cross":
                // console.log('CROSS');
                elementsCopy[id] = createElement(id, x1, y1, x2, y2, type, selectedLabel, currentPage);
                break;
            case "Pencil":
                elementsCopy[id].points = [
                    ...elementsCopy[id].points,
                    { x: x2, y: y2 }
                ];
                break;
            case "Text":
                const textWidth = document
                    .getElementById("canvas")
                    .getContext("2d")
                    .measureText(options.text).width;
                const textHeight = 24;
                elementsCopy[id] = {
                    ...createElement(id, x1, y1, x1 + textWidth, y1 + textHeight, type),
                    text: options.text
                };
                break;
            default:
                throw new Error(`Type not recognised: ${type}`);
        }
        // elementsCopy.label = selectedLabel;
        if (isHeader) {
            setTblHeaders(elementsCopy, true);
            setElements(elementsCopy, true);
            //setElements([],true);
            // console.log('isHeader',isHeader);
            // console.log('tblHeaders',tblHeaders);
            // console.log('isRow',isRow);
        } else if (isRow) {
            setTblRows(elementsCopy, true);
            setElements(elementsCopy, true);
            // console.log('isRow',isRow);
            // console.log('tblRows',tblRows);
        } else {
            //console.log('isRow',isRow);
            setElements(elementsCopy, true);
            setMainElements(elementsCopy);
            console.log(elements);
        }
    }
    const handleMouseDown = async (nativeEvent) => {
        // console.log([...elements]);
        // setPdfScale(1.35);
        const tempE = [...elements];
        // console.log('TEMPE------->',tempE);
        const find = tempE.find((el) => {
            // console.log(el);
            return el.selectedLabel == selectedLabel
        });
        // console.log('FIND-->',find);
        if (!(file)) {
            message.info('Please select PDF first!');
        }else{
            if (selectedLabel !== "" && (!find) && file) {
                if (action === "writing") return;
                const { clientX, clientY } = nativeEvent;
                setStartX(clientX);
                setStartY(clientY);
                if (tool === "Select") {
                    // move element
                    const element = getElementAtPosition(clientX, clientY, elements);
                    if (element) {
                        const offsetX = clientX - element.x1;
                        const offsetY = clientY - element.y1;
                        // elementsCopy.label = selectedLabel;
                        setElements((prevState) => prevState);
                        setSelectedElement({ ...element, offsetX, offsetY });
                        setAction("moving");
                    }
                }
                else {
                    const id = elements.length;
                    if (mouseMoved === 0) {
                        setMouseMoved(1);
                    }
                    // console.log('Before Create Element',clientX,clientY);
                    const element = createElement(
                        id,
                        clientX + (scroller.scrollLeft),
                        clientY + (scroller.scrollTop/*window.devicePixelRatio*/),
                        clientX + (scroller.scrollLeft),
                        clientY + (scroller.scrollTop/*window.devicePixelRatio*/),
                        tool,
                        selectedLabel,
                        currentPage,
                    );
                    // element.label = selectedLabel;
                    setElements((prev) => [...prev, element]);
                    setSelectedElement(element);
                    if (tool === "Text") {
                        setAction("writing");
                    } else {
                        setAction("drawing");
                    }
                }
            }
            else if (selectedLabel === "") {
                message.info('Please select atleast one label first!');
            }
            else if (find) {
                message.info('You already Drawn ' + selectedLabel);
            }
        }

    };
    const handleMouseMove = (nativeEvent) => {
        if (mouseMoved === 1) {
            setMouseMoved(2);
        }
        // console.log('MOVE');
        const { clientX, clientY } = nativeEvent;
        if (tool === "Select") {
            nativeEvent.target.style.cursor = getElementAtPosition(
                clientX,
                clientY,
                elements
            )
                ? "grab"
                : "default";
        }
        if (action === "drawing") {
            // if (tool === "Pencil") {
            //   contextRef.current.lineTo(clientX, clientY);
            //   contextRef.current.stroke();
            //   return;
            // }
            // console.log("DRAWING");
            const index = elements.length - 1;
            const { x1, y1 } = elements[index];
            updateElement(index, x1, y1, clientX + (scroller.scrollLeft), clientY + (scroller.scrollTop/*window.devicePixelRatio*/), tool);

            // console.log('X AND Y---->',startX,startY);
            let cropPositionTop = startY;
            let cropPositionLeft = startX;
            const endX = clientX;
            const endY = clientY;
            const isStartTop = endY >= startY;
            const isStartBottom = endY <= startY;
            const isStartLeft = endX >= startX;
            const isStartRight = endX <= startX;
            const isStartTopLeft = isStartTop && isStartLeft;
            const isStartTopRight = isStartTop && isStartRight;
            const isStartBottomLeft = isStartBottom && isStartLeft;
            const isStartBottomRight = isStartBottom && isStartRight;
            // let newBorderWidth = borderWidth;
            let cropWidth = 0;
            let cropHeigth = 0;
            if (isStartTopLeft) {
                // console.log('TOP LEFT');
                // newBorderWidth = `${startY}px ${windowWidth - endX}px ${
                //     windowHeight - endY
                // }px ${startX}px`;
                cropWidth = endX - startX;
                cropHeigth = endY - startY;
            }
            if (isStartTopRight) {
                // console.log('TOP RIGHT');
                // newBorderWidth = `${startY}px ${windowWidth - startX}px ${
                //     windowHeight - endY
                // }px ${endX}px`;
                cropWidth = startX - endX;
                cropHeigth = endY - startY;
                cropPositionLeft = endX;
            }
            if (isStartBottomLeft) {
                // console.log('Bottom LEFT');
                // newBorderWidth = `${endY}px ${windowWidth - endX}px ${
                //     windowHeight - startY
                // }px ${startX}px`;
                cropWidth = endX - startX;
                cropHeigth = startY - endY;
                cropPositionTop = endY;
            }
            if (isStartBottomRight) {
                // console.log('Bottom RIGHT');
                // newBorderWidth = `${endY}px ${windowWidth - startX}px ${
                //     windowHeight - startY
                // }px ${endX}px`;
                cropWidth = startX - endX;
                cropHeigth = startY - endY;
                cropPositionLeft = endX;
                cropPositionTop = endY;
            }
            setCropWidth(cropWidth);
            setCropHeight(cropHeigth);
            setCropPositionLeft(cropPositionLeft);
            setCropPositionTop(cropPositionTop);
        } else if (action === "moving") {
            // console.log('MOVING');
            const { id, x1, x2, y1, y2, type, offsetX, offsetY } = selectedElement;
            const width = x2 - x1;
            const height = y2 - y1;
            const newX1 = clientX - offsetX;
            const newY1 = clientY - offsetY;
            const options = type === "Text" ? { text: selectedElement.text } : {};
            updateElement(
                id,
                newX1,
                newY1,
                newX1 + width,
                newY1 + height,
                type,
                options
            );
        }

    };
    const handleMouseUp = async (nativeEvent) => {
        const { clientX, clientY } = nativeEvent;
        if (selectedElement) {
            if (
                selectedElement.type === "Text" &&
                clientX - selectedElement.offsetX === selectedElement.x1 &&
                clientY - selectedElement.offsetY === selectedElement.y1
            ) {
                setAction("writing");
                return;
            }

            const index = selectedElement.id;
            const { id, type } = elements[index];
            if (action === "drawing" && adjustmentRequired(type)) {
                const { x1, y1, x2, y2 } = adjustElementCoordinates(elements[index]);
                // console.log(id, x1, y1, x2, y2, type);
                // "x1": 92.904,
                //     "y1": 732.32821,
                //     "x2": 144.604108,
                //     "y2": 748.3272099999999,
                // updateElement(id, 92.904, 732.32821, 144.604108, 748.3272099999999, type);
                const canvas = document.getElementById("canvas");
                const context = canvas.getContext("2d");
                // const tempB = [...activeBtn];
                // const temp = tempB.map((btn, index) => {
                //     if (index === 0 && selectedLabel === "VendorNum") {
                //         btn = 1;
                //     } else if (index === 1 && selectedLabel === "InvoiceNum") {
                //         btn = 1;
                //     } else if (index === 2 && selectedLabel === "InvoiceDate") {
                //         btn = 1;
                //     } else if (index === 3 && selectedLabel === "DueDate") {
                //         btn = 1;
                //     } else if (index === 4 && selectedLabel === "FiscalYear") {
                //         btn = 1;
                //     } else if (index === 5 && selectedLabel === "FiscalPeriod") {
                //         btn = 1;
                //     } else if (index === 6 && selectedLabel === "CurrencyCode") {
                //         btn = 1;
                //     }
                //     return btn;
                // });
                // setActiveBtn(temp);
                // console.log('y1-->',(833-y2));
                // console.log('y2-->',(833-y1));
                const zoomState = window.devicePixelRatio;
                updateElement(id, 0, 0, 0, 0, type);
                if (mouseMoved === 2) {
                    if (isRow) {
                        setTimeout(async () => {
                            await getRowData();
                        }, 1);
                    }
                    else {
                        console.log('ELSE PART AFTER ROW');
                        setTimeout(async () => {
                            await onScreenShotDone(cropPositionLeft * zoomState, cropPositionTop * zoomState, cropWidth * zoomState, cropHeigt * zoomState, selectedLabel);
                        }, 1);
                        setMouseMoved(0);
                    }
                } else {
                    setTimeout(async () => {
                        await onScreenShotDone(cropPositionLeft * zoomState, cropPositionTop * zoomState, 1 * zoomState, 1 * zoomState, selectedLabel);
                    }, 1);
                    setMouseMoved(0);
                }
                setTimeout(() => {
                    updateElement(id, x1, y1, x2, y2, type);
                }, 2);

                // await onScreenShotDone(cropPositionLeft, cropPositionTop, cropWidth, cropHeigt, selectedLabel);
                // updateElement(id, x1, y1, x2, y2, type);
                // setChanged(true);
            }
        }
        if (action === "writing") return;
        setSelectedElement(null);
        setAction("none");
        // if(isRow){
        //     getLineData();
        // }
    };
    const handleBlur = (event) => {
        const { id, x1, y1, type } = selectedElement;
        setAction("none");
        setSelectedElement(null);
        updateElement(id, x1, y1, null, null, type, { text: event.target.value });
    };
    const handleClear = () => {
        setElements([]);
        setTotalInput([])
        setMainElements([]);
        setCroppedImage([]);
        setLabelValue([]);
        const temp = [...activeBtn];
        const t = temp.map((btn) => {
            return btn = 0;
        });
        setActiveBtn([]);
        setDataSource([]);
        setTblHeaders([]);
        setTemplateName('');
        setTemplateID(null);

    };
    const fileToBase64 = (file, cb) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(null, reader.result);
        };
        reader.onerror = function (error) {
            cb(error, null);
        };
    };
    const handleInput = ({ target }) => {
        setSelectedFile(target.files[0]);
        if (target.files.length < 1 || !target.validity.valid) {
            return;
        }
        setFile(null);
        handleClear();
        fileToBase64(target.files[0], (err, result) => {
            if (result) {
                console.log('FILE BASE64-->', result);
                setFile(result);
                setFileName(target.files[0]);
            }else if(err){
                setFile(null);
            }
        });
    };
    function onDocumentComplete(e) {
        console.log("onDocumentComplete", e);
        // setTotalPages(e);
        // setCurrentPage(1);
        if (pdfWidth) {
            console.log('WINDOWS-->', window.innerWidth, 'PDF WIDTH-->', pdfWidth, 'PDF CURRENT-->', pDFWIDTH.current);
            if (window.innerWidth - pDFWIDTH.current < 500) {
                console.log('LESS THAN 500');
                setPdfScale(1.2);
            }
            if (window.innerWidth - pDFWIDTH.current > 500) {
                console.log('GREATER THAN 500');
                setPdfScale(1.5);
            }
        }
    }
    function onPageRenderSuccess(e) {
        console.log("onPageRenderSuccess", e);
        // console.log("onPageRenderSuccess", pdfWidth, pDFWIDTH.current);
        if (window.innerWidth - pdfWidth < 500) {
            if (pdfScale !== 1.23) {
                console.log('LESS THAN 500');
                setPdfScale(1.23);
            }
        }
        if (window.innerWidth - pdfWidth > 500) {
            if (pdfScale !== 1.5) {
                setPdfScale(1.5);
            }

        }
    }
    function dataURLtoFile(dataurl, filename) {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }
    function getPicture(cropPositionLeft, cropPositionTop, cropWidth, cropHeigt) {
        return new Promise((resolve, reject) => {
            const body = document.querySelector('body');
            html2canvas(body).then(async (canvas) => {
                let croppedCanvas = document.createElement('canvas');
                let croppedCanvasContext = croppedCanvas.getContext('2d');
                // croppedCanvas.width = cropWidth;
                // croppedCanvas.height = cropHeigth;
                const zoomState = window.devicePixelRatio;
                // croppedCanvasContext.drawImage(
                //     canvas,
                //     cropPositionLeft,
                //     cropPositionTop,
                //     cropWidth / zoomState,
                //     cropHeigt / zoomState,
                //     0,
                //     0,
                //     cropWidth / zoomState,
                //     cropHeigt / zoomState
                // );
                croppedCanvasContext.drawImage(
                    canvas,
                    cropPositionLeft * zoomState,
                    cropPositionTop * zoomState,
                    cropWidth * zoomState,
                    cropHeigt * zoomState,
                    0,
                    0,
                    cropWidth * zoomState,
                    cropHeigt * zoomState
                );
                // croppedCanvas.toDataURL();
                // const url = croppedCanvas.toDataURL()
                const file = dataURLtoFile(croppedCanvas.toDataURL(), 'capture.png');
                // console.log(croppedCanvas.toDataURL());
                // console.log(file);
                let textData = await Tesseract.recognize(
                    file,
                    'eng',
                );
                const { text } = textData.data
                resolve(text);
                return text;
            }).catch(err => {
                reject(err);
            });
        });
    }
    async function onTemplateChange(data) {
        const canvas = document.getElementById("canvas");
        const context = canvas.getContext("2d");
        context.clearRect(0, 0, canvas.width, canvas.height);
        scroller.scrollTop = 0;
        setBusy(true);
        setLabelValue([]);
        setTotalInput([]);
        setMainElements([]);
        setTblHeaders([]);
        setTblRows([]);
        setSelectedLabel(null);
        setAllArray([]);
        setDataSource([]);
        if (data.data.templateData.length > 0) {
            for (let i = 0; i < data.data.templateData.length; i++) {
                setActiveBtn(prev => [...prev, 1]);
            }
        }
        setTemplateID(data.data._id);
        setTemplateName(data.data.templateName);
        let roughElement;
        let array = [];
        let cropPositionTop;
        let cropPositionLeft;
        let p1 = [];
        // setAllArray(data.data.templateData);
        setTblHeaders(data.data.templateHeaders);
        for (let i = 0; i < data.data.templateData.length; i++) {
            let apiData = data.data.templateData[i];
            cropPositionTop = apiData.y1;
            cropPositionLeft = apiData.x1;
            let endX = apiData.x2;
            let endY = apiData.y2;
            let isStartTop = endY >= apiData.y1;
            let isStartBottom = endY <= apiData.y1;
            let isStartLeft = endX >= apiData.x1;
            let isStartRight = endX <= apiData.x1;
            let isStartTopLeft = isStartTop && isStartLeft;
            let isStartTopRight = isStartTop && isStartRight;
            let isStartBottomLeft = isStartBottom && isStartLeft;
            let isStartBottomRight = isStartBottom && isStartRight;
            let cropWidth = 0;
            let cropHeigth = 0;
            if (isStartTopLeft) {
                // console.log('TOP LEFT');
                cropWidth = endX - apiData.x1;
                cropHeigth = endY - apiData.y1;
            }
            if (isStartTopRight) {
                // console.log('TOP RIGHT');
                cropWidth = apiData.x1 - endX;
                cropHeigth = endY - apiData.y1;
                cropPositionLeft = endX + 10;
            }
            if (isStartBottomLeft) {
                // console.log('Bottom LEFT');
                cropWidth = endX - apiData.x1;
                cropHeigth = apiData.y1 - endY;
                cropPositionTop = endY;
            }
            if (isStartBottomRight) {
                // console.log('Bottom RIGHT');
                cropWidth = apiData.x1 - endX;
                cropHeigth = apiData.y1 - endY;
                cropPositionLeft = endX;
                cropPositionTop = endY;
            }
            const zoomState = window.devicePixelRatio;
            
            if (totalPages === 1) {
                scroller.scrollTop = 0;
                if (cropPositionTop > window.innerHeight) {
                    const scrld = cropPositionTop + cropHeigth - window.innerHeight + 20;
                    scroller.scrollTop = scrld;
                    p1.push(getPicture(cropPositionLeft, (cropPositionTop - scrld), cropWidth, cropHeigth));
                }
                else {
                    p1.push(getPicture(cropPositionLeft, cropPositionTop, cropWidth, cropHeigth));
                }
                roughElement = {
                    ...rectangleElement(apiData.x1, apiData.y1, apiData.x2, apiData.y2),
                    type: 'Rectangle',
                    id: i,
                    selectedLabel: apiData.label,
                    currentPage: apiData.currentPage,
                };
                array.push(roughElement);
            }
            else {
                if (currentPage !== apiData.currentPage) {
                    check = apiData.currentPage;
                    setCurrentPage(apiData.currentPage, (currentPage) => {
                    });
                }
                if (check === apiData.currentPage) {
                    await timer(250);
                    let data = await getPicture(cropPositionLeft, cropPositionTop, cropWidth, cropHeigth);
                    p1.push({ text: data, currentPage: apiData.currentPage });
                    roughElement = {
                        ...rectangleElement(apiData.x1, apiData.y1, apiData.x2, apiData.y2),
                        type: 'Rectangle',
                        id: i,
                        selectedLabel: apiData.label,
                        currentPage: apiData.currentPage,
                    };
                    array.push(roughElement)
                }
            }
        }
        setIsTemplate(true);
        setElements(array);
        for (let i = 0; i < array.length; i++) {
            setActiveBtn(prev => [...prev, 1]);
        }
        let arrayOfText = [];
        await Promise.all(p1).then((r1) => {
            for (let i = 0; i < r1.length; i++) {
                arrayOfText.push(r1[i]);
            }
        });
        setMainLabelValue(arrayOfText);
        if (totalPages === 1) {
            for (let i = 0; i < data.data.templateData.length; i++) {
                let labels = { ...labelValue };
                let apiData = data.data.templateData[i];
                const obj = {
                    key: apiData.label,
                    value: arrayOfText[i]
                }
                const label = labelValue.find(item => item.key === apiData.label);
                if (label) {
                    label.value = arrayOfText[i];
                }
                else {
                    setLabelValue(prev => [...prev, obj]);
                }
                setTotalInput(prev => [...prev, apiData.label]);
            }
        }
        else {
            for (let i = 0; i < arrayOfText.length; i++) {
                let labels = { ...labelValue };
                let apiData = data.data.templateData[i];
                const obj = {
                    key: apiData.label,
                    value: arrayOfText[i].text
                }
                //Object.assign(labelValue, obj);
                //Find and Update
                const label = labelValue.find(item => item.key === apiData.label);
                if (label) {
                    label.value = arrayOfText[i].text
                }
                else {
                    setLabelValue(prev => [...prev, obj]);
                }
                
                setTotalInput(prev => [...prev, apiData.label]);
            }
        }
        setCurrentPage(1);
        setBusy(false);
    }
    async function onNextPage() {
        if (isTemplate) {
            const tempAll = [...allArray];
            setCurrentPage(prev => prev + 1, () => {
                const tempCurrent = tempAll.filter(item => item.currentPage === currentPage + 1);
                setElements(tempCurrent);
            });
            if (elements.length > 0) {
                setAllArray([...elements]);
            }
        } else {
            const tempAll = [...allArray];
            setCurrentPage(prev => prev + 1, () => {
                const tempCurrent = tempAll.filter(item => item.currentPage === currentPage + 1);
                setElements(tempCurrent);
            });
            if (elements.length > 0) {
                setAllArray([...elements]);
            }
        }
    }
    function onPrevPage() {
        
        if (isTemplate) {
            const tempAll = [...allArray];

            setCurrentPage(prev => prev - 1, () => {
                const tempCurrent = tempAll.filter(item => item.currentPage === currentPage - 1);
                setElements(tempCurrent);
            });
            if (elements.length > 0) {
                setAllArray([...elements]);
            }
        } else {
            const tempAll = [...allArray];

            setCurrentPage(prev => prev - 1, () => {
                const tempCurrent = tempAll.filter(item => item.currentPage === currentPage - 1);
                setElements(tempCurrent);
            });
            if (elements.length > 0) {
                setAllArray([...elements]);
            }
        }
    }
    function onScrollHandler() {
        var c = document.getElementById("canvas");
        var ctx = c.getContext("2d");

        const size = scrolledArr.length;
        const xSize = xScrolledArr.length;
        const secondLast = scrolledArr[size - 1];
        const xSecondLast = xScrolledArr[xSize - 1];
        const last = scroller.scrollTop;
        const xLast = scroller.scrollLeft;
        if (size == 0) {
            ctx.translate(0, (-scroller.scrollTop/*pageZoom*/));
        }
        if (size > 0) {
            const value = secondLast - last;
            ctx.translate(0, value/*pageZoom*/);
        }
        if (xSize == 0) {
            ctx.translate((-scroller.scrollLeft/*pageZoom*/), 0);
        }
        if (xSize > 0) {
            const xValue = xSecondLast - xLast;
            ctx.translate(xValue, 0);
        }
        setScrolledArr(() => [...scrolledArr, scroller.scrollTop]);
        setXScrolledArr(() => [...xScrolledArr, scroller.scrollLeft]);
        setScrolled(last);
       
    }
    function onAddClicked() {
        if (labelInput === "") {
            message.warn("Please enter a label");
        } else {
            const total = totalInput.find(item => item === labelInput);
            if (total) {
                message.info("Label already exists");
            }
            else {
                //setLabelCount(prev => prev + 1);
                setActiveBtn([...activeBtn, 0]);
                setTotalInput([...totalInput, labelInput]);
                // console.log(totalInput);
                setLabelInput("");
            }
        }

    }
  
    function onChangeInput(t) {
        setLabelInput(t.target.value);
    }
    function onRemoveClicked(index, label) {
        const temp = totalInput.filter(item => item !== totalInput[index]);
        setTotalInput([...temp])

        if (activeBtn[index]) {
           
            let totalInputs = [...totalInput];

           

            totalInputs.splice(index, 1);
            setTotalInput([...totalInputs]);

            const headers = tblHeaders.filter(item => item.selectedLabel !== label);
            setTblHeaders([...headers]);

            const elementss = elements.filter(item => item.selectedLabel !== label);
            setElements([...elementss]);
           
        }
        //const ind = activeBtn.findIndex(activeBtn[index]);
        if (index > -1) {
            activeBtn.splice(index, 1);
            console.log("active", activeBtn)
        }
    }
    function onRemoveLabelClicked(index, label) {
        const temp = totalInput.filter(item => item !== totalInput[index]);
        setTotalInput([...temp])
        if (activeBtn[index]) {
            let newLabel = [...labelValue].filter(item => item.key !== label);
            setLabelValue(newLabel);
            // console.log("labelValue",labelValue);
            let elementss = elements.filter(item => item.selectedLabel !== label);
            setElements([...elementss]);
            let mElements = [...mainElements].filter(item => item.selectedLabel !== label);
            setMainElements([...mElements]);
            let activeButtonsCopy = [...activeBtn];
            activeButtonsCopy.splice(index, 1);
            setActiveBtn(activeButtonsCopy);
        }
        if (index > -1) {
            activeBtn.splice(index, 1);
        }
    }
    const onSaveTheTemplateWithData = (docName) => {
        onSaveTempWithDataClick(templateID !== null ? templateName : 'Document', docName)
    }
    return (
        <>
            <div className="sidebarClasses">
                <Spin spinning={busy} style={{ zIndex: 100 }} >
                    {/*{(window.innerWidth-pdfWidth < 500) && <div className={`${showSideBar ? "canvasContainer sideBarShow" : "sideBarHide"}`} style={{backgroundColor: '#ECEEF7'}}>*/}
                    {/*    <div className="canvasBtns">*/}
                    {/*        <div className="canvasBtnsTop d-flex flex-column">*/}
                    {/*            <div className="d-flex flex-wrap">*/}
                    {/*                <MyDropDown onChange={onTemplateChange}/>*/}
                    {/*                /!*<button className="topFileBtns">*!/*/}
                    {/*                /!*    <span>Select File</span>*!/*/}
                    {/*                /!*    <span><PlusOutlined className="ms-2 align-text-bottom" /></span>*!/*/}
                    {/*                /!*</button>*!/*/}
                    {/*                <input*/}
                    {/*                    type="file"*/}
                    {/*                    accept="application/pdf"*/}
                    {/*                    style={{display: "none"}}*/}
                    {/*                    id="contained-button-file"*/}
                    {/*                    onChange={handleInput}*/}
                    {/*                />*/}
                    {/*                <label htmlFor="contained-button-file">*/}
                    {/*                    <Button*/}
                    {/*                        className=" selectFileBtn topFileBtns"*/}
                    {/*                        // style={{ margin: "4px", padding: "6px 4px 6px 4px",border:'2px solid #a11a10',backgroundColor:'white',marginTop:'0px',fontSize:'13px'}}*/}
                    {/*                        component="span"*/}
                    {/*                        // color="primary"*/}
                    {/*                    >*/}
                    {/*                        <span>Select File</span>*/}
                    {/*                        <span><PlusOutlined className="ms-2 align-text-bottom"/></span>*/}
                    {/*                    </Button>*/}
                    {/*                </label>*/}
                    {/*                <button className="topFileBtns" style={{width: '95px!important'}}*/}
                    {/*                        onClick={() => onDeleteTheTemplate(templateID)}>*/}
                    {/*                    Delete Template*/}
                    {/*                </button>*/}
                    {/*            </div>*/}
                    {/*            <div className="d-flex">*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="canvasBtnsBottom flex-column">*/}
                    {/*            <div className="d-flex">*/}
                    {/*                <button className="topFileBtns" style={{width: '122px'}} /*style={{height:38}}*/}
                    {/*                        onClick={() => {*/}
                    {/*                            if (file) {*/}
                    {/*                                setSaveConfirmVisible(true);*/}
                    {/*                            } else {*/}
                    {/*                                message.error("Please select a file to save");*/}
                    {/*                            }*/}
                    {/*                        }}>*/}
                    {/*                    Save Template*/}
                    {/*                </button>*/}
                    {/*                <button disabled={currentPage === 1} className="topFileBtns" style={{width: '80px'}}*/}
                    {/*                        onClick={onPrevPage}>Prev Page*/}
                    {/*                </button>*/}
                    {/*                <h5 className="topFileBtns" style={{width: '20px'}}>{currentPage}</h5>*/}
                    {/*                <button className="topFileBtns" style={{width: '80px'}} onClick={onNextPage}>Next*/}
                    {/*                    Page*/}
                    {/*                </button>*/}
                    {/*                <button*/}
                    {/*                    onClick={handleClear}*/}
                    {/*                    className="topFileBtns"*/}
                    {/*                    style={{width: '43px'}}*/}
                    {/*                    // style={{margin:'0px 5px',padding:'5px 10px',backgroundColor:'#a11a10',color:'white',border:'2px solid #a11a10',borderRadius: "4px",fontSize:'13px'}}>*/}
                    {/*                >Clear*/}
                    {/*                </button>*/}
                    {/*            </div>*/}
                    {/*            <div className="d-flex mt-1">*/}
                    {/*                {docId === '' && <button onClick={() => {*/}
                    {/*                    if (file) {*/}
                    {/*                        setSaveDocWithDataVisible(true);*/}
                    {/*                    } else {*/}
                    {/*                        message.error("Please select a file to save!");*/}
                    {/*                    }*/}
                    {/*                }} className="topFileBtns">Save Template Data</button>}*/}
                    {/*            </div>*/}
                    {/*            {docId === '' || <button className="topFileBtns" onClick={() => {*/}
                    {/*                onUpdateTempWithDataClick(templateID !== null ? templateID : 'Document')*/}
                    {/*            }}>Update</button>}*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div*/}
                    {/*        className={`${showSideBar ? "canvasBottom forBtns openBarMt" : "canvasBottom forBtns closeBarMt"}`}>*/}
                    {/*        <div>*/}
                    {/*            {(showInputOnCanvas) &&*/}
                    {/*                <div className="for-LabelAddBtns d-flex justify-content-start align-items-center"*/}
                    {/*                    // style={{width:'245px!important'}}*/}
                    {/*                >*/}
                    {/*                    <div className="mt-1">*/}
                    {/*                        <input type='text' value={labelInput} onChange={onChangeInput}*/}
                    {/*                               onKeyDown={(e) => {*/}
                    {/*                                   if (e.keyCode === 13) {*/}
                    {/*                                       onAddClicked();*/}
                    {/*                                   }*/}
                    {/*                               }*/}
                    {/*                               } placeholder="Enter Label" className="valueTxt valueTxtInput"*/}
                    {/*                               required/>*/}
                    {/*                    </div>*/}
                    {/*                    <button onClick={() => onAddClicked()} className={"sidebarBtnActive m-2"}*/}
                    {/*                            style={{width: '95px', padding: '10px 10px',}}>*/}
                    {/*                        <span className="mx-auto">Add</span>*/}
                    {/*                    </button>*/}
                    {/*                </div>}*/}
                    {/*            {*/}
                    {/*                totalInput.map((item, index) => {*/}
                    {/*                        // console.log("item",item);*/}
                    {/*                        let value = '';*/}
                    {/*                        const myValue = labelValue.filter(l => l.key === item);*/}
                    {/*                        if (myValue.length > 0) {*/}
                    {/*                            value = myValue[0].value;*/}
                    {/*                        } else {*/}
                    {/*                            value = '';*/}
                    {/*                        }*/}
                    {/*                        return (*/}
                    {/*                            <CanvasLabel isLabel={isLabel} index={index}*/}
                    {/*                                         onRemoveClicked={() => onRemoveClicked(index, item)}*/}
                    {/*                                         onRemoveLabelClicked={() => onRemoveLabelClicked(index, item)}*/}
                    {/*                                         isHeader={isHeader} key={index} activeBtn={activeBtn[index]}*/}
                    {/*                                         onButtonClicked={() => onButtonClicked(index, item)}*/}
                    {/*                                         label={item}*/}
                    {/*                                         value={value /*labelValue[index]!=null?labelValue[index].value:""*!/*/}
                    {/*                                         showSideBar={showSideBar}*/}
                    {/*                            />*/}
                    {/*                        )*/}
                    {/*                    }*/}
                    {/*                )*/}
                    {/*            }*/}
                    {/*        </div>*/}
                    {/*        <div>*/}
                    {/*            <Modal*/}
                    {/*                visible={showData}*/}
                    {/*                onCancel={() => {*/}
                    {/*                    setIsEditable(false);*/}
                    {/*                    setEditingRow(null)*/}
                    {/*                    setShowData(false)*/}
                    {/*                }}*/}
                    {/*                closable={false}*/}
                    {/*                destroyOnClose={true}*/}
                    {/*                okButtonProps={{style: {display: "none"}}}*/}
                    {/*                cancelButtonProps={{*/}
                    {/*                    style: {*/}
                    {/*                        fontSize: '13px',*/}
                    {/*                        color: '#ffffff',*/}
                    {/*                        backgroundColor: '#a11a10'*/}
                    {/*                    }*/}
                    {/*                }}*/}
                    {/*                width={1000}*/}
                    {/*                style={{padding: 0}}*/}
                    {/*            >*/}
                    {/*                <TableContainer component={Paper} style={{marginTop: 20}}>*/}
                    {/*                    <Table sx={{minWidth: 50}} aria-label="simple table">*/}
                    {/*                        <TableHead*/}
                    {/*                            style={{backgroundColor: '#a11a10', margin: '10px 5px 0px 0px',}}>*/}
                    {/*                            <TableRow>*/}
                    {/*                                {*/}
                    {/*                                    tblHeaders.map((item, index) => (*/}
                    {/*                                        <TableCell key={index}*/}
                    {/*                                                   style={{*/}
                    {/*                                                       color: 'white',*/}
                    {/*                                                       fontSize: '13px',*/}
                    {/*                                                       fontWeight: '500',*/}
                    {/*                                                       textAlign: 'left'*/}
                    {/*                                                   }}*/}
                    {/*                                        >{item.selectedLabel}</TableCell>*/}
                    {/*                                    ))*/}
                    {/*                                }*/}
                    {/*                                <TableCell*/}
                    {/*                                    style={{*/}
                    {/*                                        color: 'white',*/}
                    {/*                                        fontSize: '13px',*/}
                    {/*                                        fontWeight: '500',*/}
                    {/*                                        textAlign: 'center'*/}
                    {/*                                    }}*/}
                    {/*                                >Action</TableCell>*/}
                    {/*                            </TableRow>*/}
                    {/*                        </TableHead>*/}
                    {/*                        <TableBody>*/}
                    {/*                            {dataSource.map((row, index) => (*/}
                    {/*                                <TableRow*/}
                    {/*                                    key={index}*/}
                    {/*                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}>*/}
                    {/*                                    {tblHeaders.map((item, i) => {*/}
                    {/*                                        return (*/}
                    {/*                                            <TableCell component="th" scope="row" size="small"*/}
                    {/*                                                       style={{}}>*/}
                    {/*                                                {(isEditable && editingRow === index) ? (*/}
                    {/*                                                    <input className="editingRowInput"*/}
                    {/*                                                           onChange={(e) => {*/}
                    {/*                                                               // console.log(e.target.value);*/}
                    {/*                                                               // console.log(row[item.selectedLabel]);*/}
                    {/*                                                               row[item.selectedLabel] = e.target.value;*/}
                    {/*                                                           }} defaultValue={row[item.selectedLabel]}*/}
                    {/*                                                           type='text'/>*/}
                    {/*                                                ) : (*/}
                    {/*                                                    row[item.selectedLabel]*/}
                    {/*                                                )*/}
                    {/*                                                }*/}
                    {/*                                            </TableCell>*/}
                    {/*                                        )*/}
                    {/*                                    })}*/}
                    {/*                                    <TableCell component="th" scope="row" size="small"*/}
                    {/*                                               style={{textAlign: 'center'}} key={index}>*/}
                    {/*                                        {(isEditable && editingRow === index) ? (*/}
                    {/*                                            <>*/}
                    {/*                                                <CheckOutlined*/}
                    {/*                                                    className="me-2"*/}
                    {/*                                                    style={{color: 'blue'}}*/}
                    {/*                                                    onClick={() => {*/}
                    {/*                                                        setIsEditable(false);*/}
                    {/*                                                        setEditingRow(null)*/}
                    {/*                                                        // console.log([...dataSource]);*/}
                    {/*                                                        setDataSource([...dataSource]);*/}
                    {/*                                                    }}*/}
                    {/*                                                />*/}
                    {/*                                                <CloseOutlined*/}
                    {/*                                                    style={{color: 'red'}}*/}
                    {/*                                                    onClick={() => {*/}
                    {/*                                                        setEditingRow(null);*/}
                    {/*                                                        setIsEditable(false);*/}
                    {/*                                                    }}*/}
                    {/*                                                />*/}
                    {/*                                            </>*/}
                    {/*                                        ) : (*/}
                    {/*                                            <>*/}
                    {/*                                                <DeleteOutlined onClick={() => {*/}
                    {/*                                                    console.log('index-->', index);*/}
                    {/*                                                    const d = [...dataSource];*/}
                    {/*                                                    d.splice(index, 1);*/}
                    {/*                                                    setDataSource(d);*/}
                    {/*                                                }} className="mx-auto me-2"*/}
                    {/*                                                                style={{color: 'red'}}/>*/}
                    {/*                                                <EditOutlined onClick={() => {*/}
                    {/*                                                    console.log('index-->', index);*/}
                    {/*                                                    setIsEditable(true);*/}
                    {/*                                                    setEditingRow(index);*/}
                    {/*                                                }} style={{color: 'blue'}}/>*/}
                    {/*                                            </>*/}
                    {/*                                        )}*/}
                    {/*                                    </TableCell>*/}
                    {/*                                </TableRow>*/}
                    {/*                            ))}*/}
                    {/*                        </TableBody>*/}
                    {/*                    </Table>*/}
                    {/*                </TableContainer>*/}
                    {/*            </Modal>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>}*/}
                    {/* for Normal width of Pdf*/}
                    {/*(window.innerWidth-pdfWidth > 500) &&*/
                        <div className="originalDesign originalCanvas">
                        <div className="canvasBtns">
                            <div className="canvasBtnsTop d-flex ">
                                <MyDropDown loadDropDownMenu={loadDropDownMenu} onChange={onTemplateChange} />
                                {/*<button className="topFileBtns">*/}
                                {/*    <span>Select File</span>*/}
                                {/*    <span><PlusOutlined className="ms-2 align-text-bottom" /></span>*/}
                                {/*</button>*/}
                                <input
                                    type="file"
                                    accept="application/pdf"
                                    style={{ display: "none" }}
                                    id="contained-button-file"
                                    onChange={handleInput}
                                />
                                <label htmlFor="contained-button-file">
                                    <Button
                                        className=" selectFileBtn topFileBtns"
                                        // style={{ margin: "4px", padding: "6px 4px 6px 4px",border:'2px solid #a11a10',backgroundColor:'white',marginTop:'0px',fontSize:'13px'}}
                                        component="span"
                                    >
                                        <span>Select File</span>
                                        <span><PlusOutlined className="ms-2 align-text-bottom" /></span>
                                    </Button>
                                </label>
                                <button className="topFileBtns" onClick={() => {
                                    if(templateID !== null){
                                        onDeleteTheTemplate(templateID)
                                    }else{
                                        message.error('Please select a template to delete')
                                    }
                                }
                                }>
                                    Delete Template
                                </button>
                                <button className="topFileBtns" /*style={{height:38}}*/ onClick={() => {
                                    if (file) {
                                        setSaveConfirmVisible(true);
                                    } else {
                                        message.error("Please select a file to save");
                                    }
                                }}>
                                    Save Template
                                </button>

                            </div>
                            <div className="canvasBtnsBottom d-flex">

                                <button disabled={currentPage === 1} className="topFileBtns" onClick={onPrevPage}>Prev
                                    Page
                                </button>
                                <h5 className="topFileBtns">{currentPage}</h5>
                                <button className="topFileBtns" onClick={onNextPage}>Next Page</button>
                                <button
                                    onClick={handleClear}
                                    className="topFileBtns"
                                // style={{margin:'0px 5px',padding:'5px 10px',backgroundColor:'#a11a10',color:'white',border:'2px solid #a11a10',borderRadius: "4px",fontSize:'13px'}}>
                                >Clear
                                </button>
                                {docId === '' && <button onClick={() => {
                                    if (file) {
                                        setSaveDocWithDataVisible(true);
                                    } else {
                                        message.error("Please select a file to save!");
                                    }

                                }} className="topFileBtns">Save Data</button>}

                                {docId === '' || <button className="topFileBtns" onClick={() => {
                                    onUpdateTempWithDataClick(templateID !== null ? templateName : 'Document');
                                }}>Update</button>}
                            </div>
                            {/*<div className="d-flex mt-1">*/}
                            {/*    <button className="topFileBtns" onClick={pdfZoomIn}>Zoom In</button>*/}
                            {/*    <button className="topFileBtns" onClick={pdfZoomOut}>Zoom Out</button>*/}
                            {/*</div>*/}

                        </div>
                        <div className="canvasBottom forBtns">
                            <div>
                                {(showInputOnCanvas) &&
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="mt-1">
                                            <input type='text' value={labelInput} onChange={onChangeInput}
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 13) {
                                                        if (file) {
                                                            onAddClicked();
                                                        } else {
                                                            message.error("Please select a file first to add");
                                                        }
                                                    }
                                                }
                                                } placeholder="Enter Label" className="valueTxt valueTxtInput" required />
                                        </div>
                                        <button onClick={() => {
                                            if (file) {
                                                onAddClicked();
                                            } else {
                                                message.error("Please select a file first first to add");
                                            }
                                        }} className={"sidebarBtnActive m-2"}
                                            style={{ width: '110px' }}>
                                            <span className="mx-auto">Add</span>
                                        </button>
                                    </div>}

                                {
                                    totalInput.map((item, index) => {
                                        // console.log("item",item);
                                        let value = '';
                                        const myValue = labelValue.filter(l => l.key === item);
                                        console.log("myValue", myValue);
                                        console.log(activeBtn[index]);
                                        if (myValue.length > 0) {
                                            value = myValue[0].value;
                                        } else {
                                            value = '';
                                        }
                                        return (
                                            <CanvasLabel
                                                isLabel={isLabel}
                                                index={index}
                                                onRemoveClicked={() => onRemoveClicked(index, item)}
                                                onRemoveLabelClicked={() => {
                                                    setSelectedLabel('');
                                                    onRemoveLabelClicked(index, item)
                                                }}
                                                onChange={(e) => {
                                                    let labelValueCopy = JSON.parse(JSON.stringify([...labelValue]));
                                                    labelValueCopy.forEach(l => {
                                                        if (l.key === item) {
                                                            l.value = e.target.value;
                                                        }
                                                    });
                                                    setLabelValue(labelValueCopy);
                                                }}
                                                isHeader={isHeader}
                                                key={index}
                                                activeBtn={activeBtn[index]}
                                                onButtonClicked={() => onButtonClicked(index, item)}
                                                label={item}
                                                value={value /*labelValue[index]!=null?labelValue[index].value:""*/}
                                                showSideBar={showSideBar}
                                            />
                                        )
                                    }
                                    )
                                }
                            </div>
                            <div>
                                <Modal
                                    visible={showData}
                                    onCancel={() => {
                                        setIsEditable(false);
                                        setEditingRow(null)
                                        setShowData(false)
                                    }}
                                    closable={false}
                                    destroyOnClose={true}
                                    okButtonProps={{ style: { display: "none" } }}
                                    cancelButtonProps={{
                                        style: {
                                            fontSize: '13px',
                                            color: '#ffffff',
                                            backgroundColor: '#a11a10'
                                        }
                                    }}
                                    width={1000}
                                    style={{ padding: 0 }}
                                >
                                    <TableContainer component={Paper} style={{ marginTop: 20 }}>
                                        <Table sx={{ minWidth: 50 }} aria-label="simple table">
                                            <TableHead
                                                style={{ backgroundColor: '#a11a10', margin: '10px 5px 0px 0px', }}>
                                                <TableRow>
                                                    {
                                                        tblHeaders.map((item, index) => (
                                                            <TableCell key={index}
                                                                style={{
                                                                    color: 'white',
                                                                    fontSize: '13px',
                                                                    fontWeight: '500',
                                                                    textAlign: 'left'
                                                                }}
                                                            >{item.selectedLabel}</TableCell>
                                                        ))
                                                    }
                                                    <TableCell
                                                        style={{
                                                            color: 'white',
                                                            fontSize: '13px',
                                                            fontWeight: '500',
                                                            textAlign: 'center'
                                                        }}
                                                    >Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {dataSource.map((row, index) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        {tblHeaders.map((item, i) => {
                                                            return (
                                                                <TableCell component="th" scope="row" size="small"
                                                                    style={{}}>
                                                                    {(isEditable && editingRow === index) ? (
                                                                        <input className="editingRowInput"
                                                                            onChange={(e) => {
                                                                                // console.log(e.target.value);
                                                                                // console.log(row[item.selectedLabel]);
                                                                                row[item.selectedLabel] = e.target.value;
                                                                            }} defaultValue={row[item.selectedLabel]}
                                                                            type='text' />
                                                                    ) : (
                                                                        row[item.selectedLabel]
                                                                    )}
                                                                </TableCell>
                                                            )
                                                        })}
                                                        <TableCell component="th" scope="row" size="small"
                                                            style={{ textAlign: 'center' }} key={index}>
                                                            {(isEditable && editingRow === index) ? (
                                                                <>
                                                                    <CheckOutlined
                                                                        className="me-2"
                                                                        style={{ color: 'blue' }}
                                                                        onClick={() => {
                                                                            setIsEditable(false);
                                                                            setEditingRow(null)
                                                                            // console.log([...dataSource]);
                                                                            setDataSource([...dataSource]);
                                                                        }}
                                                                    />
                                                                    <CloseOutlined
                                                                        style={{ color: 'red' }}
                                                                        onClick={() => {
                                                                            setEditingRow(null);
                                                                            setIsEditable(false);
                                                                        }}
                                                                    />
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <DeleteOutlined onClick={() => {
                                                                        console.log('index-->', index);
                                                                        const d = [...dataSource];
                                                                        d.splice(index, 1);
                                                                        setDataSource(d);
                                                                    }} className="mx-auto me-2"
                                                                        style={{ color: 'red' }} />
                                                                    <EditOutlined onClick={() => {
                                                                        console.log('index-->', index);
                                                                        setIsEditable(true);
                                                                        setEditingRow(index);

                                                                    }} style={{ color: 'blue' }} />
                                                                </>
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Modal>
                            </div>
                        </div>
                    </div>}
                </Spin>
                {action === "writing" ? (
                    <textarea
                        ref={textAreaRef}
                        onBlur={handleBlur}
                        style={{
                            position: "fixed",
                            top: selectedElement.y1 - 2,
                            left: selectedElement.x1,
                            font: "24px sans-serif",
                            margin: 0,
                            padding: 0,
                            border: 0,
                            outline: 1,
                            resize: "auto",
                            overflow: "hidden",
                            whiteSpace: "pre",
                            background: "transparent",
                            zIndex: 99
                        }}
                    />
                ) : null}
                <canvas
                    id="canvas"
                    // width={window.innerWidth-950}
                    width={renderWidth - 20}
                    height={renderHeight + 1000}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    style={{
                        padding: 0,
                        zIndex: 99,
                        position: "absolute"
                    }}>
                    Canvas
                </canvas>
                {/* <canvas
        id="canvasForPdf"
        ref={canvasEl}
        width={window.innerWidth - 100}
        height={600}
        style={{
          border: "2px solid black",
          padding: 0,
          zIndex: 1,
          position: "absolute",
          overflow: "scroll"
        }}
      >
        Canvas for image
      </canvas> */}
                {file && <SizeMe monitorHeight>{({ size }) => {
                    console.log('size-->', size.width);
                    // console.log('WINDOW-->', window.innerWidth);
                    // console.log('TOTAL WIDTH---->',window.innerWidth-(size.width));
                    pDFWIDTH.current = size.width;
                    setPdfWidth(size.width);
                    setRenderWidth(size.width);
                    setRenderHeight(size.height);
                    return (
                        <div id={'renderPDF'}
                            style={{
                                height: '100%',
                                padding: 0,
                                zIndex: 1,
                                position: "absolute",
                                overflowY: 'scroll',
                                maxWidth: 1050,
                            }}
                            onScroll={onScrollHandler}
                        >
                            <PDF
                                file={file}
                                page={currentPage}
                                scale={pdfScale}
                                onDocumentComplete={onDocumentComplete}
                                onPageLoadSuccess={(page) => {
                                    console.log('page-->', page);
                                }}
                                onDocumentLoadSuccess={(pdf) => {
                                    setTotalPages(pdf.numPages);
                                    setCurrentPage(1);
                                    onDocumentComplete(pdf);
                                }}
                                onPageRenderSuccess={onPageRenderSuccess}
                            />
                        </div>
                    )
                }}
                </SizeMe>
                }
            </div>
            <ConfirmationModal file={file} visible={saveConfirmVisible} setVisible={setSaveConfirmVisible} onSaveTheTemplate={(e) => { setLoadDropDownMenu(true); onSaveTheTemplate(e); }} />
            <ConfirmationDocNameModal file={file} visible={saveDocWithDataVisible} setVisible={setSaveDocWithDataVisible} onSaveTheTemplate={onSaveTheTemplateWithData} />
        </>
    );
};

export default Canvas;
