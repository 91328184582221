import React from 'react';
import {FormGroup, Input, Label} from "reactstrap";

const MyInputField = (props)=>{
    return (
       <>
           <FormGroup>
               <Label for={props.for} className="text-white">
                   {props.label}
               </Label>
               <Input
                   name={props.name}
                   onChange={props.onChange}
                   required={true}
                   valid={props.first ? null : !props.onError}
                   invalid={props.onError}
                   type={props.type}
                   autoComplete='off'/>
               {/*<FormFeedback invalid={true}>*/}
               {/*    You will be able to see this*/}
               {/*</FormFeedback>*/}
           </FormGroup>
       </>
    );
}
export default MyInputField;