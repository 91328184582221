import React, { useEffect, useRef, useState } from "react";
import { Button, Checkbox, message, Modal, Select, Table } from "antd";
import ResponsiveTable from "ant-responsive-table";
import styles from "./styles.scss";
import moment from "moment";
import { axios, DelConfirm, LanguagesApi, PurchaseOrderAPI } from "../../api";
import {
  messageSPDDays,
  messageSPDLimitExceeded,
  MyExpireErrorModal,
  MyModal,
  SupplierPDCommon,
} from "../../components/shared";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import {
  getPoData,
  setCompanyName,
  setInvoices,
  setOpenRedux,
  setPoData,
  setSupplierCompany,
} from "../../redux/dashboard/";
import {
  canChangeSupplierPromiseDate,
  hasLanguageInLocal,
} from "../../functions";
import i18 from "i18next";
import { useTranslation } from "react-i18next";
import {
  changeLanguage,
  getLanguageState,
  getSelectedLanguage,
} from "../../redux/languages";
import { commonTextOfTable } from "../../constants";
import ReactFlagsSelect from "react-flags-select";
import TableCmp from "../../components/shared/table";

const { Option } = Select;

const pause = async (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

const exampleFunction = async () => {
  await pause(1000);  // Pauses for 3 seconds
};

function TableComponent() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const languageState = useSelector(getLanguageState);
  const d = localStorage.getItem("poData");
  const [open, setOpen] = React.useState(false);
  const [buyerData, setBuyerData] = useState({});
  const [filterData, setFilterData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [poLineData, setPoLineData] = useState([]);
  const poLineDataRef = useRef([]);
  const [poLineDataPagination, setPoLineDataPagination] = useState({
    total: 10,
    showSizeChanger: true,
  });
  const [poLineVisible, setPoLineVisible] = useState(false);
  const [filter, setFilter] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [selectedPoNum, setSelectedPoNum] = useState("");
  const [selectedLine, setSelectedLine] = useState("");
  const [selectedRel, setSelectedRel] = useState("");
  const [col, setCol] = useState([]);
  const [poNumColum, setPoNumColum] = useState([]);
  const [lastComment, setLastComment] = useState("");
  const [poLastComment, setPoLastComment] = useState("");
  const [pagination, setPagination] = useState({
    total: 100,
  });
  const [selectedPONum, setSelectedPONum] = useState("");
  const openBtnRef = useRef(null);
  const selectedPoNumOpen = useRef("0");
  const btnClass = useRef("1");
  const myData = useRef([]);
  const rowSelectedPoNum = useRef("");
  const rowSelectedOpenStatus = useRef("");
  const [data, setData] = useState();
  const [btn, setBtn] = useState();
  const [relConfirmed, setRelConfirmed] = useState();
  const currentPoDetailOpenStatus = useRef(false);
  const [visible, setVisible] = useState(false);

  let poForModal;
  const dataFromRedux = useSelector(getPoData);


  const columns = [
    {
      accessorKey: 'POHeader_PONum',
      header: t("KSuppPortal_POHeader_PONum" /*"Po Num"*/),
      size: 20,
      sorter: (a, b) => {
        return a.POHeader_PONum - b.POHeader_PONum;
      },
      Cell: ({ cell, row }) => {
        const PONum = cell.getValue();
        const record = row.original;
        return (
          <div style={{ display: 'flex' }}>
            <a
              onClick={() => {
                setSelectedPoNum(PONum);
                poNumClickHandler(
                  PONum,
                  record?.POHeader_OpenOrder,
                  record?.Calculated_AcceptReject,
                  record?.Calculated_AcceptRejReason
                );
              }}
              style={{ cursor: 'pointer' }}
            >
              <u>{String(PONum)}</u>
            </a>
          </div>
        );
      },
    },
    {
      accessorKey: 'POHeader_OrderDate',
      header: t("KSuppPortal_POHeader_OrderDate" /*"Order date"*/),
      size: 10,
      sorter: (a, b) => {
        const dateA = new Date(a.POHeader_OrderDate);
        const dateB = new Date(b.POHeader_OrderDate);
        return dateA - dateB; // Compare dates
      },
      Cell: ({ cell }) => {
        let value = cell.getValue();
        return <span>  {value ? moment(value).format('YYYY-MM-DD') : ''} </span>
      },
    },
    {
      accessorKey: 'POHeader_DueDate',
      header: t("KSuppPortal_POHeader_DueDate" /*"Due date"*/),
      size: 30,
      sorter: (a, b) => {
        const dateA = new Date(a.POHeader_DueDate);
        const dateB = new Date(b.POHeader_DueDate);
        return dateA - dateB; // Compare dates
      },
      Cell: ({ cell }) => {
        let value = cell.getValue();
        return <span>  {value ? moment(value).format('YYYY-MM-DD') : ''} </span>
      }
    },
    {
      accessorKey: 'POHeader_EntryPerson',
      header: t("KSuppPortal_POHeader_EntryPerson" /*"Purchaser"*/),
      size: 10,
    },
    {
      accessorKey: 'POHeader_PurPoint',
      header: t("KSuppPortal_POHeader_PurPoint" /*"Purchase Point"*/),
      size: 10,
    },
    {
      accessorKey: 'POHeader_OpenOrder',
      header: t("KSuppPortal_POHeader_OpenOrder" /*"Open PO"*/),
      size: 10,
      Cell: ({ cell }) => {
        const isOpenOrder = cell.getValue();
        return isOpenOrder
          ? t("KSuppPortal_APInvHed_OpenPayable") // "Open"
          : t("KSuppPortal_Closed"); // "Closed"
      },
    },
    {
      accessorKey: 'Calculated_AcceptReject',
      header: t("KSuppPortal_Calculated_AcceptReject" /*"Supplier Status"*/),
      size: 30,
      Cell: ({ cell }) => {
        const statusValue = cell.getValue();
        return statusValue === 0
          ? "None"
          : statusValue === 1
            ? t("KSuppPortal_Accepted") /*"Accepted"*/
            : t("KSuppPortal_Rejected") /*"Rejected"*/

      },
    },
    {
      accessorKey: 'POHeader_TotalOrder',
      header: t("KSuppPortal_POHeader_TotalOrder" /*"Order Total"*/),
      size: 20,
      sortDescFirst: true,
      sorter: (a, b) => {
        return a.POHeader_TotalOrder - b.POHeader_TotalOrder;
      },
      Cell: ({ cell }) => (
        <span>
          <NumberFormat
            value={parseFloat(cell.getValue()).toFixed(2)}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
        </span>
      ),
    },
    {
      accessorKey: 'POHeader_CurrencyCode',
      header: t("KSuppPortal_POHeader_CurrencyCode" /*"Currency Code"*/),
      size: 30,
    },
    {
      accessorKey: 'action',
      header: t('KSuppPortal_Action'),
      enableGrouping: false,
      size: 10,
      Cell: ({ row }) => {
        const record = row.original;
        return (
          <div>
            {record?.POHeader_OpenOrder  /* && (record?.Calculated_AcceptReject === 0 || record?.Calculated_AcceptReject === -1)*/ && (
            <div className="d-flex">
              {record?.Calculated_AcceptReject === 0 ? (
                <>
                  {/* Accept button */}
                  <div className="me-2">
                    <button
                      onClick={() => {
                        setSelectedPoNum(record.POHeader_PONum);
                        setModalTitle("Accept");
                        setModalVisible(true);
                      }}
                      className="btn btn-sm btn-success"
                    >
                      {t("KSuppPortal_Accept") /*Accept*/}
                    </button>
                  </div>
                  {/* Reject button */}
                  <div>
                    <button
                      onClick={() => {
                        setSelectedPoNum(record.POHeader_PONum);
                        setModalTitle("Reject");
                        setModalVisible(true);
                      }}
                      className="btn btn-sm btn-danger"
                    >
                      {t("KSuppPortal_Reject") /*Reject*/}
                    </button>
                  </div>
                </>
              ) : record?.Calculated_AcceptReject === 1 ? (
                <div>
                  <button
                    onClick={() => {
                      setSelectedPoNum(record.POHeader_PONum);
                      setModalTitle("Reject");
                      setModalVisible(true);
                    }}
                    className="btn btn-sm btn-danger"
                  >
                    {t("KSuppPortal_Reject") /*Reject*/}
                  </button>
                </div>
              ) : (
                <>
                  <div className="me-2">
                    <button
                      onClick={() => {
                        setSelectedPoNum(record.POHeader_PONum);
                        setModalTitle("Accept");
                        setModalVisible(true);
                      }}
                      className="btn btn-sm btn-success"
                    >
                      {t("KSuppPortal_Accept") /*Accept*/}
                    </button>
                  </div>
                </>
              )}
            </div>
           )} 
          </div>
        )
      }
    },
  ]



  let MobViewColumns = [
    {
      title: t("KSuppPortal_POHeader_PONum" /*"Po Num"*/),
      width: "7%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "POHeader_PONum",
      key: "POHeader_PONum",
      sorter: (a, b) =>
        a.POHeader_PONum.toString()?.localeCompare(b.POHeader_PONum.toString()),
      render: (text, record) => (
        <div style={{ display: "flex" }}>
          <a
            onClick={() => {
              setSelectedPoNum(text);
              poNumClickHandler(
                text,
                record?.POHeader_OpenOrder,
                record?.Calculated_AcceptReject,
                record?.Calculated_AcceptRejReason
              );
            }}
          >
            <u>{text}</u>
          </a>
        </div>
      ),
    },
    {
      title: t("KSuppPortal_POHeader_OrderDate" /*"Order date"*/),
      width: "8%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "POHeader_OrderDate",
      key: "POHeader_OrderDate",
      sorter: (a, b) =>
        a.POHeader_OrderDate?.localeCompare(b.POHeader_OrderDate),
      render: (_, record) => (
        <span>{moment(record?.POHeader_OrderDate).format("YYYY-MM-DD")}</span>
      ),
    },
    {
      title: t("KSuppPortal_POHeader_DueDate" /*"Due date"*/),
      width: "8%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "POHeader_DueDate",
      key: "POHeader_DueDate",
      sorter: (a, b) => a.POHeader_DueDate?.localeCompare(b.POHeader_DueDate),
      render: (_, record) => (
        <span>
          {record?.POHeader_DueDate !== null
            ? moment(record?.POHeader_DueDate).format("YYYY-MM-DD")
            : ""}
        </span>
      ),
    },
    {
      title: t("KSuppPortal_POHeader_EntryPerson" /*"Purchaser"*/),
      width: "8%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "POHeader_EntryPerson",
      key: "POHeader_EntryPerson",
    },
    {
      title: t("KSuppPortal_POHeader_PurPoint" /*"Purchase Point"*/),
      width: "10%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "POHeader_PurPoint",
      key: "POHeader_PurPoint",
    },
    {
      title: t("KSuppPortal_POHeader_OpenOrder" /*"Open PO"*/),
      width: "10%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "POHeader_OpenOrder",
      className: "text-center",
      key: "POHeader_OpenOrder",
      render: (_, record) => (
        <>
          {
            record?.POHeader_OpenOrder
              ? t("KSuppPortal_APInvHed_OpenPayable") /*"Open"*/
              : t("KSuppPortal_Closed") /*"Closed"*/
          }
        </>
      ),
    },
    {
      title: t("KSuppPortal_Calculated_AcceptReject" /*"Supplier Status"*/),
      width: "9%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "Calculated_AcceptReject",
      key: "Calculated_AcceptReject",
      render: (_, record) => (
        <span>
          {
            record?.Calculated_AcceptReject === 0
              ? "None"
              : record?.Calculated_AcceptReject === 1
                ? t("KSuppPortal_Accepted") /*"Accepted"*/
                : t("KSuppPortal_Rejected") /*"Rejected"*/
          }
        </span>
      ),
    },
    {
      title: t("KSuppPortal_POHeader_TotalOrder" /*"Order Total"*/),
      width: "9%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "POHeader_DocTotalOrder",
      className: "text-end",
      sorter: (a, b) =>
        a.POHeader_DocTotalOrder?.localeCompare(b.POHeader_DocTotalOrder),
      key: "POHeader_DocTotalOrder",
      render: (text, record) => (
        <span>
          {
            <NumberFormat
              value={parseFloat(text).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
          }
        </span>
      ),
    },
    {
      title: t("KSuppPortal_POHeader_CurrencyCode" /*"Currency Code"*/),
      width: "9%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "POHeader_CurrencyCode",
      className: "text-center",
      key: "POHeader_CurrencyCode",
      render: (text, record) => <>{text}</>,
    },
    {
      title: t("Action"),
      width: "6%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        setBtn(record)
        exampleFunction()
        return (
          <div>
            {btn?.POHeader_OpenOrder && (
              <div className="d-flex">
                {btn?.Calculated_AcceptReject !== 1 ? (
                  <div className="me-2">
                    <button
                      style={{ borderRadius: "30px", fontSize: "12px" }}
                      onClick={() => {
                        setSelectedPoNum(btn?.POHeader_PONum);
                        setModalTitle("Accept");
                        setModalVisible(true);
                      }}
                      className="btn btn-sm btn-success"
                    >
                      {t("KSuppPortal_Accept") /* Accept */}
                    </button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    setCol(poColumns);
    // if (hasLanguageInLocal()) {
    setPoNumColum(columns);
    // }

    dispatch(changeLanguage(i18.language));
    if (dataFromRedux.length > 0) {
      setData(dataFromRedux);
      myData.current = dataFromRedux;
      btnClass.current = "1";
      openBtnRef.current.click();
    } else {
      setDataLoading(true);
      PurchaseOrderAPI.getCustomerData()
        .then((res) => {
          dispatch(setPoData(res.data.vendordata.podata));
          dispatch(setInvoices(res.data.vendordata.apinvdata));
          dispatch(setOpenRedux(res.data.vendordata.pocount.open));
          dispatch(setCompanyName(res.data.vendordata.profiledata.Name));
          dispatch(setSupplierCompany(res.data.otherdata));
          setData(res.data.vendordata.podata);
          myData.current = res.data.vendordata.podata;
          openBtnRef.current.click();
          setDataLoading(false);
        })
        .catch((err) => {
          console.log(JSON.stringify(err));
          setDataLoading(false);
        });
    }
  }, []);

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const updateDimensions = () => {
    setWidth((prevState) => window.innerWidth);
    setHeight((prevState) => window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    // console.log('Data from redux',dataFromRedux);
  }, [dataFromRedux]);

  useEffect(() => {
    if (selectedPoNum.length > 0) {
      const tempColumns = columns.map((col) => {
        if (col.dataIndex === "Calculated_AcceptReject") {
          col.render = (text, record) => {
            if (record.POHeader_PONum == selectedPoNum) {
              return (
                <>
                  <span>
                    {modalTitle === "Accepted"
                      ? t("KSuppPortal_Accepted")
                      : modalTitle === "Rejected"
                        ? t("KSuppPortal_Rejected")
                        : modalTitle === "None"
                          ? t("KSuppPortal_None")
                          : modalTitle}
                  </span>
                </>
              );
            } else {
              return (
                <>
                  <span>
                    {
                      record?.Calculated_AcceptReject === 0
                        ? t("KSuppPortal_None") /*"None"*/
                        : record?.Calculated_AcceptReject === 1
                          ? t("KSuppPortal_Accepted") /*"Accepted"*/
                          : t("KSuppPortal_Rejected") /*"Rejected"*/
                    }
                  </span>
                </>
              );
            }
          };
        }
        return col;
      });
      setPoNumColum(tempColumns);
      setDataLoading(false);
      setSelectedPoNum("");
    }
  }, [data]);


  function onDelConfirmChange(e, release, line, partNum) {
    // console.log(e.target.checked,release,line,partNum);
    setLoading(true);
    DelConfirm.onDelConfirm(
      e.target.checked,
      release,
      line,
      localStorage.getItem("ponum")
    )
      .then((res) => {
        const tempData = myData.current.map((item) => {
          // console.log(item.PORel_PORelNum);
          if (
            item.PORel_PORelNum === release &&
            item.PODetail_POLine === line &&
            item.PODetail_PartNum === partNum
          ) {
            item.PORel_KDelConfirm_c = e.target.checked;
          }
          return item;
        });
        setPoLineData(tempData, () => {
          const tempColumns = poColumns.map((col) => {
            if (col.dataIndex === "PORel_KDelConfirm_c") {
              col.render = (text, record) => {
                let diffDays = "";
                // let suppPromiseDate = true;
                if (
                  record?.Calculated_DueDate == null ||
                  record?.Calculated_DueDate == ""
                ) {
                  // suppPromiseDate = false;
                  const promiseDate = new Date(
                    moment(record?.PORel_DueDate).format("YYYY-MM-D")
                  );
                  const todayDate = new Date(
                    moment(new Date()).format("YYYY-MM-D")
                  );
                  const diffTime = todayDate - promiseDate;
                  diffDays = diffTime / (1000 * 60 * 60 * 24);
                  diffDays = Math.round(diffDays);
                  // console.log(diffDays);
                } else {
                  // suppPromiseDate = true;
                  const promiseDate = new Date(
                    moment(record?.Calculated_DueDate).format("YYYY-MM-D")
                  );
                  const todayDate = new Date(
                    moment(new Date()).format("YYYY-MM-D")
                  );
                  const diffTime = todayDate - promiseDate;
                  diffDays = diffTime / (1000 * 60 * 60 * 24);
                  diffDays = Math.round(diffDays);
                }
                if (
                  record.PORel_PORelNum === release &&
                  record.PODetail_POLine === line &&
                  record.PODetail_PartNum === partNum
                ) {
                  // console.log('RENDER DONE');
                  return (
                    <>
                      {!record.PORel_VoidRelease && record.PORel_OpenRelease ? (
                        <Checkbox
                          disabled={!(diffDays <= 0 && diffDays >= -6)}
                          className={
                            diffDays <= 0 && diffDays >= -6
                              ? "antdActiveCheckbox"
                              : "antdDisableCheckBox"
                          }
                          checked={record.PORel_KDelConfirm_c}
                          onChange={(e) =>
                            onDelConfirmChange(
                              e,
                              record.PORel_PORelNum,
                              record.PODetail_POLine,
                              record.PODetail_PartNum
                            )
                          }
                        />
                      ) : (
                        <Checkbox
                          disabled={true}
                          className={"antdDisableCheckBox"}
                          checked={record.PORel_KDelConfirm_c}
                          onChange={(e) =>
                            onDelConfirmChange(
                              e,
                              record.PORel_PORelNum,
                              record.PODetail_POLine,
                              record.PODetail_PartNum
                            )
                          }
                        />
                      )}
                    </>
                  );
                } else {
                  return (
                    <>
                      {!record.PORel_VoidRelease && record.PORel_OpenRelease ? (
                        <Checkbox
                          disabled={!(diffDays <= 0 && diffDays >= -6)}
                          className={
                            diffDays <= 0 && diffDays >= -6
                              ? "antdActiveCheckbox"
                              : "antdDisableCheckBox"
                          }
                          checked={record.PORel_KDelConfirm_c}
                          onChange={(e) =>
                            onDelConfirmChange(
                              e,
                              record.PORel_PORelNum,
                              record.PODetail_POLine,
                              record.PODetail_PartNum
                            )
                          }
                        />
                      ) : (
                        <Checkbox
                          disabled={true}
                          className={"antdDisableCheckBox"}
                          checked={record.PORel_KDelConfirm_c}
                          onChange={(e) =>
                            onDelConfirmChange(
                              e,
                              record.PORel_PORelNum,
                              record.PODetail_POLine,
                              record.PODetail_PartNum
                            )
                          }
                        />
                      )}
                    </>
                  );
                }
              };
            }
            return col;
          });
          setCol(tempColumns);
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.response.data);
      });
  }

  useEffect(() => {
    setLoading(false);
  }, [poLineData]);

  function onRelConfirm(e, index, dateString, poNum, line, rel) {
    setLoading(true);
    PurchaseOrderAPI.onRelConfirm(
      dateString,
      rel,
      line,
      poNum,
      e.target.checked
    )
      .then((res) => {
        // console.log(JSON.stringify(res));
        const tempData = myData.current.map((item, itemIndex) => {
          // console.log(index, itemIndex)
          if (index === itemIndex) {
            // console.log(item.PORel_Confirmed);
            item.PORel_Confirmed = e.target.checked;
            // console.log(item.PORel_Confirmed);
          }
          return item;
        });
        setPoLineData(tempData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(JSON.stringify(err));
        setLoading(false);
      });
  }

  function poNumClickHandler(poNum, openStatus, status, reason) {
    // console.log(openStatus);
    currentPoDetailOpenStatus.current = openStatus;
    rowSelectedPoNum.current = poNum;
    rowSelectedOpenStatus.current = openStatus;
    setSelectedPONum(poNum);
    poForModal = poNum;
    localStorage.setItem("ponum", poNum);
    selectedPoNumOpen.current = openStatus;
    setPoLineVisible(true);
    setLoading(true);
    let config = {
      method: "get",
      url: "/api/mcustdata/orders?PONum=" + poNum,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        var d = {};
        setPoLineData(response.data.data.rels);
        const tempPagination = { total: response.data.data.rels.length };
        setPoLineDataPagination(tempPagination);
        myData.current = response.data.data.rels;
        // currentPoDetailOpenStatus.current = response.data.data.orderdata.OpenOrder;
        // console.log(JSON.stringify(response.data.data.orderdata));
        d.buyer = response.data.data.orderdata.BuyerIDName;
        d.poNum = response.data.data.orderdata.PONum;
        d.plant = response.data.data.orderdata.pbsPlant_c;
        d.orderdate = response.data.data.orderdata.OrderDate.split("T")[0];
        // d.totalCharge = response.data.data.orderdata.TotalCharges;
        d.totalCharge = response.data.data.orderdata.DocTotalCharges;
        // d.totalDiscount = response.data.data.orderdata.TotalDiscount;
        d.totalNet = response.data.data.orderdata.DocTotalOrder;
        d.totalTax = response.data.data.orderdata.DocTotalTax;
        d.vendorName = response.data.data.orderdata.VendorName;
        d.currencyCode = response.data.data.rels[0].POHeader_CurrencyCode;
        d.address =
          response.data.data.orderdata.VendorAddress1 +
          "\n" +
          response.data.data.orderdata.VendorAddress2 +
          " " +
          response.data.data.orderdata.VendorZIP +
          " " +
          response.data.data.orderdata.VendorCountry;

        d.status =
          status === 1 ? "Accepted" : status === -1 ? "Rejected" : "None";
        // setStatus(response.data.data.podtl[0].Calculated_AcceptReject === 1 ? 'Accepted' : response.data.data.podtl[0].Calculated_AcceptReject === -1 ? 'Rejected' : 'None');
        // setPoLastComment(response.data.data.podtl[0].Calculated_AcceptRejReason);
        d.lastComment = reason ? reason : "";
        // console.log(JSON.stringify(d));
        setBuyerData(d);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        // console.log(error);
      });
  }

  const onChange = async (
    date,
    dateString,
    poNum,
    line,
    rel,
    copy = 0,
    partNum,
    relConfirmed,
    counter
  ) => {
    // console.log(dateString,poNum,line,rel);
    if (counter !== 3) {
      setLoading(true);
      if (copy === 0) {
        const tempData = myData.current.map((item) => {
          if (
            item.PODetail_POLine === line &&
            item.PORel_PORelNum === rel &&
            item.PODetail_PartNum === partNum
          ) {
            item.Calculated_DueDate = dateString + "T00:00:00";
            item.PORel_Confirmed = true;
            item.PORel_PromiseDateLimiter_c =
              item.PORel_PromiseDateLimiter_c + 1;
          }
          return item;
        });
        setPoLineData(tempData);
        const tempColumns = poColumns.map((col) => {
          if (col.dataIndex === "Calculated_DueDate") {
            col.render = (text, record) => (
              <>
                <SupplierPDCommon record={record} onChange={onChange} />
              </>
            );
          }
          return col;
        });
        setCol(tempColumns);
      }
      poNum = parseInt(localStorage.getItem("ponum"));
      let data = JSON.stringify({
        PONum: poNum,
        line: line,
        release: rel,
        wdate: dateString,
        PORel_Confirmed: relConfirmed,
        // "email": "testing@gmail.com"
      });
      const config = {
        method: "post",
        url: "/api/mcustdata/polineduedate",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };
      try {
        const response = await axios(config);
        if (copy === 0) {
          setLoading(false);
          message.success(
            t(
              "KSuppPortal_UpdateMessage"
            ) /*"Supplier promise date has been Updated"*/
          );
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        const { response } = error;
        if (response.status === 429) {
          message.info(messageSPDLimitExceeded);
        } else if (response.status === 424) {
          message.info(messageSPDDays);
        }
      }
    } else {
      message.info(
        t(
          "KSuppPortal_LimitExceeded"
        ) /*"You can't update it. Supplier promise date update limit has been exceeded."*/
      );
    }
  };

  const poColumns = [
    {
      title: t("KSuppPortal_PODetail_PartNum") /*"Part Num"*/,
      width: "8%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PODetail_PartNum",
      key: "POHeader_PONum",
    },
    {
      title: t("KSuppPortal_PODetail_LineDesc") /*"Description"*/,
      width: "8%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PODetail_LineDesc",
      key: "PODetail_LineDesc",

    },
    {
      title: t("KSuppPortal_PODetail_ClassID") /*"Class ID"*/,
      width: "5%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PODetail_ClassID",
      key: "PODetail_ClassID",

    },
    // {
    //     title: 'Class ID',
    //     width:"2%",
    //     dataIndex: 'POHeader_PONum',
    //     key: 'POHeader_PONum',
    //     visible: false
    // },
    {
      title: t("KSuppPortal_PORel_DueDate") /*"Due Date"*/,
      width: "8%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PORel_DueDate",
      key: "PORel_DueDate",
      render: (record) => (<>
        <span>
          {
            record
              ? moment(record).format("YYYY-MM-DD")
              : "not fiend"}
        </span>
      </>
      ),
    },
    {
      title: t("KSuppPortal_Calculated_DueDate") /*"Supplier Promise Date"*/,
      width: "10%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "Calculated_DueDate",
      className: "text-center",
      key: "Calculated_DueDate",
      render: (_, record) => {
        return <SupplierPDCommon record={record} onChange={onChange} />
      },
    },
    {
      title: t("KSuppPortal_PORel_Confirmed") /*"Rel Confirmed"*/,
      width: "7%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PORel_Confirmed",
      className: "text-center",
      key: "PORel_Confirmed",
      render: (_, record, index) => {
        setRelConfirmed(record)
        exampleFunction()
        let data = record

        return (
          <>
            {!data?.PORel_VoidRelease && data?.PORel_OpenRelease ? (
              <Checkbox
                className="antdActiveCheckbox"
                checked={data.PORel_Confirmed}
                onChange={(e) => {
                  onRelConfirm(
                    e,
                    index,
                    data.Calculated_DueDate !== null
                      ? data.Calculated_DueDate.split("T")[0]
                      : data.Calculated_DueDate,
                    rowSelectedPoNum.current,
                    data.PODetail_POLine,
                    data.PORel_PORelNum
                  );
                }}
                disabled={false}
              />
            ) : (
              <Checkbox
                className="antdDisableCheckBox"
                checked={data?.PORel_Confirmed}
                disabled={true}
              />
            )}
          </>
        );
      },
    },
    {
      title: t("KSuppPortal_PORel_KDelConfirm_c") /*"Confirm Delivery"*/,
      width: "8%",
      showOnResponse: true,
      showOnDesktop: true,

      dataIndex: "PORel_KDelConfirm_c",
      className: "text-center",
      key: "PORel_KDelConfirm_c",
      render: (_, record) => {
        let diffDays = "";
        // let suppPromiseDate = true;
        if (
          record?.Calculated_DueDate == null ||
          record?.Calculated_DueDate == ""
        ) {
          // suppPromiseDate = false;
          const promiseDate = new Date(
            moment(record?.PORel_DueDate).format("YYYY-MM-D")
          );
          const todayDate = new Date(moment(new Date()).format("YYYY-MM-D"));
          const diffTime = todayDate - promiseDate;
          diffDays = diffTime / (1000 * 60 * 60 * 24);
          diffDays = Math.round(diffDays);
        } else {
          // suppPromiseDate = true;
          const promiseDate = new Date(
            moment(record?.Calculated_DueDate).format("YYYY-MM-D")
          );
          const todayDate = new Date(moment(new Date()).format("YYYY-MM-D"));
          const diffTime = todayDate - promiseDate;
          diffDays = diffTime / (1000 * 60 * 60 * 24);
          diffDays = Math.round(diffDays);
        }
        return (
          <>
            {!record?.PORel_VoidRelease && record?.PORel_OpenRelease ? (
              <Checkbox
                disabled={!(diffDays <= 0 && diffDays >= -6)}
                className={
                  diffDays <= 0 && diffDays >= -6
                    ? "antdActiveCheckbox"
                    : "antdDisableCheckBox"
                }
                checked={record?.PORel_KDelConfirm_c}
                onChange={(e) =>
                  onDelConfirmChange(
                    e,
                    record?.PORel_PORelNum,
                    record?.PODetail_POLine,
                    record?.PODetail_PartNum
                  )
                }
              />
            ) : (
              <Checkbox
                disabled={true}
                className={"antdDisableCheckBox"}
                checked={record?.PORel_KDelConfirm_c}
                onChange={(e) =>
                  onDelConfirmChange(
                    e,
                    record?.PORel_PORelNum,
                    record?.PODetail_POLine,
                    record?.PODetail_PartNum
                  )
                }
              />
            )}
          </>
        );
      },
    },
    {
      title: t("KSuppPortal_PORel_OpenRelease") /*"Open Release"*/,
      width: "5%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PORel_OpenRelease",
      className: "text-center",
      key: "PORel_OpenRelease",
      render: (record) => {
        return (
          <>
            {
              <Checkbox
                disabled={true}
                className={"antdDisableCheckBox"}
                checked={record}
              />
            }
          </>
        );
      },
    },
    {
      title: t("KSuppPortal_PODetail_UnitCost") /*"Unit Price"*/,
      width: "5%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PODetail_DocUnitCost",
      key: "PODetail_DocUnitCost",
      className: "text-end",
      render: (record) => (
        <span>
          {
            <NumberFormat
              value={parseFloat(record).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
          }
        </span>
      ),
    },
    {
      title: t("KSuppPortal_PODetail_OrderQty") /*"Quantity"*/,
      width: "4%",
      showOnResponse: true,
      showOnDesktop: true,

      dataIndex: "PORel_RelQty",
      key: "PORel_RelQty",
      className: "text-center",
      render: (record) => (
        <span>
          {
            <NumberFormat
              value={parseFloat(record).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
            />
          }
        </span>
      ),
    },
    {
      title: t("KSuppPortal_PODetail_POLine") /*"Line"*/,
      width: "3%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PODetail_POLine",
      key: "PODetail_POLine",
    },
    {
      title: t("KSuppPortal_PORel_PORelNum") /*"Release"*/,
      width: "3%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PORel_PORelNum",
      key: "PORel_PORelNum",
    },
    {
      title: t("KSuppPortal_PORel_JobNum") /*"Job Num"*/,
      width: "6.5%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PORel_JobNum",
      key: "PORel_JobNum",
    },
    {
      title: t("KSuppPortal_PORel_ProjectID") /*"Project ID"*/,
      width: "6.5%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PORel_ProjectID",
      key: "PORel_ProjectID",
    },
    {
      title: t("KSuppPortal_PORel_PhaseID") /*"Phase ID"*/,
      width: "5%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PORel_PhaseID",
      key: "PORel_PhaseID",
    },
  ];


  const poColumnsMobile = [
    {
      title: t("KSuppPortal_PODetail_PartNum") /*"Part Num"*/,
      width: "8%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PODetail_PartNum",
      key: "POHeader_PONum",
    },
    {
      title: t("KSuppPortal_PODetail_LineDesc") /*"Description"*/,
      width: "8%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PODetail_LineDesc",
      key: "PODetail_LineDesc",

    },
    {
      title: t("KSuppPortal_PODetail_ClassID") /*"Class ID"*/,
      width: "5%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PODetail_ClassID",
      key: "PODetail_ClassID",

    },
    // {
    //     title: 'Class ID',
    //     width:"2%",
    //     dataIndex: 'POHeader_PONum',
    //     key: 'POHeader_PONum',
    //     visible: false
    // },
    {
      title: t("KSuppPortal_PORel_DueDate") /*"Due Date"*/,
      width: "8%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PORel_DueDate",
      key: "PORel_DueDate",
      render: (record) => (<>
        <span>
          {
            record
              ? moment(record).format("YYYY-MM-DD")
              : "not fiend"}
        </span>
      </>
      ),
    },
    {
      title: t("KSuppPortal_Calculated_DueDate") /*"Supplier Promise Date"*/,
      width: "10%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "Calculated_DueDate",
      className: "text-center",
      key: "Calculated_DueDate",
      render: (_, record, index) => {
        // const datat=poLineData[index]

        // console.log("datat")
        // console.log(datat)
        // console.log("index")
        // console.log(index)
        // setData(record)
        // exampleFunction()
        // const datat=data

        return <SupplierPDCommon record={{ ...poLineData[index] }} onChange={onChange} />
      },
    },
    {
      title: t("KSuppPortal_PORel_Confirmed") /*"Rel Confirmed"*/,
      width: "7%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PORel_Confirmed",
      className: "text-center",
      key: "PORel_Confirmed",
      render: (_, record, index) => {
        setRelConfirmed(record)
        exampleFunction()
        let data = record

        return (
          <>
            {!data?.PORel_VoidRelease && data?.PORel_OpenRelease ? (
              <Checkbox
                className="antdActiveCheckbox"
                checked={data.PORel_Confirmed}
                onChange={(e) => {
                  onRelConfirm(
                    e,
                    index,
                    data.Calculated_DueDate !== null
                      ? data.Calculated_DueDate.split("T")[0]
                      : data.Calculated_DueDate,
                    rowSelectedPoNum.current,
                    data.PODetail_POLine,
                    data.PORel_PORelNum
                  );
                }}
                disabled={false}
              />
            ) : (
              <Checkbox
                className="antdDisableCheckBox"
                checked={data?.PORel_Confirmed}
                disabled={true}
              />
            )}
          </>
        );
      },
    },
    {
      title: t("KSuppPortal_PORel_KDelConfirm_c") /*"Confirm Delivery"*/,
      width: "8%",
      showOnResponse: true,
      showOnDesktop: true,

      dataIndex: "PORel_KDelConfirm_c",
      className: "text-center",
      key: "PORel_KDelConfirm_c",
      render: (_, record) => {
        let diffDays = "";
        // let suppPromiseDate = true;
        if (
          record?.Calculated_DueDate == null ||
          record?.Calculated_DueDate == ""
        ) {
          // suppPromiseDate = false;
          const promiseDate = new Date(
            moment(record?.PORel_DueDate).format("YYYY-MM-D")
          );
          const todayDate = new Date(moment(new Date()).format("YYYY-MM-D"));
          const diffTime = todayDate - promiseDate;
          diffDays = diffTime / (1000 * 60 * 60 * 24);
          diffDays = Math.round(diffDays);
        } else {
          // suppPromiseDate = true;
          const promiseDate = new Date(
            moment(record?.Calculated_DueDate).format("YYYY-MM-D")
          );
          const todayDate = new Date(moment(new Date()).format("YYYY-MM-D"));
          const diffTime = todayDate - promiseDate;
          diffDays = diffTime / (1000 * 60 * 60 * 24);
          diffDays = Math.round(diffDays);
        }
        return (
          <>
            {!record?.PORel_VoidRelease && record?.PORel_OpenRelease ? (
              <Checkbox
                disabled={!(diffDays <= 0 && diffDays >= -6)}
                className={
                  diffDays <= 0 && diffDays >= -6
                    ? "antdActiveCheckbox"
                    : "antdDisableCheckBox"
                }
                checked={record?.PORel_KDelConfirm_c}
                onChange={(e) =>
                  onDelConfirmChange(
                    e,
                    record?.PORel_PORelNum,
                    record?.PODetail_POLine,
                    record?.PODetail_PartNum
                  )
                }
              />
            ) : (
              <Checkbox
                disabled={true}
                className={"antdDisableCheckBox"}
                checked={record?.PORel_KDelConfirm_c}
                onChange={(e) =>
                  onDelConfirmChange(
                    e,
                    record?.PORel_PORelNum,
                    record?.PODetail_POLine,
                    record?.PODetail_PartNum
                  )
                }
              />
            )}
          </>
        );
      },
    },
    {
      title: t("KSuppPortal_PORel_OpenRelease") /*"Open Release"*/,
      width: "5%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PORel_OpenRelease",
      className: "text-center",
      key: "PORel_OpenRelease",
      render: (record) => {
        return (
          <>
            {
              <Checkbox
                disabled={true}
                className={"antdDisableCheckBox"}
                checked={record}
              />
            }
          </>
        );
      },
    },
    {
      title: t("KSuppPortal_PODetail_UnitCost") /*"Unit Price"*/,
      width: "5%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PODetail_DocUnitCost",
      key: "PODetail_DocUnitCost",
      className: "text-end",
      render: (record) => (
        <span>
          {
            <NumberFormat
              value={parseFloat(record).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
          }
        </span>
      ),
    },
    {
      title: t("KSuppPortal_PODetail_OrderQty") /*"Quantity"*/,
      width: "4%",
      showOnResponse: true,
      showOnDesktop: true,

      dataIndex: "PORel_RelQty",
      key: "PORel_RelQty",
      className: "text-center",
      render: (record) => (
        <span>
          {
            <NumberFormat
              value={parseFloat(record).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
            />
          }
        </span>
      ),
    },
    {
      title: t("KSuppPortal_PODetail_POLine") /*"Line"*/,
      width: "3%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PODetail_POLine",
      key: "PODetail_POLine",
    },
    {
      title: t("KSuppPortal_PORel_PORelNum") /*"Release"*/,
      width: "3%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PORel_PORelNum",
      key: "PORel_PORelNum",
    },
    {
      title: t("KSuppPortal_PORel_JobNum") /*"Job Num"*/,
      width: "6.5%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PORel_JobNum",
      key: "PORel_JobNum",
    },
    {
      title: t("KSuppPortal_PORel_ProjectID") /*"Project ID"*/,
      width: "6.5%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PORel_ProjectID",
      key: "PORel_ProjectID",
    },
    {
      title: t("KSuppPortal_PORel_PhaseID") /*"Phase ID"*/,
      width: "5%",
      showOnResponse: true,
      showOnDesktop: true,
      dataIndex: "PORel_PhaseID",
      key: "PORel_PhaseID",
    },
  ];

  const allEventHandler = () => {
    btnClass.current = "3";
    setFilter(false);
  };

  const closeEventHandler = () => {
    btnClass.current = "2";
    const filterPoData = dataFromRedux.filter((item) => {
      return item.POHeader_OpenOrder === false;
    });
    setFilterData(filterPoData);
    setFilter(true);
  };

  const openEventHandler = () => {
    btnClass.current = "1";
    const filterPoData = dataFromRedux.filter((item) => {
      return item.POHeader_OpenOrder === true;
    });
    // console.log(JSON.stringify(filterPoData));
    setFilterData(filterPoData);
    setFilter(true);
  };





  const searchEventHandler = (e) => {
    const s = e.target.value;
    if (s) {
      if (filter) {
        const filterPoData = filterData.filter((item, i) => {
          return (
            item.POHeader_PONum.toString().includes(s) ||
            moment(item.POHeader_OrderDate)
              .format("YYYY-MM-DD")
              .toString()
              .includes(s) ||
            moment(item.POHeader_DueDate)
              .format("YYYY-MM-DD")
              .toString()
              .includes(s) ||
            item.POHeader_EntryPerson.toString().includes(s) ||
            item.POHeader_PurPoint.toString().includes(s) ||
            item.POHeader_ApprovedAmount.toString().includes(s)
          );
        });
        setFilterData(filterPoData);
        setFilter(true);
      } else {
        const filterPoData = data.filter((item, i) => {
          return (
            item.POHeader_PONum.toString().includes(s) ||
            moment(item.POHeader_OrderDate)
              .format("YYYY-MM-DD")
              .toString()
              .includes(s) ||
            moment(item.POHeader_DueDate)
              .format("YYYY-MM-DD")
              .toString()
              .includes(s) ||
            item.POHeader_EntryPerson.toString().includes(s) ||
            item.POHeader_PurPoint.toString().includes(s) ||
            item.POHeader_ApprovedAmount.toString().includes(s)
          );
        });
        setFilterData(filterPoData);
        setFilter(true);
      }
    } else {
      if (btnClass.current === "1") {
        openEventHandler();
      } else if (btnClass.current === "2") {
        closeEventHandler();
      } else if (btnClass.current === "3") {
        allEventHandler();
      }
    }
  };

  async function copyClickHandler() {
    if (currentPoDetailOpenStatus.current) {
      let counter = 0,
        changeCounter = 0;
      setLoading(true);
      for (let i = 0; i < poLineData.length; i++) {
        const dueDate = poLineData[i].PORel_DueDate
          ? poLineData[i].PORel_DueDate.split("T")[0]
          : "";
        const supplierDueDate = poLineData[i].Calculated_DueDate
          ? poLineData[i].Calculated_DueDate.split("T")[0]
          : "";
        const poNum = selectedPoNum;
        if (poLineData[i].PORel_PromiseDateLimiter_c !== 3) {
          if (
            dueDate !== supplierDueDate &&
            canChangeSupplierPromiseDate(poLineData[i].Calculated_DueDate)
          ) {
            poLineData[i].Calculated_DueDate = poLineData[i].PORel_DueDate;
            await onChange(
              null,
              dueDate,
              poLineData[i].POHeader_PONum,
              poLineData[i].PODetail_POLine,
              poLineData[i].PORel_PORelNum,
              1,
              poLineData[i].PODetail_PartNum,
              poLineData[i].PORel_Confirmed,
              poLineData[i].PORel_PromiseDateLimiter_c
            );
            changeCounter++;
          }
        } else if (poLineData[i].PORel_PromiseDateLimiter_c === 3) {
          counter++;
        }
      }
      if (changeCounter > 0 && counter > 0) {
        message.info(
          t(
            "KSuppPortal_copyLimit"
          ) /*"Some Supplier Promise date could not copied because of limit exceeded rule."*/
        );
      }
      let newPoLineData = poLineData.map((line) => {
        if (
          line.Calculated_DueDate !== line.PORel_DueDate &&
          line.PORel_PromiseDateLimiter_c !== 3 &&
          canChangeSupplierPromiseDate(line.Calculated_DueDate)
        ) {
          line.Calculated_DueDate = line.PORel_DueDate;
          line.PORel_PromiseDateLimiter_c = line.PORel_PromiseDateLimiter_c + 1;
        }
        return line;
      });
      setPoLineData(newPoLineData);
      const tempColumns = poColumns.map((col) => {
        if (col.dataIndex === "Calculated_DueDate") {
          col.render = (text, record) => (
            <>
              <SupplierPDCommon record={record} onChange={onChange} />
            </>
          );
        }
        return col;
      });
      setCol(tempColumns);
      setLoading(false);
    } else {
      setVisible(true);
    }
  }

  function downloadHandler() {
    message.info(
      "KSuppPortal_WaitMessage"
      /*"Please wait! It might take take up to 15 seconds for a PDF to generate and might be block."*/
    );
    setOpen(true);
    let data = JSON.stringify({
      PONum: selectedPoNum,
    });
    let config = {
      method: "post",
      url: "/api/mcustdata/podownload",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        setOpen(false);
        var urll = response.data.url;
        const myArray = urll.split("assets");

        var uurl = axios.defaults.baseURL + "assets" + myArray[1];

        //window.open(response.data.url);
        window.open(uurl);
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(JSON.stringify(error));
        message.error(
          t(
            "KSuppPortal_PDFMessage"
          ) /*"Sorry! There is an error in generating PDF."*/
        );
        setOpen(false);
      });
  }

  const afterChange = () => {
    setDataLoading(true);
    PurchaseOrderAPI.getCustomerData()
      .then((res) => {
        dispatch(setPoData(res.data.vendordata.podata));
        setData(res.data.vendordata.podata);
        myData.current = res.data.vendordata.podata;
        openBtnRef.current.click();
        setDataLoading(false);
      })
      .catch((err) => {
        setDataLoading(false);
        message.error(err.response.data);
      });
  };

  const [selected, setSelected] = useState("US");

  const customLabels = {
    "Custom Labels": {
      GB: { primary: "GB", secondary: "+44" },
      US: { primary: "US", secondary: "+1" },
    }
  };

  return (
    <>
      <div className="btn-tbl-top-group">
        <div className="btn-group" role="group">
          <button
            ref={openBtnRef}
            className={`btn 
              ${btnClass.current === "1" ? "btn-primary" : "btn-default"}
              `}
            onClick={openEventHandler}
          >
            {t("KSuppPortal_APInvHed_OpenPayable") /*Open*/}
          </button>
          <button
            className={`btn 
            ${btnClass.current === "2" ? "btn-primary" : "btn-default"}
            `}
            onClick={closeEventHandler}
          >
            {t("KSuppPortal_Closed") /*Closed*/}
          </button>
          <button
            className={`btn 
            ${btnClass.current === "3" ? "btn-primary" : "btn-default"}
            `}
            onClick={allEventHandler}
          >
            {t("KSuppPortal_All") /*All*/}
          </button>
        </div>
        <div className={"search-area"}>
          <label className="label-inline text-dark">
            {t("KSuppPortal_Search") /*Search*/}:
          </label>
          <input
            autoComplete="off"
            onChange={searchEventHandler}
            name="search"
            className="searchInputField"
          />

        </div>
      </div>

      <MyExpireErrorModal
        destroyOnClose={true}
        guid={false}
        msg={
          t(
            "KSuppPortal_POClosedMessage"
          ) /*"Selected Po Num is closed so you can't perform this Action!"*/
        }
        title={t("KSuppPortal_POClosed") /*"Po Num closed"*/}
        visible={visible}
        setVisible={setVisible}
      />

      <MyModal
        guid={false}
        po={selectedPoNum}
        title={
          modalTitle === "Accept"
            ? t("KSuppPortal_Accept")
            : modalTitle === "Reject"
              ? t("KSuppPortal_Reject")
              : modalTitle
        }
        setVisible={setModalVisible}
        visible={modalVisible}
        setLastComment={setLastComment}
        width={500}
        setColum={setCol}
        setData={setData}
        setColumns={setPoNumColum}
        column={columns}
        setDataLoading={setDataLoading}
        myData={data}
        afterChange={afterChange}
      />

      <Modal
        destroyOnClose={true}
        width={"94%"}
        title={t("KSuppPortal_PODetails") /*"PO Details"*/}
        visible={poLineVisible}
        onCancel={() => {
          setPoLineVisible(false);
        }}
        //  onCancel={()=>{resetDate()}}
        cancelText={t("KSuppPortal_Close") /*"Close"*/}
        // destroyOnClose={true}
        closableButtonProps={{ style: { backgroundColor: "red" } }}
        className="bg-danger p-0"
        okButtonProps={{
          style: { fontSize: "13px", display: "none" },
          className: "btn btn-success"
        }}
        cancelButtonProps={{
          className: "btn btn-danger"
        }}
      >
        <div className="mb-3" style={{ boxShadow: "#b7abaf 1px 3px 8px" }}>
          <div className="pt-3 px-1 ps-2 mt-0 row" style={{ margin: "0 1px" }}>
            <div className="col-lg-2 col-md-4 col-sm-5">
              <div className="poDetailsSubDiv row mb-2">
                <h1 className="PoDetailFields col-7 m-0 p-0">
                  {t("KSuppPortal_POHeader_BuyerID") /*Buyer*/}:
                </h1>
                <h5 className="col-5 m-0 p-0 text-start">{buyerData.buyer}</h5>
              </div>
              <div className="poDetailsSubDiv row mb-2">
                <h1 className="col-7 m-0 p-0">
                  {t("KSuppPortal_PONumber") /*PO Number*/}:
                </h1>
                <h5 className="col-5 m-0 p-0 text-start">{buyerData.poNum}</h5>
              </div>
              <div className="poDetailsSubDiv row mb-2">
                <h1 className="col-7 m-0 p-0">
                  {t("KSuppPortal_POHeader_OrderDate") /*Order Date*/}:
                </h1>
                <h5 className="col-5 m-0 p-0 text-start">
                  {buyerData.orderdate}
                </h5>
              </div>
            </div>
            <div className="poDetailsMain col-lg-2 col-md-4 col-sm-5 mx-md-5 me-sm-0">
              <div className="poDetailsSubDiv row mb-2">
                <h1 className="col-6 m-0 p-0 text-start">
                  {t("KSuppPortal_POHeader_DocTotalCharges") /*Charges*/}:
                </h1>
                <h5 className="col-6 m-0 p-0 text-end">
                  {
                    <NumberFormat
                      value={parseFloat(buyerData.totalCharge).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  }
                </h5>
              </div>
              <div className="poDetailsSubDiv row mb-2">
                <h1 className="col-6 m-0 p-0 text-start">
                  {t("KSuppPortal_POHeader_DocTotalTax") /*Tax*/}:
                </h1>
                <h5 className="col-6 m-0 p-0 text-end">
                  {
                    <NumberFormat
                      value={parseFloat(buyerData.totalTax).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  }
                </h5>
              </div>
              <div className="poDetailsSubDiv row mb-2">
                <h1 className="col-6 m-0 p-0 text-start">
                  {t("KSuppPortal_POHeader_TotalOrder") /*Order Total*/}:
                </h1>
                <h5 className="col-6 m-0 p-0 text-end">
                  {
                    <NumberFormat
                      value={parseFloat(buyerData.totalNet).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  }
                </h5>
              </div>
              <div className="poDetailsSubDiv row mb-2">
                <h1 className="col-6 m-0 p-0 text-start">
                  {t("KSuppPortal_POHeader_CurrencyCode") /*Currency Code*/}:
                </h1>
                <h5 className="col-6 m-0 p-0 text-end">
                  {buyerData.currencyCode}
                </h5>
              </div>
            </div>
            <div className="poDetailsMain col-lg-5 col-md-8 col-sm-10 m-0 m-lg-0 my-sm-3">
              <div className="poDetailsSubDiv row mb-2">
                <h1 className="col-2 m-0 p-0">
                  {t("KSuppPortal_Name") /*Name*/}:
                </h1>
                <h5 className="col-10 m-0 p-0">{buyerData.vendorName}</h5>
              </div>
              <div className="poDetailsSubDiv row mb-2">
                <h1 className="col-2 m-0 p-0">
                  {t("KSuppPortal_Address1") /*Address*/}:
                </h1>
                <h5 className="col-10 m-0 p-0">{buyerData.address}</h5>
              </div>
              <div className="poDetailsSubDiv row mb-2">
                <h1 className="col-2 m-0 p-0">
                  {t("KSuppPortal_Status") /*Status*/} :
                </h1>
                <h5 className="col-10 m-0 p-0">
                  {buyerData.status === "Accepted"
                    ? t("KSuppPortal_Accepted")
                    : buyerData.status === "Rejected"
                      ? t("KSuppPortal_Rejected")
                      : buyerData.status === "None"
                        ? t("KSuppPortal_None")
                        : buyerData.status}
                </h5>
              </div>
              <div className="poDetailsSubDiv row mb-2">
                <h1 className="col-2 m-0 p-0">
                  {t("KSuppPortal_Calculated_AcceptRejReason") /*Comment*/} :
                </h1>
                <h5 className="col-10 m-0 p-0">{buyerData.lastComment}</h5>
              </div>
            </div>
          </div>
          <div
            className="d-flex justify-content-end"
            style={{ marginTop: "-22px" }}
          >
            <button onClick={copyClickHandler} className="btnPoModal">
              {t("KSuppPortal_Copy") /*Copy Promise Date*/}
            </button>
            <button onClick={downloadHandler} className="btnPoModal">
              {t("KSuppPortal_Downlaod") /*Download*/}
            </button>
          </div>
        </div>

        <div className="table-responsive ant-table-responsive">
          {/* box-shadow: #b7abaf 1px 3px 8px; */}
          {width > 900 && height > 344 ? (
            <Table
              rowKey={(record) =>
                record.PORel_PORelNum +
                record.PODetail_POLine +
                record.PODetail_PartNum
              }
              className={styles}
              loading={loading}
              dataSource={loading ? "" : poLineData}
              pagination={{
                showSizeChanger: true,
              }}
            >
              {poColumns.map((column) => (
                <Table.Column
                  key={column.key}
                  title={column.title}
                  width={column?.width}
                  dataIndex={column.dataIndex}
                  render={column?.render}
                  sorter={column?.sorter}
                  className={column?.className}
                />
              ))}
            </Table>
          ) : (
            <ResponsiveTable
              antTableProps={{
                showHeader: true,
                columns: poColumnsMobile,
                dataSource: loading ? "" : poLineData,
                loading: loading,
                pagination: false,

                rowKey: (record) =>
                  record.PORel_PORelNum +
                  record.PODetail_POLine +
                  record.PODetail_PartNum,

                showSorterTooltip: false,
                className: styles,
              }}
              mobileBreakPoint={768}
            />
          )}
        </div>
      </Modal>

      <div className="ant-table-responsive">
        {width > 768 && height > 344 ? (
          <TableCmp
            columns={columns}

            data={filter ? filterData : dataLoading ? "" : data}
            isLoading={dataLoading}
            renderDetailPanel={({ row }) => {
              return (
                <small>
                  {t("KSuppPortal_Calculated_AcceptRejReason") /*Comment*/}:{" "}
                  {row.original.Calculated_AcceptRejReason}
                </small>
              )
            }}
          />

        ) : (
          <ResponsiveTable
            antTableProps={{
              showHeader: true,
              columns: MobViewColumns,
              dataSource: filter ? filterData : dataLoading ? "" : data,
              loading: dataLoading,
              rowKey: (record) => record.POHeader_PONum,
              expandable: {
                columnWidth: "1%",
                expandedRowRender: (record) => (
                  <p style={{ margin: 0, fontSize: "13px" }}>
                    {t("KSuppPortal_Calculated_AcceptRejReason") /*Comment*/}:{" "}
                    {record.Calculated_AcceptRejReason}
                  </p>
                ),
                rowExpandable: (record) =>
                  record.POHeader_PONum == record.POHeader_PONum,
              },
              pagination: {
                // total:10,
                showSizeChanger: false,
              },
              showSorterTooltip: false,
              className: styles,
            }}
            mobileBreakPoint={768}
          />
        )}
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default TableComponent;
