import "antd/dist/antd.css";
import { Layout } from "antd";
import { MyFooter, MyHeader } from "../index";
import { ConfigProvider } from "antd";
import moment from "moment";
import "moment/locale/zh-cn";
import { useSelector } from "react-redux";
import { getSelectedLanguage } from "../../../redux/languages";
import enUs from "antd/lib/locale-provider/en_US";
import zhCn from "antd/lib/locale/zh_CN";
import "./style.scss";

const { Header, Footer, Content } = Layout;

const CustomLayout = ({ children }) => {
  const locRedux = useSelector(getSelectedLanguage);
  let locale = locRedux
    ? locRedux === "zr"
      ? zhCn
      : enUs
    : localStorage.getItem("i18nextLng") === "zr"
    ? zhCn
    : enUs;
  moment.locale(locale.toString());
  const path = window.location.pathname;
  return (
    <>
      <ConfigProvider locale={locale}>
        <Layout className="master-layout">
          {path !== "/pdfcanvas" &&
            path !== "/pdfmodule" &&
            path !== "/pdfcanvasSS" && (
              <>
                <MyHeader />
              </>
            )}
          <Content
          >
            {children}
          </Content>
          {path !== "/pdfcanvas" && path !== "/pdfmodule" && (
            <Footer className="main-footer">
              <MyFooter />
            </Footer>
          )}
        </Layout>
      </ConfigProvider>
    </>
  );
};

export default CustomLayout;
