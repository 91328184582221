import React from "react";
import mainStyle from "../../../scss/main.scss";
import { BiDownload } from "react-icons/bi";
import { Button } from "@mui/material";
import styles from "../../purchaseOrder/styles.scss";
import { Table } from "antd";
import { column } from "./data";

const CardWTable = () => {
  const primaryColor = mainStyle.primary;
  const fontSize = mainStyle.fontSize;
  const [filterData, setFilterData] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [tblColumn, setTblColumn] = React.useState(column);
  const [filter, setFilter] = React.useState(false);
  return (
    <>
      <>
        <div className="d-flex justify-content-between p-1">
          <span style={{ fontSize: fontSize, color: "black" }}>
            Open Orders
          </span>
          <Button
            size={"small"}
            style={{
              height: "20px",
              color: "white",
              backgroundColor: mainStyle.primary,
              fontSize: fontSize,
            }}
          >
            New Order
          </Button>
        </div>
      </>
      <>
        <div className="text-end my-2">
          <label style={{ fontSize: "13px" }} className="text-dark">
            Search:
          </label>
          <input
            autoComplete="off"
            name="search"
            className="searchInputField"
          />
        </div>
      </>
      <>
        <div>
          <Table
            rowKey={(record) => record.POHeader_PONum}
            expandable={{
              columnWidth: "1%",
              expandedRowRender: (record) => (
                <p style={{ margin: 0, fontSize: "13px" }}>
                  Comment: {record.Calculated_AcceptRejReason}
                </p>
              ),
              rowExpandable: (record) =>
                record.POHeader_PONum == record.POHeader_PONum,
            }}
            pagination={{
              // total:10,
              showSizeChanger: true,
            }}
            className={styles}
            dataSource={filter ? filterData : data}
            columns={tblColumn}
          />
        </div>
      </>
    </>
  );
};
export { CardWTable };
