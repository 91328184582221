import React from 'react';
import {axios} from '../index';
import qs from "qs";

const PurchaseOrder = {
    getCustomerData: () => {
        return new Promise((resolve, reject) => {
            let config = {
                method: 'post',
                url: '/api/mcustdata/getcustomerdata',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                }
            };
            axios(config)
                .then(function (response) {
                    // const dispatch = useDispatch();
                    localStorage.setItem('open',response.data.vendordata.pocount.open);
                    localStorage.setItem('purchaseOrder',response.data.vendordata.podata.length);
                    localStorage.setItem('invoices',response.data.vendordata.apinvdata.length);
                    // localStorage.setItem('poData',JSON.stringify(response.data.vendordata.podata));
                    // localStorage.setItem('invoicesData',JSON.stringify(response.data.vendordata.apinvdata));
                    localStorage.setItem('profileData',JSON.stringify(response.data.vendordata.profiledata));
                    // dispatch(setCompanyName(response.data.vendordata.profiledata));
                    resolve(response);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },
    onRelConfirm: (dateString, rel,line, poNum, relConfirm) => {
        return new Promise((resolve, reject) => {
            let data,config;
                data = JSON.stringify({
                    "PONum": poNum,
                    "line": line,
                    "release": rel,
                    "wdate": dateString,
                    "PORel_Confirmed":relConfirm,
                });
                config = {
                    method: 'post',
                    url: '/api/mcustdata/polineduedate',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                        'Content-Type': 'application/json',
                    },
                    data : data
                };
            axios(config)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },
}
export default PurchaseOrder;