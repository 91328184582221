import CreateIcon from "@mui/icons-material/Create";
import RectangleIcon from "@mui/icons-material/Rectangle";
import CircleIcon from "@mui/icons-material/Circle";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import PanToolIcon from "@mui/icons-material/PanTool";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import {ArrowLeftOutlined, ArrowRightOutlined} from "@ant-design/icons";

const tools = [
    // {
    //     name: "Pencil",
    //     icon: <CreateIcon />
    // },
    {
        name: "Rectangle",
        icon: <RectangleIcon />,
        txt:'Draw Shape',
    },
    // {
    //     name: "Circle",
    //     icon: <CircleIcon />
    // },
    // {
    //     name: "Line",
    //     icon: <RemoveIcon />
    // },
    // {
    //     name: "Tick",
    //     icon: <DoneIcon />
    // },
    // {
    //     name: "Cross",
    //     icon: <CloseIcon />
    // },
    // {
    //     name: "Text",
    //     icon: <TextFieldsIcon />
    // },
    {
        name: "Select",
        icon: <PanToolIcon/>,
        txt:'Move',
    }
];

const Tools = ({ tool, setTool, undo, redo }) => {
    const selectTool = (event) => {
        // console.log(event.currentTarget.id);
        const currentTool = event.currentTarget.id;
        setTool(currentTool);
    };
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
                border: "1px solid black",
                // width: "fit-content",
                margin: 0,
                position: "absolute",
                zIndex: 100,
                left:616,
                top:50,
                width:'100%',
                padding:'4px 0px',
            }}
        >
            {tools.map((t, ind) => {
                return (
                    <IconButton
                        style={{
                            margin: "3px 7px",
                            padding:'5px 10px',
                            border: "2px solid #a11a10",
                            borderRadius: "4px",
                            width: "fit-content",
                            cursor: "pointer",
                            backgroundColor: t.name === tool ? "#a11a10" : "transparent",
                            fontSize:'13px',
                            color: t.name === tool ? "white" : "black",
                        }}
                        onClick={selectTool}
                        id={t.name}
                        key={t.name}
                    >
                        <span style={{marginRight:'5px' }}>{t.icon }</span>
                        <span style={{color:t.name === tool ? "white" : "black" }}>{t.txt}</span>
                    </IconButton>
                );
            })}
            <div
                style={{
                    display: "flex",
                    alignContent: "space-around",
                    padding: "4px",
                    justifyContent: "start",
                    width: "100%",
                    color:'black!important',

                }}
            >
                <IconButton
                    style={{ border: "2px solid #a11a10",borderRadius: "4px", margin: "0px 2px",padding:'5px 10px',color:'black!important' }}
                    size="small"
                    onClick={undo}
                >
                    <ArrowLeftOutlined />
                </IconButton>
                <IconButton
                    style={{ border: "2px solid #a11a10",borderRadius: "4px", margin: "0px 2px",padding:'5px 10px',color:'black!important' }}
                    size="small"
                    onClick={redo}
                >
                    <ArrowRightOutlined />
                </IconButton>
            </div>
        </div>
    );
};

export default Tools;
