import React from "react";
import { BiBadgeCheck, BiDownload } from "react-icons/bi";
import { FaBoxOpen, FaBoxes } from "react-icons/fa";
import { AiFillMobile, AiOutlineMessage } from "react-icons/ai";
import { Box } from "../../../assets/icons/box";
import mainStyle from "../../../scss/main.scss";
import { Button } from "@mui/material";
import { MapTip } from "../../../assets/icons/mapTip";
import { DatePicker } from "antd";
const CardDetail = (props) => {
  const primaryColor = mainStyle.primary;
  const fontSize = mainStyle.fontSize;
  return (
    <>
      <div>
        <>
          <div className="d-flex justify-content-between">
            <div>
              <span style={{ fontWeight: "bold", color: "black" }}>
                POS Cash Port Hedland (Internal Use Only)
              </span>
              <BiBadgeCheck className={"mx-1"} color={"green"} size={25} />
            </div>
            <div
              style={{ fontSize: fontSize, color: "black", fontWeight: "bold" }}
            >
              23 PLUMBING & GAS, 23
            </div>
          </div>
          <>
            <div className="d-flex justify-content-between">
              <div>
                <AiOutlineMessage color={mainStyle.primary} size={18} />
                <AiFillMobile
                  className={"mx-3"}
                  color={mainStyle.primary}
                  size={18}
                />
                <Box className={"mx-3"} color={mainStyle.primary} size={18} />
              </div>
              <Button
                size={"small"}
                style={{
                  color: "white",
                  backgroundColor: mainStyle.primary,
                  fontSize: fontSize,
                }}
              >
                Switch Customer
              </Button>
            </div>
          </>
        </>
        <>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <MapTip color={mainStyle.primary} size={18} />
              <span
                style={{
                  fontSize: fontSize,
                  fontWeight: "bold",
                  color: primaryColor,
                }}
              >
                POS Cash Port Hedland (Internal Use Only)~POS Cash Port Hedland
                (Internal Use Only)~ ~AUSTRALIA
              </span>
            </div>
            <div className="d-flex" style={{ marginRight: "10%" }}>
              <div style={{ color: "black", fontSize: fontSize }}>
                Review Date
              </div>
              <div style={{ marginLeft: "40px" }}>
                <div style={{ color: "black", fontSize: fontSize }}>
                  Last Update Date
                </div>
                <span
                  style={{
                    color: "#9A0E06",
                    backgroundColor: "#EEE5FF",
                    borderColor: "transparent",
                    fontWeight: "bold",
                    fontSize: fontSize,
                  }}
                >
                  2019-11-29
                </span>
              </div>
              <div style={{ marginLeft: "40px" }}>
                <div style={{ color: "black", fontSize: fontSize }}>
                  Date Range
                </div>
                <div
                  className="d-flex"
                  style={{ color: "black", fontSize: fontSize }}
                >
                  <DatePicker.RangePicker />
                  <Button
                    className={"mx-2"}
                    startIcon={<BiDownload color="white" size={20} />}
                    size={"small"}
                    style={{
                      color: "white",
                      backgroundColor: mainStyle.primary,
                      fontSize: fontSize,
                    }}
                  >
                    Statement
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
        <>
          <div className="d-flex">
            <>
              <div className="d-flex col-6">
                <span className="my-2">
                  <FaBoxOpen size={25} />
                </span>
                <div className="mx-2">
                  <div
                    style={{
                      color: "black",
                      fontSize: fontSize,
                      fontWeight: "bold",
                    }}
                  >
                    Open Order
                  </div>
                  <div
                    style={{
                      color: mainStyle.primary,
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    1
                  </div>
                </div>
              </div>
            </>
            <>
              <div className="d-flex">
                <span className="my-2">
                  <FaBoxes size={25} />
                </span>
                <div className="mx-2">
                  <div
                    style={{
                      color: "black",
                      fontSize: fontSize,
                      fontWeight: "bold",
                    }}
                  >
                    Total Orders
                  </div>
                  <div
                    style={{
                      color: mainStyle.primary,
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    1
                  </div>
                </div>
              </div>
            </>
          </div>
        </>
      </div>
    </>
  );
};
export { CardDetail };
