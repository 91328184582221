import axios from "axios";

const instance = axios.create({
  baseURL: "https://mit.kairossolutions.co/", //For Live New
  // baseURL: "https://portal.mit.com.sg:8448/", //For Live OLD
  // baseURL: "https://portal.mit.com.sg:9448/", //For MIT Train TEST
});
export const M2Axios = axios.create({
  baseURL: "https://m2.kairossolutions.co/", //For MIT Train
});

export default instance;
