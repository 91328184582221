import React from "react";
import { CardDetail } from "./cardDetail";
import "./style.scss";
import { CardWTable } from "./cardWTable";
import { M2DashboardApi } from "../../../api/customerPortal";
import { useDispatch } from "react-redux";
import { setCompanyName, setSupplierCompany } from "../../../redux/dashboard";

const CustomerPortalHome = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    M2DashboardApi.getDashboardData()
      .then((res) => {
        dispatch(setCompanyName(res.customerdata.profiledata.Name));
        dispatch(setSupplierCompany(res.customerdata.otherdata));
      })
      .catch((e) => {});
  }, []);

  return (
    <>
      <div className="my-2 mx-4">
        <>
          <div className="customer-container p-2">
            <CardDetail />
          </div>
        </>
        <>
          <div className="row">
            <div className="customer-container col-8">
              <CardWTable />
            </div>
            <div className="customer-container col-4">Chart Here</div>
          </div>
        </>
      </div>
    </>
  );
};

export default CustomerPortalHome;
