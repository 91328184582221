import React, { Component, Fragment } from 'react';
import { render } from 'react-dom';
import Hello from '../pdfwithcanvas/index';
import ScreenCapture from './ScreenCapture';
import './style.scss';
import {Button} from "@mui/material";

class App extends Component {
    state = {
        name: 'GAMO',
        screenCapture: '',
    };

    handleScreenCapture = (screenCapture) => {
        console.log(screenCapture);
        this.setState({
            screenCapture,
        });
    };

    render() {
        const { screenCapture } = this.state;
        return (
            <ScreenCapture onEndCapture={this.handleScreenCapture}>
                {({ onStartCapture }) => (
                    <Fragment>

                        <p>Start editing to see some magic happen :)</p>
                        <Button
                            style={{ margin: "4px", padding: "2px",position:'absolute', top: '0', right: '241px' }}
                            variant="contained"
                            color="primary"
                            component="span"
                            onClick={onStartCapture}
                        >
                            Capture
                        </Button>
                        <Hello  />
                        {/*<button onClick={onStartCapture}*/}
                        {/*    style={{position:'absolute', top: '0', right: '241px'}}*/}
                        {/*>Capture</button>*/}
                        <br />
                        <br />
                        <img src={screenCapture}  alt={'something'}/>
                    </Fragment>
                )}
            </ScreenCapture>
        );
    }
}

// render(<App />, document.getElementById('root'));
export default App;